import { useState } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useNavigate, useParams } from "react-router";
import Swal from "sweetalert2";
import businessProductService from "../../services/business-product.service";
import businessRuleGroupService from "../../services/business-rule-group.service";
import businessRulePerGroupService from "../../services/business-rule-per-group.service";
import businessRuleTypeService from "../../services/business-rule-type.service";
import businessRuleService from "../../services/business-rule.service";
import { BusinessRule } from "../../typings/api/business-rule";
import { BusinessRuleGroup } from "../../typings/api/business-rule-group";
import BreadCrumbs from "../../layout/breadcrumbs";
import CardHeading from "../../layout/card-heading";
import PageDescription from "../../layout/page-description";
import TableNeogen from "../../layout/table-neogen";
import Loader2 from "../utilities/Loader2";
import AddEditBusinessProduct from "./modals/add-edit-business-product";
import AddEditBusinessRule from "./modals/add-edit-business-rule";
import AddEditBusinessRuleGroup from "./modals/add-edit-business-rule-group";
import ManageRuleTypes from "./modals/manage-rule-types";
import PrintPre from "../../layout/print-pre";
import roleGroupService from "../../services/role-group.service";

export default function Product() {
    const cache = useQueryClient();
    const { id } = useParams();
    const navigate = useNavigate();
    const [showAddEditProduct, setShowAddEditProduct] = useState(false);
    const [showAddEditGroup, setShowAddEditGroup] = useState(false);
    const [showAddEditRule, setShowAddEditRule] = useState(false);
    const [groupToPass, setGroupToPass] = useState<BusinessRuleGroup | null>();
    const [showManageRuleTypes, setShowManageRuleTypes] = useState(false);
    const [ruleToPass, setRuleToPass] = useState<BusinessRule | undefined>();

    const productQuery = useQuery(["business-products", id], async () => {
        const response = await businessProductService.getOneIncludingChildren(Number(id));
        if (response) {
            return response;
        }
    });

    const ruleTypeQuery = useQuery(["business-rule-types"], async () => {
        const response = await businessRuleTypeService.getAll();
        if (response) {
            return response.data;
        }
    });

    const roleGroupsQuery = useQuery(["role-groups"], async () => {
        const response = await roleGroupService.getAll();
        if (response) {
            return response.data;
        } else {
            return [];
        }
    });

    // const ruleQuery = useQuery(["processflow-groups", id], async () => {
    //     const response = await businessRuleService.getAllByProductId(Number(id));
    //     if (response) {
    //         return response.data;
    //     }
    // });

    return (
        <>
            <BreadCrumbs
                addMb={true}
                pages={[
                    {
                        name: "Business Rule Products",
                        href: "/rules",
                    },
                    {
                        name: productQuery.data?.name ?? "Unknown",
                        href: `/rules/${productQuery}`,
                        current: true,
                    },
                ]}
            />
            <PageDescription
                title={productQuery.data?.name ?? "Unknown"}
                description={productQuery.data?.description ?? "Unknown"}
                buttons={[
                    // {
                    //     text: "Run Process Flow",
                    //     action: () => navigate("/processflow-wizard/" + id),
                    //     icon: "fal fa-wand-magic-sparkles"
                    // },
                    {
                        label: "Edit Product",
                        onClick: () => {
                            setShowAddEditProduct(true);
                        },
                        // bg: "bg-blue-600",
                        icon: "fal fa-pencil-alt",
                    },
                    {
                        label: "Manage Rule Types",
                        onClick: () => {
                            // setGroupToPass(null);
                            setShowManageRuleTypes(true);
                        },
                        bg: "bg-blue-600",
                        icon: "fal fa-shelves",
                    },
                    {
                        label: "Add Rule Group",
                        onClick: () => {
                            setGroupToPass(null);
                            setShowAddEditGroup(true);
                        },
                        bg: "bg-green-600",
                        size: "xs",
                        icon: "fas fa-plus",
                    },
                    {
                        label: "Delete Product",
                        onClick: () => {
                            Swal.fire({
                                title: "Are you sure?",
                                text: "You won't be able to revert this!",
                                icon: "warning",
                                confirmButtonText: "Yes, delete it!",
                                showCancelButton: true,
                                confirmButtonColor: "#3085d6",
                            }).then(async (result) => {
                                if (result.value) {
                                    try {
                                        await businessProductService.deleteByID(Number(id));
                                    } catch (e) {
                                        console.log(e);
                                    }
                                    cache.invalidateQueries(["processflow-stages", id]);
                                    Swal.fire({
                                        title: "Deleted!",
                                        text: "Your product has been deleted.",
                                        icon: "success",
                                        showConfirmButton: false,
                                        timer: 1500,
                                    }).then(() => {
                                        navigate("/rules");
                                    });
                                }
                            });
                        },
                        bg: "bg-red-600",
                        icon: "fal fa-trash-alt",
                    },
                ]}
            />
            {/* <PrintPre>
                {productQuery.data}
            </PrintPre> */}
            {/* <h3 className="text-xl font-light">Rule Groups</h3> */}
            <div className="masonry-3-cols">
                {productQuery.data?.businessRuleGroups?.map((group: BusinessRuleGroup, idx: number) => {
                    return (
                        <div key={idx} className="  mb-5    ">
                            {/* <PrintPre>{group}</PrintPre> */}
                            <CardHeading
                                title={group.name}
                                description={group.description}
                                badges={[
                                    {
                                        text: group.isQualify ? "Qualifier" : "Disqualifier",
                                        color: group.isQualify ? "green" : "red",
                                        icon: "fal fa-wand-magic-sparkles",
                                    },
                                    {
                                        text: group.isAnd ? "All Required" : "One Required",
                                        color: group.isAnd ? "blue" : "yellow",
                                        icon: "fal fa-wand-magic-sparkles",
                                    },
                                ]}
                                buttons={[
                                    {
                                        icon: "fal fa-plus",
                                        text: "Add Rule",
                                        size: "xxs",
                                        action: () => {
                                            setGroupToPass(group);
                                            setRuleToPass(undefined);
                                            setShowAddEditRule(true);
                                        },
                                    },
                                    {
                                        icon: "fal fa-pencil-alt",
                                        // bg: "bg-blue-600",
                                        type: "info",
                                        text: "Edit Group",
                                        size: "xxs",
                                        action: () => {
                                            setGroupToPass(group);
                                            setShowAddEditGroup(true);
                                        },
                                    },
                                    {
                                        text: "Delete Group",
                                        type: "danger",
                                        icon: "fal fa-trash-alt",
                                        size: "xxs",
                                        action: () => {
                                            Swal.fire({
                                                title: "Are you sure?",
                                                text: "You won't be able to revert this!",
                                                icon: "warning",
                                                confirmButtonText: "Yes, delete it!",
                                                showCancelButton: true,
                                                confirmButtonColor: "#3085d6",
                                            }).then(async (result) => {
                                                if (result.value) {
                                                    try {
                                                        await businessRuleGroupService.deleteByID(Number(group.id));
                                                    } catch (e) {
                                                        console.log(e);
                                                    }
                                                    cache.invalidateQueries(["business-products"]);
                                                    Swal.fire({
                                                        title: "Deleted!",
                                                        text: "Your group has been deleted.",
                                                        icon: "success",
                                                        showConfirmButton: false,
                                                        timer: 1500,
                                                    });
                                                }
                                            });
                                        },
                                    },
                                ]}
                            >
                                {ruleTypeQuery.isLoading ? (
                                    <Loader2 />
                                ) : (
                                    <div className="my-2 mx-4">
                                        {/* <PrintPre>{ user}</PrintPre> */}
                                        <TableNeogen
                                            compact
                                            ignoreFields={["processflowId", "ruleType", "valueTriggerEnd"]}
                                            headers={["Field", "Value"]}
                                            entries={group.businessRules ?? []}
                                            actions={[
                                                {
                                                    icon: "fal fa-pencil-alt",
                                                    label: "Edit",
                                                    size: "xxs",
                                                    onClick: (rule: BusinessRule) => {
                                                        setRuleToPass(rule);
                                                        setGroupToPass(group);
                                                        setShowAddEditRule(true);
                                                    },
                                                },
                                                {
                                                    label: "Delete",
                                                    className: "bg-red-600",
                                                    icon: "fal fa-trash-alt",
                                                    size: "xxs",
                                                    onClick: (rule: number) => {
                                                        Swal.fire({
                                                            title: "Are you sure?",
                                                            text: "You won't be able to revert this!",
                                                            icon: "warning",
                                                            confirmButtonText: "Yes, delete it!",
                                                            showCancelButton: true,
                                                            confirmButtonColor: "#3085d6",
                                                        }).then(async (result) => {
                                                            if (result.value) {
                                                                try {
                                                                    console.error(rule);
                                                                    await businessRuleService.deleteByID(Number(rule));
                                                                    // Delete associated Rule Per Group
                                                                    const existingEntries =
                                                                        await businessRulePerGroupService.getFilteredWhere(
                                                                            {
                                                                                businessRule: Number(rule),
                                                                            },
                                                                        );
                                                                    if (
                                                                        existingEntries &&
                                                                        (existingEntries.data ?? []).length > 0
                                                                    ) {
                                                                        for (const entry of existingEntries.data) {
                                                                            await businessRulePerGroupService.deleteByID(
                                                                                entry.id,
                                                                            );
                                                                        }
                                                                    }
                                                                } catch (e) {
                                                                    console.log(e);
                                                                }
                                                                cache.invalidateQueries(["business-products"]);
                                                                Swal.fire({
                                                                    title: "Deleted!",
                                                                    text: "Your rule has been deleted.",
                                                                    icon: "success",
                                                                    showConfirmButton: false,
                                                                    timer: 1500,
                                                                });
                                                            }
                                                        });
                                                    },
                                                },
                                            ]}
                                            formatters={[
                                                {
                                                    field: "valueTriggerStart",
                                                    type: "CustomFunctionFullRow",
                                                    customFunction: (value: any, value2) => {
                                                        if (value.fieldName === "Role Group") {
                                                            const roleName = roleGroupsQuery.data?.find(
                                                                (x) => x.id === Number(value2[1]),
                                                            )?.name;
                                                            return roleName;
                                                        } else {
                                                            return value2[1];
                                                        }
                                                    },
                                                },
                                                {
                                                    field: "processflowId",
                                                    type: "Processflow",
                                                },
                                            ]}
                                        />
                                    </div>
                                )}
                            </CardHeading>
                            {/* <h3 className="t    ext-xl font-semibold">{group.name}</h3>
                        <p className="text-gray-600">{group.description}</p>
                        <TableNeogen
                            entries={group.businessRules}
                        /> */}
                        </div>
                    );
                })}
            </div>
            {/* <PrintPre>
                {productQuery.data}
            </PrintPre> */}
            <AddEditBusinessRuleGroup
                show={showAddEditGroup}
                close={() => setShowAddEditGroup(false)}
                group={groupToPass}
            />
            <AddEditBusinessProduct show={showAddEditProduct} close={() => setShowAddEditProduct(false)} />
            <AddEditBusinessRule
                show={showAddEditRule}
                close={() => setShowAddEditRule(false)}
                rule={ruleToPass}
                group={groupToPass}
            />
            <ManageRuleTypes show={showManageRuleTypes} close={() => setShowManageRuleTypes(false)} />
        </>
    );
}
