import { Tab } from "@headlessui/react";
import { useState } from "react";

export function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(" ");
}

export default function Toggle({
    options,
    selectedIndex,
    setSelectedIndex,
}: {
    options: string[];
    selectedIndex: number;
    setSelectedIndex: (index: number) => void;
}) {
    // const [selectedIndex, setSelectedIndex] = useState(0);
    return (
        <Tab.Group selectedIndex={selectedIndex} onChange={setSelectedIndex}>
            <Tab.List className="flex space-x-1 rounded-xl bg-blue-900/20 p-1">
                {options.map((tab) => (
                    <Tab
                        key={tab}
                        className={({ selected }) =>
                            classNames(
                                "w-full rounded-lg py-2 px-2 text-sm font-medium leading-5 text-gray-500",
                                "ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none",
                                selected
                                    ? "bg-white dark:bg-indigo-700 dark:text-gray-200 shadow"
                                    : "text-blue-100 hover:bg-white/[0.12] hover:text-white dark:text-slate-400 dark:hover:bg-green-500/[0.42] dark:hover:text-slate-300",
                            )
                        }
                    >
                        {tab}
                    </Tab>
                ))}
            </Tab.List>
        </Tab.Group>
    );
}
