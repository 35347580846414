import { z } from "zod";
import { makeRequest, parseSchema } from "../../jason-proof-of-concept/shared/utils";
import { companySchema } from "../domain/company";
import { CompanyFilters } from "../components/modals/company-filters-modal";

export const getPaginatedCompanies = async ({
    authToken,
    filters,
    adHocFilters,
}: {
    authToken: string;
    filters?: any;
    adHocFilters?: CompanyFilters;
}) => {
    const response = await makeRequest({
        method: "get",
        path: "/companies",
        authToken,
        queryParams: { filter: JSON.stringify(filters), adHocFilters: JSON.stringify(adHocFilters) },
    });

    const companies = parseSchema(z.array(companySchema), response.data);

    const countHeader = response.headers["x-count"];

    const count = countHeader ? Number.parseInt(countHeader, 10) : undefined;

    return { data: companies, count };
};
