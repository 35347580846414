import { useEffect, useState } from "react";
import { QueryClient, useQueryClient } from "@tanstack/react-query";
import { useParams } from "react-router";
import { useNavigate } from "react-router";
import Swal from "sweetalert2";
import businessRuleGroupService from "../../../services/business-rule-group.service";
import businessRuleGroupsPerProductService from "../../../services/business-rule-groups-per-product.service";
import businessRuleService from "../../../services/business-rule.service";
import { BusinessRuleGroup } from "../../../typings/api/business-rule-group";
import InputControlled from "../../../layout/input-controlled";
import ModalDialog from "../../../layout/modal-dialog";
import PrintPre from "../../../layout/print-pre";
import SelectNeoGen from "../../../layout/select-neogen";

export default function AddEditBusinessRuleGroup(props: AddEditBusinessRuleGroupProps) {
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [error, setError] = useState("");
    const [isQualify, setIsQualify] = useState(1);
    const [isAnd, setIsAnd] = useState(1);
    const navigate = useNavigate();
    const { id } = useParams();
    const cache = useQueryClient();

    useEffect(() => {
        if (props.group) {
            setName(props.group.name);
            setDescription(props.group.description);
            setIsQualify(props.group.isQualify);
        } else {
            // alert(JSON.stringify(props));
            setName("");
            setDescription("");
            setIsQualify(1);
        }
    }, [props]);

    if (!props.show) {
        return null;
    }

    async function save() {
        try {
            if (props.group) {
                await businessRuleGroupService.update(props.group.id, {
                    name,
                    description,
                    isQualify,
                    isAnd,
                });
                cache.invalidateQueries(["business-rule-group"]);
                cache.invalidateQueries(["business-products"]);
                Swal.fire({
                    title: "Success",
                    text: "Business Rule Group saved successfully",
                    icon: "success",
                    showConfirmButton: false,
                    timer: 1500,
                });

                props.close();
                return;
            } else {
                const response = await businessRuleGroupService.create({
                    name,
                    description,
                    product: Number(id),
                    isQualify,
                    isAnd,
                });

                if (response?.data.id) {
                    const businessRuleGroupsPerProductResponse = await businessRuleGroupsPerProductService.create({
                        businessRuleGroupId: response.data.id,
                        businessProductId: Number(id),
                    });
                    if (businessRuleGroupsPerProductResponse?.data.id) {
                        cache.invalidateQueries(["business-rule-group"]);

                        cache.invalidateQueries(["business-products"]);
                        Swal.fire({
                            title: "Success",
                            text: "Business Rule Group created successfully",
                            icon: "success",
                            showConfirmButton: false,
                            timer: 1500,
                        });

                        props.close();
                        return;
                    }
                } else {
                    console.error(response?.data);
                    // setError(response?.data?.message);
                }
            }
        } catch (error: any) {
            console.error(error);
            setError(error.message);
        }
    }
    console.log(name);

    return (
        <>
            <ModalDialog
                show={props.show}
                title={props.group ? "Edit Rule Group" : "Add Rule Group"}
                okAction={() => save()}
                close={() => props.close()}
            >
                {/* <PrintPre>
                    {props.group}
                </PrintPre> */}
                <InputControlled
                    label="Name"
                    name="name"
                    value={name}
                    setValue={(e: string) => setName(e)}
                    errors={[error]}
                    description="The name of the rule group"
                />
                <InputControlled
                    label="Description"
                    value={description}
                    setValue={(e: string) => setDescription(e)}
                    errors={[error]}
                    description="The description of the rule group"
                />
                <SelectNeoGen
                    description="Does meeting the conditions qualify or disqualify the customer."
                    value={isQualify}
                    label="Qualifies for the product"
                    onChange={(e: string | number) => setIsQualify(Number(e))}
                    options={[
                        { id: 0, name: "No" },
                        { id: 1, name: "Yes" },
                    ]}
                />
                <SelectNeoGen
                    className="mt-5"
                    description="Does the test need to pass for all or just at least one."
                    value={isAnd}
                    label="Needs all to pass"
                    onChange={(e: string | number) => setIsAnd(Number(e))}
                    options={[
                        { id: 0, name: "No" },
                        { id: 1, name: "Yes" },
                    ]}
                />
            </ModalDialog>
        </>
    );
}

type AddEditBusinessRuleGroupProps = {
    show: boolean;
    close: () => void;
    group?: BusinessRuleGroup | null;
};
