import React from "react";
import PageDescription from "../../../layout/page-description";
import AddIndustry from "./modals/add-industry";
import { useQuery } from "@tanstack/react-query";
import TableNeogen from "../../../layout/table-neogen";
import industryService, { Industry } from "../../../services/industry.service";
import SwalNeogenFire from "../../../layout/swal-neogen";

export default function Industries() {
    const [showAddIndustry, setShowAddIndustry] = React.useState(false);
    const [industry, setIndustry] = React.useState<Industry | null>(null);
    const industriesQuery = useQuery(["industries"], async () => {
        const result = await industryService.getAll();
        return result?.data ?? [];
    });
    return (
        <>
            <PageDescription
                title="Industries"
                description="Manage Industries"
                buttons={[
                    {
                        label: "Add Industry",
                        onClick: () => {
                            setIndustry(null);
                            setShowAddIndustry(true);
                        },
                        icon: "fas fa-building",
                    },
                    {
                        label: "Manage Industry Groups",
                        onClick: () => {
                            window.location.href = "/admin/industry-groups";
                        },
                        icon: "fas fa-city",
                        type: "info",
                    },
                    {
                        label: "Manage Mandates",
                        onClick: () => {
                            window.location.href = "/admin/mandates";
                        },
                        icon: "fas fa-file-lines",
                        type: "secondary",
                    },
                ]}
            />
            <TableNeogen
                entries={industriesQuery.data ?? []}
                actions={[
                    {
                        label: "Edit",
                        onClick: (entry) => {
                            // console.log({ entry });
                            setIndustry(industriesQuery.data?.find((i) => i.id === entry) ?? null);
                            setShowAddIndustry(true);
                        },
                        icon: "fas fa-edit",
                    },
                    {
                        label: "Delete",
                        type: "danger",
                        onClick: (entry) => {
                            SwalNeogenFire({
                                title: "Are you sure?",
                                text: "You won't be able to revert this!",
                                icon: "warning",
                                showCancelButton: true,
                                confirmButtonText: "Yes, delete it!",
                                cancelButtonText: "No, keep it",
                                confirmButtonColor: "#d33",
                                reverseButtons: true,
                            }).then(async (result) => {
                                if (result.isConfirmed) {
                                    await industryService.deleteByID(entry);
                                    industriesQuery.refetch();
                                    SwalNeogenFire({
                                        title: "Deleted!",
                                        text: "Your file has been deleted.",
                                        icon: "success",
                                        showConfirmButton: false,
                                        timer: 1500,
                                    });
                                }
                            });
                        },
                        icon: "fas fa-trash",
                    },
                ]}
            />
            <AddIndustry show={showAddIndustry} close={() => setShowAddIndustry(false)} industry={industry} />
        </>
    );
}
