import APIService from "./api.service";

class IndustryService extends APIService<Industry> {
    constructor() {
        super("/industries");
    }
}

export type Industry = {
    id?: number;
    name: string;
    notes: string;
};

export default new IndustryService();
