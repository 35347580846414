import APIService from "./api.service";

export type StageHistory = {
    id: number;
    processflow: number;
    stageFrom: number;
    stageTo: number;
    changeWhen: Date;
    syncChange: number;
    changeBy: string;
    notes: string;
};

class StageHistoryService extends APIService<StageHistory> {
    constructor() {
        super("stage-histories");
    }
}

export default new StageHistoryService();
