import { z } from "zod";

export const businessRuleTypeSchema = z.object({
    id: z.union([z.number(), z.undefined()]),
    name: z.string(),
    comparisonType: z.number().nullable(),
    usesField2: z.union([z.literal(0), z.literal(1)]),
    dataType: z.enum([
        "checkbox",
        "textfield",
        "textarea",
        "yesNo",
        "selectBox",
        "fileUpload",
        "state",
        "datePicker",
        "roleGroup",
        "valueCalculator",
        "video",
        "wysiwyg",
    ]),
    description: z.string(),
    matchValue: z.string().nullable(),
});

export type BusinessRuleType = z.infer<typeof businessRuleTypeSchema>;
