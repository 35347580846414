import { Link, useNavigate } from "react-router-dom";
import { User } from "../../jason-proof-of-concept/users/domain/user";
import { TBody, Table, Td, Tr } from "../../layout/table";
import { Company } from "../domain/company";
import { formatPhonenumber } from "../../sections/utilities/formatters";
import { Currency } from "../../billing/components";
import { useState } from "react";
import ButtonNeoGen from "../../layout/button-neogen";
import { EditCompanyModal } from "./modals/edit-company-modal";
import {
    getEstimatedPayoutFromData,
    getW2Employees,
} from "../../jason-proof-of-concept/other/actions/getEstimatedPayout";
import { EditUserModal } from "./modals/edit-user-modal";
import { EditCompanyStatusModal } from "./modals/edit-company-status-modal";
import { startCase } from "lodash";
import { timeAgo } from "../../sections/users/cards/calls-list";
import { useAuth } from "../../auth/use-auth";
import authService from "../../services/auth.service";
import { roleGroups } from "../../services/role-group.service";
import { useBreakpoint } from "../../hooks/appMedia";
import { EditCompanyRoleUserModal } from "./modals/edit-company-role-user-modal";

export const BasicInfo = ({
    company,
    applicationPercentageProgress: applicationProgress,
    owner,
    affiliate,
    accountant,
    taxAttorney,
    payMaster,
    onCompanyUpdated,
    onOwnerUpdated,
    onCompanyUserUpdated,
    applicationData,
}: {
    company: Company;
    applicationPercentageProgress: number;
    owner?: User;
    affiliate?: User;
    accountant?: User;
    taxAttorney?: User;
    payMaster?: User;
    onCompanyUpdated: (company: Company) => void;
    onOwnerUpdated: (user: User) => void;
    onCompanyUserUpdated: (user: User) => void;
    applicationData?: Record<string, any>;
}) => {
    const [editCompanyModalOpen, setEditCompanyModalOpen] = useState(false);
    const [editOwnerModalOpen, setEditOwnerModalOpen] = useState(false);
    const [editAffiliateModalOpen, setEditAffiliateModalOpen] = useState(false);
    const [editAccountantModalOpen, setEditAccountantModalOpen] = useState(false);
    const [editTaxAttorneyModalOpen, setEditTaxAttorneyModalOpen] = useState(false);
    const [editPayMasterModalOpen, setEditPayMasterModalOpen] = useState(false);
    const [editCompanyStatusModalOpen, setEditCompanyStatusModalOpen] = useState(false);
    const navigate = useNavigate();
    const user = authService.getCurrentUser()?.user;
    const usersRoleGroups = (user?.roleGroups || []) as any[];
    const isSuperUser = !!usersRoleGroups.find((rg) => rg.id === roleGroups.SuperUser);
    const w2Employees = applicationData ? getW2Employees({ data: applicationData }) : null;
    const estimatedValue = applicationData ? getEstimatedPayoutFromData({ data: applicationData }) : 0;

    const canEditStatus = isSuperUser;
    const canEditOwner = isSuperUser || (user?.id && (user.id === owner?.id || user.id === affiliate?.id));
    const canEditAffiliate = isSuperUser || user?.id === affiliate?.id || user?.id === owner?.id;
    const canEditAccountant = isSuperUser || user?.id === accountant?.id || user?.id === owner?.id;
    const canEditTaxAttorney = isSuperUser || user?.id === taxAttorney?.id || user?.id === owner?.id;
    const canEditPayMaster = isSuperUser || user?.id === payMaster?.id || user?.id === owner?.id;
    const breakpoints = useBreakpoint();
    const isMobile = breakpoints.breakpoint === "mobile";
    const isTablet = breakpoints.breakpoint === "tablet";

    return (
        <div>
            {editCompanyModalOpen && (
                <EditCompanyModal
                    company={company}
                    onClose={() => {
                        setEditCompanyModalOpen(false);
                    }}
                    onCompanyUpdated={(updatedCompany) => {
                        onCompanyUpdated(updatedCompany);
                        setEditCompanyModalOpen(false);
                    }}
                />
            )}
            {editOwnerModalOpen && owner && (
                <EditUserModal
                    user={owner}
                    companyId={company.id}
                    onClose={() => {
                        setEditOwnerModalOpen(false);
                    }}
                    onUserUpdated={(updatedOwner) => {
                        setEditOwnerModalOpen(false);
                        onOwnerUpdated(updatedOwner);
                    }}
                />
            )}
            {editAffiliateModalOpen && affiliate && (
                <EditCompanyRoleUserModal
                    roleName={"Affiliate"}
                    user={affiliate}
                    roleId={7}
                    companyId={company.id}
                    onClose={() => {
                        setEditAffiliateModalOpen(false);
                    }}
                    onUserUpdated={(user) => {
                        setEditAffiliateModalOpen(false);
                        onCompanyUserUpdated(user);
                    }}
                />
            )}
            {editAccountantModalOpen && accountant && (
                <EditCompanyRoleUserModal
                    roleName={"Accountant"}
                    user={accountant}
                    roleId={8}
                    companyId={company.id}
                    onClose={() => {
                        setEditAccountantModalOpen(false);
                    }}
                    onUserUpdated={(user) => {
                        setEditAccountantModalOpen(false);
                        onCompanyUserUpdated(user);
                    }}
                />
            )}
            {editTaxAttorneyModalOpen && taxAttorney && (
                <EditCompanyRoleUserModal
                    roleName={"Tax attorney"}
                    user={taxAttorney}
                    roleId={15}
                    companyId={company.id}
                    onClose={() => {
                        setEditTaxAttorneyModalOpen(false);
                    }}
                    onUserUpdated={(user) => {
                        setEditTaxAttorneyModalOpen(false);
                        onCompanyUserUpdated(user);
                    }}
                />
            )}
            {editPayMasterModalOpen && payMaster && (
                <EditCompanyRoleUserModal
                    roleName={"Paymaster"}
                    user={payMaster}
                    roleId={18}
                    companyId={company.id}
                    onClose={() => {
                        setEditPayMasterModalOpen(false);
                    }}
                    onUserUpdated={(user) => {
                        setEditPayMasterModalOpen(false);
                        onCompanyUserUpdated(user);
                    }}
                />
            )}
            {editCompanyStatusModalOpen && (
                <EditCompanyStatusModal
                    company={company}
                    onClose={() => {
                        setEditCompanyStatusModalOpen(false);
                    }}
                    onCompanyUpdated={(updatedCompany) => {
                        onCompanyUpdated(updatedCompany);
                        setEditCompanyStatusModalOpen(false);
                    }}
                />
            )}
            {!isMobile && !isTablet ? (
                <Table>
                    <TBody>
                        <Tr>
                            <Td style={{ width: 200, verticalAlign: "top", color: "gray" }}>Company</Td>
                            <Td>
                                <div className="flex flex-row justify-between">
                                    <div className="flex flex-col">
                                        <div style={{ fontSize: 16 }}>
                                            <b>{company.name}</b>
                                        </div>
                                        <div style={{ color: "gray" }}>
                                            {[
                                                company.address || undefined,
                                                company.city || undefined,
                                                company.state || undefined,
                                                company.country || undefined,
                                            ]
                                                .filter((t) => !!t)
                                                .join(", ")}
                                        </div>
                                    </div>
                                    <div>
                                        <ButtonNeoGen
                                            size="sm"
                                            type="outline"
                                            onClick={() => {
                                                setEditCompanyModalOpen(true);
                                            }}
                                        >
                                            Edit
                                        </ButtonNeoGen>
                                    </div>
                                </div>
                            </Td>
                        </Tr>
                        <Tr>
                            <Td style={{ width: 200, verticalAlign: "top", color: "gray" }}>Status</Td>
                            <Td>
                                <div className="flex flex-row justify-between">
                                    <div className="flex flex-col">
                                        <b>{company.status ? startCase(company.status) : "Not set"}</b>
                                        <span style={{ color: "gray" }}>
                                            {company.statusUpdatedAt
                                                ? timeAgo(company.statusUpdatedAt).toLocaleString()
                                                : null}
                                        </span>
                                    </div>
                                    {canEditStatus && (
                                        <div>
                                            <ButtonNeoGen
                                                size="sm"
                                                type="outline"
                                                onClick={() => {
                                                    setEditCompanyStatusModalOpen(true);
                                                }}
                                            >
                                                Edit
                                            </ButtonNeoGen>
                                        </div>
                                    )}
                                </div>
                            </Td>
                        </Tr>
                        <Tr>
                            <Td style={{ verticalAlign: "top", color: "gray" }}>Owner</Td>
                            <Td>
                                {owner && (
                                    <div className="flex flex-row justify-between">
                                        <div className="flex flex-col">
                                            <b style={{ fontSize: 16 }}>
                                                {[owner.firstName, owner.lastName].join(" ")}
                                            </b>
                                            <div>
                                                {owner.email ? (
                                                    <a
                                                        style={{ color: "gray", textDecoration: "underline" }}
                                                        href={`mailto:${owner.email}`}
                                                    >
                                                        {owner.email}
                                                    </a>
                                                ) : (
                                                    "No email set"
                                                )}
                                            </div>
                                            <div>
                                                {owner.phone ? (
                                                    <a
                                                        style={{ color: "gray", textDecoration: "underline" }}
                                                        href={`tel:${owner.phone}`}
                                                    >
                                                        {formatPhonenumber(owner.phone)}
                                                    </a>
                                                ) : (
                                                    "No phone number set"
                                                )}
                                            </div>
                                        </div>
                                        {canEditOwner && (
                                            <div>
                                                <ButtonNeoGen
                                                    size="sm"
                                                    type="outline"
                                                    onClick={() => {
                                                        setEditOwnerModalOpen(true);
                                                    }}
                                                >
                                                    Edit
                                                </ButtonNeoGen>
                                            </div>
                                        )}
                                    </div>
                                )}
                            </Td>
                        </Tr>
                        <Tr>
                            <Td style={{ verticalAlign: "top", color: "gray" }}>Application</Td>
                            <Td>
                                <div className="flex flex-row justify-between">
                                    <div className="flex flex-col">
                                        <div>
                                            Progress: <b>{applicationProgress}%</b>
                                        </div>
                                        <div>
                                            2020 W2 Employees: <b>{w2Employees?.w2Employees2020 || 0}</b>
                                        </div>
                                        <div>
                                            2021 W2 Employees: <b>{w2Employees?.w2Employees2021 || 0}</b>
                                        </div>
                                        <div>
                                            Estimated value:{" "}
                                            <b>
                                                <Currency amount={estimatedValue} />
                                            </b>
                                        </div>
                                    </div>
                                    <div>
                                        <ButtonNeoGen
                                            size="sm"
                                            type="outline"
                                            onClick={() => {
                                                navigate(`/companies/${company.id}?tab=application`);
                                            }}
                                        >
                                            View application
                                        </ButtonNeoGen>
                                    </div>
                                </div>
                            </Td>
                        </Tr>
                        <Tr>
                            <Td style={{ width: 200, verticalAlign: "top", color: "gray" }}>Affiliate</Td>
                            <Td>
                                {affiliate ? (
                                    <div className="flex flex-row justify-between">
                                        <div className="flex flex-col">
                                            <b style={{ fontSize: 16 }}>
                                                {[affiliate.firstName, affiliate.lastName].join(" ")}
                                            </b>
                                            <div>
                                                {affiliate.email ? (
                                                    <a
                                                        style={{ color: "gray", textDecoration: "underline" }}
                                                        href={`mailto:${affiliate.email}`}
                                                    >
                                                        {affiliate.email}
                                                    </a>
                                                ) : (
                                                    "No email set"
                                                )}
                                            </div>
                                            <div>
                                                {affiliate.phone ? (
                                                    <a
                                                        style={{ color: "gray", textDecoration: "underline" }}
                                                        href={`tel:${affiliate.phone}`}
                                                    >
                                                        {formatPhonenumber(affiliate.phone)}
                                                    </a>
                                                ) : (
                                                    "No phone number set"
                                                )}
                                            </div>
                                            <div>
                                                Affiliate ID: {affiliate.id} (
                                                <a
                                                    onClick={() => {
                                                        navigator.clipboard.writeText(affiliate.id);
                                                    }}
                                                    href="#"
                                                >
                                                    Copy
                                                </a>
                                                )
                                            </div>
                                            <div>
                                                <a
                                                    href={`/deals?userId=${affiliate.id}`}
                                                    className="text-decoration-underline"
                                                    style={{ color: "gray", textDecoration: "underline" }}
                                                >
                                                    View affiliate deals
                                                </a>
                                            </div>
                                        </div>
                                        {canEditAffiliate && (
                                            <div>
                                                <ButtonNeoGen
                                                    size="sm"
                                                    type="outline"
                                                    onClick={() => {
                                                        setEditAffiliateModalOpen(true);
                                                    }}
                                                >
                                                    Edit
                                                </ButtonNeoGen>
                                            </div>
                                        )}
                                    </div>
                                ) : (
                                    <div>No affiliate</div>
                                )}
                            </Td>
                        </Tr>
                        <Tr>
                            <Td style={{ width: 200, verticalAlign: "top", color: "gray" }}>Accountant</Td>
                            <Td>
                                {accountant ? (
                                    <div className="flex flex-row justify-between">
                                        <div className="flex flex-col">
                                            <b style={{ fontSize: 16 }}>
                                                {[accountant.firstName, accountant.lastName].join(" ")}
                                            </b>
                                            <div>
                                                {accountant.email ? (
                                                    <a
                                                        style={{ color: "gray", textDecoration: "underline" }}
                                                        href={`mailto:${accountant.email}`}
                                                    >
                                                        {accountant.email}
                                                    </a>
                                                ) : (
                                                    "No email set"
                                                )}
                                            </div>
                                            <div>
                                                {accountant.phone ? (
                                                    <a
                                                        style={{ color: "gray", textDecoration: "underline" }}
                                                        href={`tel:${accountant.phone}`}
                                                    >
                                                        {formatPhonenumber(accountant.phone)}
                                                    </a>
                                                ) : (
                                                    "No phone number set"
                                                )}
                                            </div>
                                            <div>
                                                Accountant ID: {accountant.id} (
                                                <a
                                                    onClick={() => {
                                                        navigator.clipboard.writeText(accountant.id);
                                                    }}
                                                    href="#"
                                                >
                                                    Copy
                                                </a>
                                                )
                                            </div>
                                        </div>
                                        {canEditAccountant && (
                                            <div>
                                                <ButtonNeoGen
                                                    size="sm"
                                                    type="outline"
                                                    onClick={() => {
                                                        setEditAccountantModalOpen(true);
                                                    }}
                                                >
                                                    Edit
                                                </ButtonNeoGen>
                                            </div>
                                        )}
                                    </div>
                                ) : (
                                    <div>No accountant</div>
                                )}
                            </Td>
                        </Tr>
                        <Tr>
                            <Td style={{ width: 200, verticalAlign: "top", color: "gray" }}>Tax attorney</Td>
                            <Td>
                                {taxAttorney ? (
                                    <div className="flex flex-row justify-between">
                                        <div className="flex flex-col">
                                            <b style={{ fontSize: 16 }}>
                                                {[taxAttorney.firstName, taxAttorney.lastName].join(" ")}
                                            </b>
                                            <div>
                                                {taxAttorney.email ? (
                                                    <a
                                                        style={{ color: "gray", textDecoration: "underline" }}
                                                        href={`mailto:${taxAttorney.email}`}
                                                    >
                                                        {taxAttorney.email}
                                                    </a>
                                                ) : (
                                                    "No email set"
                                                )}
                                            </div>
                                            <div>
                                                {taxAttorney.phone ? (
                                                    <a
                                                        style={{ color: "gray", textDecoration: "underline" }}
                                                        href={`tel:${taxAttorney.phone}`}
                                                    >
                                                        {formatPhonenumber(taxAttorney.phone)}
                                                    </a>
                                                ) : (
                                                    "No phone number set"
                                                )}
                                            </div>
                                            <div>
                                                Tax attorney ID: {taxAttorney.id} (
                                                <a
                                                    onClick={() => {
                                                        navigator.clipboard.writeText(taxAttorney.id);
                                                    }}
                                                    href="#"
                                                >
                                                    Copy
                                                </a>
                                                )
                                            </div>
                                        </div>
                                        {canEditTaxAttorney && (
                                            <div>
                                                <ButtonNeoGen
                                                    size="sm"
                                                    type="outline"
                                                    onClick={() => {
                                                        setEditTaxAttorneyModalOpen(true);
                                                    }}
                                                >
                                                    Edit
                                                </ButtonNeoGen>
                                            </div>
                                        )}
                                    </div>
                                ) : (
                                    <div>No tax attorney</div>
                                )}
                            </Td>
                        </Tr>
                        <Tr>
                            <Td style={{ width: 200, verticalAlign: "top", color: "gray" }}>Paymaster</Td>
                            <Td>
                                {payMaster ? (
                                    <div className="flex flex-row justify-between">
                                        <div className="flex flex-col">
                                            <b style={{ fontSize: 16 }}>
                                                {[payMaster.firstName, payMaster.lastName].join(" ")}
                                            </b>
                                            <div>
                                                {payMaster.email ? (
                                                    <a
                                                        style={{ color: "gray", textDecoration: "underline" }}
                                                        href={`mailto:${payMaster.email}`}
                                                    >
                                                        {payMaster.email}
                                                    </a>
                                                ) : (
                                                    "No email set"
                                                )}
                                            </div>
                                            <div>
                                                {payMaster.phone ? (
                                                    <a
                                                        style={{ color: "gray", textDecoration: "underline" }}
                                                        href={`tel:${payMaster.phone}`}
                                                    >
                                                        {formatPhonenumber(payMaster.phone)}
                                                    </a>
                                                ) : (
                                                    "No phone number set"
                                                )}
                                            </div>
                                            <div>
                                                Paymaster ID: {payMaster.id} (
                                                <a
                                                    onClick={() => {
                                                        navigator.clipboard.writeText(payMaster.id);
                                                    }}
                                                    href="#"
                                                >
                                                    Copy
                                                </a>
                                                )
                                            </div>
                                        </div>
                                        {canEditPayMaster && (
                                            <div>
                                                <ButtonNeoGen
                                                    size="sm"
                                                    type="outline"
                                                    onClick={() => {
                                                        setEditPayMasterModalOpen(true);
                                                    }}
                                                >
                                                    Edit
                                                </ButtonNeoGen>
                                            </div>
                                        )}
                                    </div>
                                ) : (
                                    <div>No paymaster</div>
                                )}
                            </Td>
                        </Tr>
                    </TBody>
                </Table>
            ) : (
                <div className="p-4 flex flex-col gap-4">
                    <div className="flex flex-row justify-between">
                        <div className="flex flex-col">
                            <div className="text-xs text-gray-500">Company</div>
                            <div style={{ fontSize: 16 }}>
                                <b>{company.name}</b>
                            </div>
                            <div style={{ color: "gray" }}>
                                {!company.address && !company.city && !company.state && !company.country
                                    ? ""
                                    : [company.address, company.city, company.state, company.country].join(", ")}
                            </div>
                        </div>
                        <div>
                            <ButtonNeoGen
                                size="sm"
                                type="outline"
                                onClick={() => {
                                    setEditCompanyModalOpen(true);
                                }}
                            >
                                Edit
                            </ButtonNeoGen>
                        </div>
                    </div>

                    <div className="flex flex-row justify-between">
                        <div className="flex flex-col">
                            <div className="text-xs text-gray-500">Status</div>
                            <div style={{ fontSize: 16 }}>
                                <b>{company.status ? startCase(company.status) : "Not set"}</b>
                                <span style={{ color: "gray" }}>
                                    {company.statusUpdatedAt ? timeAgo(company.statusUpdatedAt).toLocaleString() : null}
                                </span>
                            </div>
                        </div>
                        {canEditStatus && (
                            <div>
                                <ButtonNeoGen
                                    size="sm"
                                    type="outline"
                                    onClick={() => {
                                        setEditCompanyStatusModalOpen(true);
                                    }}
                                >
                                    Edit
                                </ButtonNeoGen>
                            </div>
                        )}
                    </div>

                    {owner && (
                        <div className="flex flex-row justify-between">
                            <div className="flex flex-col">
                                <div className="text-xs text-gray-500">Owner</div>
                                <b style={{ fontSize: 16 }}>{[owner.firstName, owner.lastName].join(" ")}</b>
                                <div>
                                    {owner.email ? (
                                        <a
                                            style={{ color: "gray", textDecoration: "underline", fontSize: 12 }}
                                            href={`mailto:${owner.email}`}
                                        >
                                            {owner.email}
                                        </a>
                                    ) : (
                                        "No email set"
                                    )}
                                </div>
                                <div>
                                    {owner.phone ? (
                                        <a
                                            style={{ color: "gray", textDecoration: "underline", fontSize: 12 }}
                                            href={`tel:${owner.phone}`}
                                        >
                                            {formatPhonenumber(owner.phone)}
                                        </a>
                                    ) : (
                                        "No phone number set"
                                    )}
                                </div>
                            </div>
                            {canEditOwner && (
                                <div>
                                    <ButtonNeoGen
                                        size="sm"
                                        type="outline"
                                        onClick={() => {
                                            setEditOwnerModalOpen(true);
                                        }}
                                    >
                                        Edit
                                    </ButtonNeoGen>
                                </div>
                            )}
                        </div>
                    )}

                    <div className="flex flex-row justify-between">
                        <div className="flex flex-col">
                            <div className="text-xs text-gray-500">Application</div>
                            <div className="text-sm">
                                Progress: <b>{applicationProgress}%</b>
                            </div>
                            <div className="text-sm">
                                2020 W2 Employees: <b>{w2Employees?.w2Employees2020 || 0}</b>
                            </div>
                            <div className="text-sm">
                                2021 W2 Employees: <b>{w2Employees?.w2Employees2021 || 0}</b>
                            </div>
                            <div className="text-sm">
                                Estimated value:{" "}
                                <b>
                                    <Currency amount={estimatedValue} />
                                </b>
                            </div>
                        </div>
                        <div>
                            <ButtonNeoGen
                                size="sm"
                                type="outline"
                                onClick={() => {
                                    navigate(`/companies/${company.id}?tab=application`);
                                }}
                            >
                                View application
                            </ButtonNeoGen>
                        </div>
                    </div>

                    <div className="flex flex-row justify-between">
                        <div className="flex flex-col">
                            <div className="text-xs text-gray-500">Affiliate</div>
                            {affiliate ? (
                                <>
                                    <b style={{ fontSize: 16 }}>
                                        {[affiliate.firstName, affiliate.lastName].join(" ")}
                                    </b>
                                    <div>
                                        {affiliate.email ? (
                                            <a
                                                style={{ color: "gray", textDecoration: "underline", fontSize: 12 }}
                                                href={`mailto:${affiliate.email}`}
                                            >
                                                {affiliate.email}
                                            </a>
                                        ) : (
                                            "No email set"
                                        )}
                                    </div>
                                    <div>
                                        {affiliate.phone ? (
                                            <a
                                                style={{ color: "gray", textDecoration: "underline", fontSize: 12 }}
                                                href={`tel:${affiliate.phone}`}
                                            >
                                                {formatPhonenumber(affiliate.phone)}
                                            </a>
                                        ) : (
                                            "No phone number set"
                                        )}
                                    </div>
                                </>
                            ) : (
                                <p>No Affiliate</p>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};
