import { useCallback, useEffect, useMemo, useState } from "react";
import Loader2 from "../../sections/utilities/Loader2";
import PageDescription from "../../layout/page-description";
import { search as ssSearch } from "ss-search";
import { debounce } from "lodash";
import { getAuthTokenNoThrow } from "../../services/auth-header";
import { useUsers } from "../../jason-proof-of-concept/users/hooks/use-users";
import { useRoleGroups } from "../../role-groups/hooks/use-role-groups";
import { TBody, THead, Table, Td, Th, Tr } from "../../layout/table";
import { useBreakpoint } from "../../hooks/appMedia";
import { Currency } from "../../billing/components";
import SearchField from "../../layout/search-field";
import { usePayments } from "../hooks/use-payments";
import { Payment } from "../domain/payment";
import { Link } from "react-router-dom";
import OptionsDropDown from "../../layout/options-dropdown";
import { PaymentRequest } from "../../payment-requests/domain/payment-request";
import { CreatePaymentRequestModal } from "../../companies/components/modals/create-payment-request-modal";
import { CreatePaymentModal } from "../../companies/components/modals/create-payment-modal";
import Toggle from "../../layout/toggle";

export function PaymentsPage() {
    const [search, setSearch] = useState("");
    const [debouncedSearch, setDebouncedSearch] = useState("");
    const authToken = getAuthTokenNoThrow() || "no-auth";
    const [showCapturePaymentModal, setShowCapturePaymentModal] = useState<
        (Partial<Payment> & { companyId: number }) | undefined
    >();
    const [showNewPaymentRequestModal, setShowNewPaymentRequestModalWithValues] = useState<
        (Partial<PaymentRequest> & { maxAmount?: number; companyId: number }) | undefined
    >();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const updateDebouncedSearch = useCallback(
        debounce(
            (term) => {
                setDebouncedSearch(term);
            },
            700,
            { trailing: true, maxWait: 1000 },
        ),
        [],
    );
    useEffect(() => {
        updateDebouncedSearch(search);
    }, [search, updateDebouncedSearch]);

    const paymentsQuery = usePayments({
        authToken,
        filters: { ...(status !== null ? { where: { status: status } } : {}) },
    });
    const payments = useMemo(() => paymentsQuery.data || [], [paymentsQuery.data]);
    const filteredPayments = useMemo(
        () =>
            debouncedSearch
                ? (ssSearch(
                      payments,
                      [
                          "from",
                          "fromUser.firstName",
                          "fromUser.lastName",
                          "fromUser.email",
                          "fromCompany.name",
                          "to",
                          "toUser.firstName",
                          "toUser.lastName",
                          "toUser.email",
                          "toCompany.name",
                          "company.name",
                          "description",
                      ],
                      debouncedSearch,
                  ) as Payment[])
                : payments,
        [payments, debouncedSearch],
    );
    const filteredPaymentsToClearErc = useMemo(
        () => filteredPayments.filter((p) => p.toClearErc === true),
        [filteredPayments],
    );
    const filteredPaymentsFromClearErc = useMemo(
        () => filteredPayments.filter((pr) => pr.toClearErc === false || pr.fromClearErc === true || pr.toUserId),
        [filteredPayments],
    );

    const usersQuery = useUsers({ authToken });
    const [searchOpen, setSearchOpen] = useState(false);
    const roleGroupsQuery = useRoleGroups({ authToken });
    const breakpoints = useBreakpoint();
    const [isToClearErc, setIsToClearErc] = useState(0);

    return (
        <>
            {showNewPaymentRequestModal && (
                <CreatePaymentRequestModal
                    companyId={showNewPaymentRequestModal.companyId}
                    defaultValues={showNewPaymentRequestModal}
                    fromUser={showNewPaymentRequestModal.fromUser || undefined}
                    fromCompany={showNewPaymentRequestModal.fromCompany || undefined}
                    toUser={showNewPaymentRequestModal.toUser || undefined}
                    toCompany={showNewPaymentRequestModal.toCompany || undefined}
                    onClose={() => {
                        setShowNewPaymentRequestModalWithValues(undefined);
                    }}
                    maxAmount={showNewPaymentRequestModal.maxAmount}
                    onCreate={() => {
                        setShowNewPaymentRequestModalWithValues(undefined);
                    }}
                />
            )}
            {showCapturePaymentModal && (
                <CreatePaymentModal
                    companyId={showCapturePaymentModal.companyId}
                    defaultValues={showCapturePaymentModal}
                    fromUser={showCapturePaymentModal.fromUser || undefined}
                    fromCompany={showCapturePaymentModal.fromCompany || undefined}
                    toUser={showCapturePaymentModal.toUser || undefined}
                    toCompany={showCapturePaymentModal.toCompany || undefined}
                    onClose={() => {
                        setShowCapturePaymentModal(undefined);
                    }}
                    onPaymentCaptured={() => {
                        setShowCapturePaymentModal(undefined);
                        paymentsQuery.refetch();
                    }}
                />
            )}
            <PageDescription title="Payments">
                <div className="flex flex-col items-end justify-end gap-4">
                    <div className="flex flex-row gap-4 items-center">
                        <div className="text-left">
                            Search
                            <SearchField search={search} setSearch={setSearch} placeholder="Search payments..." />
                        </div>
                    </div>
                    <div className="w-full">
                        <Toggle
                            options={["To ClearERC", "From ClearERC"]}
                            setSelectedIndex={setIsToClearErc}
                            selectedIndex={isToClearErc}
                        />
                    </div>
                </div>
            </PageDescription>
            <div>
                {paymentsQuery.isLoading && (
                    <div className={"p-5 text-center"}>
                        <Loader2 />
                    </div>
                )}
                {isToClearErc ? (
                    <Table>
                        <THead>
                            <Tr>
                                <Th>Description</Th>
                                <Th>Company</Th>
                                <Td>Date</Td>
                                <Th>From</Th>
                                <Th>To</Th>
                                <Th>Reference</Th>
                                <Th>Amount</Th>
                                <Th />
                            </Tr>
                        </THead>
                        <TBody>
                            {filteredPaymentsFromClearErc.length === 0 && (
                                <Tr>
                                    <Td colSpan={6} style={{ textAlign: "center" }}>
                                        No payments
                                    </Td>
                                </Tr>
                            )}
                            {filteredPaymentsFromClearErc.length > 0 &&
                                filteredPaymentsFromClearErc.map((payment) => {
                                    return (
                                        <Tr key={payment.id}>
                                            <Td>{payment.description || "-"}</Td>
                                            <Td>
                                                {payment.company && (
                                                    <Link to={`/companies/${payment.companyId}/`}>
                                                        {payment.company.name}
                                                    </Link>
                                                )}
                                            </Td>
                                            <Td>{payment.date.toDateString() || "-"}</Td>
                                            <Td>
                                                {payment.fromClearErc
                                                    ? "ClearERC"
                                                    : payment.fromUser
                                                    ? [payment.fromUser.firstName, payment.fromUser.lastName].join(" ")
                                                    : payment.from || payment.fromCompany?.name}
                                            </Td>
                                            <Td>
                                                {payment.toClearErc
                                                    ? "ClearERC"
                                                    : payment.toUser
                                                    ? [payment.toUser.firstName, payment.toUser.lastName].join(" ")
                                                    : payment.to || payment.toCompany?.name || "-"}
                                            </Td>
                                            <Td>{payment.reference || "-"}</Td>
                                            <Td>
                                                <Currency amount={payment.amount} />
                                            </Td>
                                            <Th>
                                                <OptionsDropDown
                                                    options={[
                                                        {
                                                            label: "Create reversal request",
                                                            onClick: () => {
                                                                setShowNewPaymentRequestModalWithValues({
                                                                    companyId: payment.companyId,
                                                                    description: `Reversal: ${payment.description}`,
                                                                    amount: payment.amount,
                                                                    from: payment.to || undefined,
                                                                    fromClearErc: payment.toClearErc || undefined,
                                                                    fromCompanyId: payment.toCompanyId || undefined,
                                                                    fromCompany: payment.toCompany || undefined,
                                                                    fromUserId: payment.toUserId || undefined,
                                                                    to: payment.from || undefined,
                                                                    toClearErc: payment.fromClearErc || undefined,
                                                                    toCompanyId: payment.fromCompanyId || undefined,
                                                                    toCompany: payment.fromCompany || undefined,
                                                                    toUserId: payment.fromUserId || undefined,
                                                                });
                                                            },
                                                        },
                                                        {
                                                            label: "Reverse transaction",
                                                            onClick: () => {
                                                                setShowCapturePaymentModal({
                                                                    companyId: payment.companyId,
                                                                    description: `Reversal: ${payment.description}`,
                                                                    amount: payment.amount,
                                                                    from: payment.to || undefined,
                                                                    fromClearErc: payment.toClearErc || undefined,
                                                                    fromCompanyId: payment.toCompanyId || undefined,
                                                                    fromCompany: payment.toCompany || undefined,
                                                                    fromUserId: payment.toUserId || undefined,
                                                                    to: payment.from || undefined,
                                                                    toClearErc: payment.fromClearErc || undefined,
                                                                    toCompanyId: payment.fromCompanyId || undefined,
                                                                    toCompany: payment.fromCompany || undefined,
                                                                    toUserId: payment.fromUserId || undefined,
                                                                });
                                                            },
                                                        },
                                                    ]}
                                                />
                                            </Th>
                                        </Tr>
                                    );
                                })}
                        </TBody>
                    </Table>
                ) : (
                    <Table>
                        <THead>
                            <Tr>
                                <Th>Description</Th>
                                <Th>Company</Th>
                                <Td>Date</Td>
                                <Th>From</Th>
                                <Th>To</Th>
                                <Th>Reference</Th>
                                <Th>Amount</Th>
                                <Th />
                            </Tr>
                        </THead>
                        <TBody>
                            {filteredPaymentsToClearErc.length === 0 && (
                                <Tr>
                                    <Td colSpan={6} style={{ textAlign: "center" }}>
                                        No payments
                                    </Td>
                                </Tr>
                            )}
                            {filteredPaymentsToClearErc.length > 0 &&
                                filteredPaymentsToClearErc.map((payment) => {
                                    return (
                                        <Tr key={payment.id}>
                                            <Td>{payment.description || "-"}</Td>
                                            <Td>
                                                {payment.company && (
                                                    <Link to={`/companies/${payment.companyId}/`}>
                                                        {payment.company.name}
                                                    </Link>
                                                )}
                                            </Td>
                                            <Td>{payment.date.toDateString() || "-"}</Td>
                                            <Td>
                                                {payment.fromClearErc
                                                    ? "ClearERC"
                                                    : payment.fromUser
                                                    ? [payment.fromUser.firstName, payment.fromUser.lastName].join(" ")
                                                    : payment.from || payment.fromCompany?.name}
                                            </Td>
                                            <Td>
                                                {payment.toClearErc
                                                    ? "ClearERC"
                                                    : payment.toUser
                                                    ? [payment.toUser.firstName, payment.toUser.lastName].join(" ")
                                                    : payment.to || payment.toCompany?.name || "-"}
                                            </Td>
                                            <Td>{payment.reference || "-"}</Td>
                                            <Td>
                                                <Currency amount={payment.amount} />
                                            </Td>
                                            <Th>
                                                <OptionsDropDown
                                                    options={[
                                                        {
                                                            label: "Create reversal request",
                                                            onClick: () => {
                                                                setShowNewPaymentRequestModalWithValues({
                                                                    companyId: payment.companyId,
                                                                    description: `Reversal: ${payment.description}`,
                                                                    amount: payment.amount,
                                                                    from: payment.to || undefined,
                                                                    fromClearErc: payment.toClearErc || undefined,
                                                                    fromCompanyId: payment.toCompanyId || undefined,
                                                                    fromCompany: payment.toCompany || undefined,
                                                                    fromUserId: payment.toUserId || undefined,
                                                                    to: payment.from || undefined,
                                                                    toClearErc: payment.fromClearErc || undefined,
                                                                    toCompanyId: payment.fromCompanyId || undefined,
                                                                    toCompany: payment.fromCompany || undefined,
                                                                    toUserId: payment.fromUserId || undefined,
                                                                });
                                                            },
                                                        },
                                                        {
                                                            label: "Reverse transaction",
                                                            onClick: () => {
                                                                setShowCapturePaymentModal({
                                                                    companyId: payment.companyId,
                                                                    description: `Reversal: ${payment.description}`,
                                                                    amount: payment.amount,
                                                                    from: payment.to || undefined,
                                                                    fromClearErc: payment.toClearErc || undefined,
                                                                    fromCompanyId: payment.toCompanyId || undefined,
                                                                    fromCompany: payment.toCompany || undefined,
                                                                    fromUserId: payment.toUserId || undefined,
                                                                    to: payment.from || undefined,
                                                                    toClearErc: payment.fromClearErc || undefined,
                                                                    toCompanyId: payment.fromCompanyId || undefined,
                                                                    toCompany: payment.fromCompany || undefined,
                                                                    toUserId: payment.fromUserId || undefined,
                                                                });
                                                            },
                                                        },
                                                    ]}
                                                />
                                            </Th>
                                        </Tr>
                                    );
                                })}
                        </TBody>
                    </Table>
                )}
            </div>
        </>
    );
}
