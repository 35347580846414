import { z } from "zod";
import ModalDialog from "../../../layout/modal-dialog";
import { useForm } from "../../../hooks/useForm";
import { Form } from "../../../layout/form/form";
import { TextField } from "../../../layout/form/text-field";
import { getAuthTokenNoThrow } from "../../../services/auth-header";
import ButtonNeoGen from "../../../layout/button-neogen";
import { Company, CompanyStatus } from "../../domain/company";
import { SelectField } from "../../../layout/form/select-field";
import { useEffect, useMemo, useState } from "react";
import { User } from "../../../jason-proof-of-concept/users/domain/user";
import { sortUsers } from "../../../sections/utilities/sortUsers";
import { useMutation } from "@tanstack/react-query";
import { useSetupCompany } from "../../hooks/use-setup-company";
import { RoleGroup } from "../../../role-groups/domain/role-group";
import { useUpdateCompany } from "../../hooks/use-update-company";
import { startCase } from "lodash";
import { NumberField } from "../../../layout/form/number-field";
import {
    getEstimatedPayoutFromData,
    getW2Employees,
} from "../../../jason-proof-of-concept/other/actions/getEstimatedPayout";
import { Currency } from "../../../billing/components";
import { UserCompany } from "../../../user-companies/domain/user-company";
import { useUpdateUserCompany } from "../../../user-companies/hooks/use-update-user-company";
import { Td, Th } from "../../../layout/table";
import { CurrencyField } from "../../../layout/form/currency-field";
import { DateTimeField } from "../../../layout/form/date-field";
import { formatCurrency } from "../../../billing/utils";

const schema = z.object({
    totalFeeAmount: z.number().nullish(),
    totalFeePercent: z.number().nullish(),
    totalFeeType: z.enum(["amount", "percentage"]),
    upfrontFeeAmount: z.number().nullish(),
    upfrontFeePercent: z.number().nullish(),
    upfrontFeeType: z.enum(["amount", "percentage"]),
    upfrontFeeDate: z.date().nullish(),
    depositFeeAmount: z.number().nullish(),
    depositFeePercent: z.number().nullish(),
    depositFeeType: z.enum(["amount", "percentage"]),
    depositFeeDate: z.date().nullish(),
    laterFeeAmount: z.number().nullish(),
    laterFeePercent: z.number().nullish(),
    laterFeeType: z.enum(["amount", "percentage"]),
    laterFeeDate: z.date().nullish(),
});

type Data = z.infer<typeof schema>;

export const EditUserCommissionModal = ({
    onClose,
    company,
    onUpdated,
    dealValue,
    clearErcFee,
    user,
    userCompany,
}: {
    onClose: () => any;
    company: Company;
    onUpdated: (userCompany: UserCompany) => void;
    dealValue: number;
    clearErcFee: number;
    user: User;
    userCompany: UserCompany;
}) => {
    const authToken = getAuthTokenNoThrow() || "no-auth-token";
    const [errorMessage, setErrorMessage] = useState("");

    const defaultValues: Data = {
        totalFeePercent: userCompany.totalFeePercent || 0,
        totalFeeAmount: userCompany.totalFeeAmount || 0,
        totalFeeType: userCompany.totalFeeType || "percentage",
        upfrontFeePercent: userCompany.upfrontFeePercent || 0,
        upfrontFeeAmount: userCompany.upfrontFeeAmount || 0,
        upfrontFeeType: userCompany.upfrontFeeType || "percentage",
        upfrontFeeDate: userCompany.upfrontFeeDate || undefined,
        depositFeePercent: userCompany.depositFeePercent || 0,
        depositFeeAmount: userCompany.depositFeeAmount || 0,
        depositFeeType: userCompany.depositFeeType || "percentage",
        depositFeeDate: userCompany.depositFeeDate || undefined,
        laterFeePercent: userCompany.laterFeePercent || 0,
        laterFeeAmount: userCompany.laterFeeAmount || 0,
        laterFeeType: userCompany.laterFeeType || "percentage",
        laterFeeDate: userCompany.laterFeeDate || undefined,
    };

    const form = useForm({ schema, defaultValues });

    const updateUserCompanyMutation = useUpdateUserCompany();

    const submitMutation = useMutation({
        mutationFn: async (data: Data) => {
            const updatedUserCompany = await updateUserCompanyMutation.mutateAsync({
                authToken,
                id: userCompany.id,
                data: {
                    totalFeePercent: data.totalFeePercent || 0,
                    totalFeeAmount: data.totalFeeAmount || 0,
                    totalFeeType: data.totalFeeType || "percentage",
                    upfrontFeePercent: data.upfrontFeePercent || 0,
                    upfrontFeeAmount: data.upfrontFeeAmount || 0,
                    upfrontFeeType: data.upfrontFeeType || "percentage",
                    upfrontFeeDate: data.upfrontFeeDate,
                    depositFeePercent: data.depositFeePercent || 0,
                    depositFeeAmount: data.depositFeeAmount || 0,
                    depositFeeType: data.depositFeeType || "percentage",
                    depositFeeDate: data.depositFeeDate,
                    laterFeePercent: data.laterFeePercent || 0,
                    laterFeeAmount: data.laterFeeAmount || 0,
                    laterFeeType: data.laterFeeType || "percentage",
                    laterFeeDate: data.laterFeeDate,
                },
            });
            return updatedUserCompany;
        },
    });

    const handleSubmit = async (data: Data) => {
        setErrorMessage("");
        if (
            data.totalFeeAmount !==
            (data.upfrontFeeAmount || 0) + (data.depositFeeAmount || 0) + (data.laterFeeAmount || 0)
        ) {
            form.setError("upfrontFeeAmount", { message: `Must sum to ${formatCurrency(data.totalFeeAmount || 0)}` });
            form.setError("depositFeeAmount", { message: `Must sum to ${formatCurrency(data.totalFeeAmount || 0)}` });
            form.setError("laterFeeAmount", { message: `Must sum to ${formatCurrency(data.totalFeeAmount || 0)}` });
            setErrorMessage(`Fee amount must sum to the total of ${formatCurrency(data.totalFeeAmount || 0)}`);
            return;
        }
        if (
            data.totalFeePercent !==
            (data.upfrontFeePercent || 0) + (data.depositFeePercent || 0) + (data.laterFeePercent || 0)
        ) {
            form.setError("upfrontFeePercent", { message: `Must sum to ${data.totalFeePercent}%` });
            form.setError("depositFeePercent", { message: `Must sum to ${data.totalFeePercent}%` });
            form.setError("laterFeePercent", { message: `Must sum to ${data.totalFeePercent}%` });
            setErrorMessage(`Fee percent must sum to the total of ${data.totalFeePercent}%`);
            return;
        }

        const userCompany = await submitMutation.mutateAsync(data);
        onUpdated(userCompany);
    };

    return (
        <>
            <ModalDialog
                show
                title={"Edit user commission"}
                close={onClose}
                showOk={false}
                showCancel={false}
                size="md"
            >
                <Form onSubmit={form.handleSubmit(handleSubmit as any)} error={updateUserCompanyMutation.error as any}>
                    {errorMessage && <p className="bg-red-100 text-red-600 p-4 rounded-lg">{errorMessage}</p>}
                    <div className="flex flex-col gap-1 mb-4" style={{ fontSize: 14 }}>
                        <div>
                            <b>
                                {user.firstName} {user.lastName}
                            </b>
                        </div>
                        <div>
                            Confirmed deal value:{" "}
                            <b>
                                <Currency amount={dealValue} />
                            </b>
                        </div>
                        <div>
                            Gross ClearERC fee:{" "}
                            <b>
                                <Currency amount={clearErcFee} />
                            </b>
                        </div>
                    </div>
                    <div>
                        <div>
                            <table style={{ width: "100%", tableLayout: "fixed" }}>
                                <thead>
                                    <tr>
                                        <Th style={{ width: 70 }} />
                                        <Th style={{ width: 150 }}>Amount</Th>
                                        <Th style={{ width: 130 }}>Percent</Th>
                                        <Th style={{ width: 160 }}>Expected Date</Th>
                                        <Th style={{ width: 170 }}>Type</Th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <Td style={{ fontWeight: "bold" }}>Total:</Td>
                                        <Td>
                                            <CurrencyField
                                                noMargin
                                                {...form.getFieldProps("totalFeeAmount")}
                                                onChange={(e) => {
                                                    form.setValue("totalFeeType", "amount");
                                                    form.setValue(
                                                        "totalFeePercent",
                                                        Number.parseFloat(
                                                            (
                                                                (Number.parseFloat(e.target.value || "0") * 100) /
                                                                clearErcFee
                                                            ).toFixed(2),
                                                        ),
                                                    );
                                                }}
                                            />
                                        </Td>
                                        <Td>
                                            <NumberField
                                                noMargin
                                                {...form.getFieldProps("totalFeePercent")}
                                                onChange={(e) => {
                                                    form.setValue("totalFeeType", "percentage");
                                                    form.setValue(
                                                        "totalFeeAmount",
                                                        Number.parseFloat(
                                                            (
                                                                clearErcFee *
                                                                (Number.parseFloat(e.target.value || "0") / 100)
                                                            ).toFixed(2),
                                                        ),
                                                    );
                                                }}
                                            />
                                        </Td>
                                        <Td />
                                        <Td>
                                            <SelectField
                                                isSearchable={false}
                                                noMargin
                                                {...form.getFieldProps("totalFeeType")}
                                                options={[
                                                    { value: "percentage", label: "Percent" },
                                                    { value: "amount", label: "Amount" },
                                                ]}
                                            />
                                        </Td>
                                    </tr>
                                    <tr>
                                        <Td style={{ fontWeight: "bold" }}>Upfront:</Td>
                                        <Td>
                                            <CurrencyField
                                                noMargin
                                                {...form.getFieldProps("upfrontFeeAmount")}
                                                onChange={(e) => {
                                                    form.setValue("upfrontFeeType", "amount");
                                                    form.setValue(
                                                        "upfrontFeePercent",
                                                        Number.parseFloat(
                                                            (
                                                                (Number.parseFloat(e.target.value || "0") * 100) /
                                                                clearErcFee
                                                            ).toFixed(2),
                                                        ),
                                                    );
                                                }}
                                            />
                                        </Td>
                                        <Td>
                                            <NumberField
                                                noMargin
                                                {...form.getFieldProps("upfrontFeePercent")}
                                                onChange={(e) => {
                                                    form.setValue("upfrontFeeType", "percentage");
                                                    form.setValue(
                                                        "upfrontFeeAmount",
                                                        Number.parseFloat(
                                                            (
                                                                clearErcFee *
                                                                (Number.parseFloat(e.target.value || "0") / 100)
                                                            ).toFixed(2),
                                                        ),
                                                    );
                                                }}
                                            />
                                        </Td>
                                        <Td>
                                            <DateTimeField
                                                noMargin
                                                {...form.getFieldProps("upfrontFeeDate")}
                                                showTimeSelect={false}
                                                showTimeInput={false}
                                                isClearable
                                            />
                                        </Td>
                                        <Td>
                                            <SelectField
                                                isSearchable={false}
                                                noMargin
                                                {...form.getFieldProps("upfrontFeeType")}
                                                options={[
                                                    { value: "percentage", label: "Percent" },
                                                    { value: "amount", label: "Amount" },
                                                ]}
                                            />
                                        </Td>
                                    </tr>
                                    <tr>
                                        <Td style={{ fontWeight: "bold" }}>Deposit:</Td>
                                        <Td>
                                            <CurrencyField
                                                noMargin
                                                {...form.getFieldProps("depositFeeAmount")}
                                                onChange={(e) => {
                                                    form.setValue("depositFeeType", "amount");
                                                    form.setValue(
                                                        "depositFeePercent",
                                                        Number.parseFloat(
                                                            (
                                                                (Number.parseFloat(e.target.value || "0") * 100) /
                                                                clearErcFee
                                                            ).toFixed(2),
                                                        ),
                                                    );
                                                }}
                                            />
                                        </Td>
                                        <Td>
                                            <NumberField
                                                noMargin
                                                {...form.getFieldProps("depositFeePercent")}
                                                onChange={(e) => {
                                                    form.setValue("depositFeeType", "percentage");
                                                    form.setValue(
                                                        "depositFeeAmount",
                                                        Number.parseFloat(
                                                            (
                                                                clearErcFee *
                                                                (Number.parseFloat(e.target.value || "0") / 100)
                                                            ).toFixed(2),
                                                        ),
                                                    );
                                                }}
                                            />
                                        </Td>
                                        <Td>
                                            <DateTimeField
                                                noMargin
                                                {...form.getFieldProps("depositFeeDate")}
                                                showTimeSelect={false}
                                                showTimeInput={false}
                                                isClearable
                                            />
                                        </Td>
                                        <Td>
                                            <SelectField
                                                isSearchable={false}
                                                noMargin
                                                {...form.getFieldProps("depositFeeType")}
                                                options={[
                                                    { value: "percentage", label: "Percent" },
                                                    { value: "amount", label: "Amount" },
                                                ]}
                                            />
                                        </Td>
                                    </tr>
                                    <tr>
                                        <Td style={{ fontWeight: "bold" }}>Later:</Td>
                                        <Td>
                                            <CurrencyField
                                                noMargin
                                                {...form.getFieldProps("laterFeeAmount")}
                                                onChange={(e) => {
                                                    form.setValue("laterFeeType", "amount");
                                                    form.setValue(
                                                        "laterFeePercent",
                                                        Number.parseFloat(
                                                            (
                                                                (Number.parseFloat(e.target.value || "0") * 100) /
                                                                clearErcFee
                                                            ).toFixed(2),
                                                        ),
                                                    );
                                                }}
                                            />
                                        </Td>
                                        <Td>
                                            <NumberField
                                                noMargin
                                                {...form.getFieldProps("laterFeePercent")}
                                                onChange={(e) => {
                                                    form.setValue("laterFeeType", "percentage");
                                                    form.setValue(
                                                        "laterFeeAmount",
                                                        Number.parseFloat(
                                                            (
                                                                clearErcFee *
                                                                (Number.parseFloat(e.target.value || "0") / 100)
                                                            ).toFixed(2),
                                                        ),
                                                    );
                                                }}
                                            />
                                        </Td>
                                        <Td>
                                            <DateTimeField
                                                noMargin
                                                {...form.getFieldProps("laterFeeDate")}
                                                showTimeSelect={false}
                                                showTimeInput={false}
                                                isClearable
                                            />
                                        </Td>
                                        <Td>
                                            <SelectField
                                                isSearchable={false}
                                                noMargin
                                                {...form.getFieldProps("laterFeeType")}
                                                options={[
                                                    { value: "percentage", label: "Percent" },
                                                    { value: "amount", label: "Amount" },
                                                ]}
                                            />
                                        </Td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="flex justify-end gap-4">
                        <ButtonNeoGen
                            type="outline"
                            disabled={updateUserCompanyMutation.isLoading}
                            onClick={() => onClose()}
                        >
                            Cancel
                        </ButtonNeoGen>
                        <ButtonNeoGen type="submit" disabled={updateUserCompanyMutation.isLoading}>
                            Update commission
                        </ButtonNeoGen>
                    </div>
                </Form>
            </ModalDialog>
        </>
    );
};
