import { useMemo, useState } from "react";
import PageDescription from "../../layout/page-description";
import Loader2 from "../utilities/Loader2";
import { useUsers } from "../../jason-proof-of-concept/users/hooks/use-users";
import { useAuth } from "../../auth/use-auth";
import { useCompanies } from "../../companies/hooks/use-companies";
import { useProcessflowProgresses } from "../../companies/hooks/use-processflow-progresses";
import { getDataFromProgressData } from "../../jason-proof-of-concept/wizard/wizard-container";
import {
    affiliateCommissionMultiplier,
    getEstimatedPayoutFromData,
    getW2Employees,
} from "../../jason-proof-of-concept/other/actions/getEstimatedPayout";
import { useUserCompanies } from "../../companies/hooks/use-user-companies";
import { TBody, THead, Table, Td, Tr } from "../../layout/table";
import OptionsDropDown from "../../layout/options-dropdown";
import { NewCompanyModal } from "../../companies/components/modals/new-company-modal";
import { useNavigate } from "react-router";
import { timeAgo } from "../users/cards/calls-list";

export default function Clients() {
    const auth = useAuth();
    const authToken = auth.expectAuthToken();
    const [showNewClientModal, setShowNewClientModal] = useState(false);
    const navigate = useNavigate();

    const usersQuery = useUsers({ authToken });
    const users = useMemo(() => usersQuery.data || [], [usersQuery.data]);

    const companiesQuery = useCompanies({
        authToken,
        filters: { where: { ownedById: { inq: users.map((u) => u.id) } } },
    });
    const companies = useMemo(() => companiesQuery.data || [], [companiesQuery.data]);

    const processflowProgressesQuery = useProcessflowProgresses({
        authToken,
        filters: { companyIds: companies.map((c) => c.id) },
    });
    const processflowProgresses = useMemo(
        () => processflowProgressesQuery.data || [],
        [processflowProgressesQuery.data],
    );

    const userCompaniesQuery = useUserCompanies({
        authToken,
        filters: {
            where: {
                or: [
                    { ercUserId: { inq: users.map((u) => u.id) } },
                    { companyId: { inq: companies.map((c) => c.id) } },
                ],
            },
        },
    });
    const userCompanies = useMemo(() => userCompaniesQuery.data || [], [userCompaniesQuery.data]);

    const allRows = useMemo(
        () =>
            companies.map((company) => {
                const processflowProgress = processflowProgresses.find(
                    (p) => company.ownedById && company.ownedById === p.userId,
                );
                const data = getDataFromProgressData(processflowProgress?.data || ({} as any));
                const dealValue = processflowProgress?.data ? getEstimatedPayoutFromData({ data }) : 0;
                const estimatedCommission = dealValue * affiliateCommissionMultiplier;
                const status = processflowProgress?.status ?? "";
                const progressPercentage = Math.round((processflowProgress?.data?.percentageComplete || 0) * 100);
                const w2Employees = getW2Employees({ data });
                const owner = users.find((u) => u.id === company.ownedById);
                const row = {
                    id: company.id,
                    processflowProgress,
                    progressPercentage,
                    dealValue,
                    estimatedCommission,
                    company,
                    w2Employees,
                    status,
                    owner,
                };

                return row;
            }),
        [companies, processflowProgresses, users],
    );

    const isLoading = usersQuery.isLoading || companiesQuery.isLoading || processflowProgressesQuery.isLoading;

    return (
        <>
            {showNewClientModal && (
                <NewCompanyModal
                    onClose={() => setShowNewClientModal(false)}
                    roleId={6} // ERC Client
                    onCompanyCreated={({ company }) => {
                        usersQuery.refetch();
                        companiesQuery.refetch();
                        processflowProgressesQuery.refetch();
                        userCompaniesQuery.refetch();
                        navigate(`/companies/${company.id}`);
                    }}
                />
            )}
            {isLoading ? (
                <Loader2 />
            ) : (
                <>
                    <PageDescription
                        title="Clients"
                        description="Clients who have signed up for your services."
                        buttons={[
                            {
                                label: "New Client",
                                icon: "fas fa-plus",
                                onClick: () => {
                                    setShowNewClientModal(true);
                                },
                            },
                        ]}
                    />
                    <Table>
                        <THead>
                            <Tr>
                                <Td>Company</Td>
                                <Td>Owner</Td>
                                <Td>Created</Td>
                                <Td>Deal status</Td>
                                <Td style={{ textAlign: "right" }}></Td>
                            </Tr>
                        </THead>
                        <TBody>
                            {allRows.map((row) => {
                                return (
                                    <Tr key={row.id}>
                                        <Td>{row.company.name}</Td>
                                        <Td>{[row.owner?.firstName, row.owner?.lastName].join(" ")}</Td>
                                        <Td style={{ paddingTop: 0, paddingBottom: 0 }}>
                                            <div className="flex flex-col">
                                                {row.company.createdAt?.toDateString()}
                                                <span style={{ color: "gray" }}>
                                                    {row.company.createdAt
                                                        ? timeAgo(row.company.createdAt).toLocaleString()
                                                        : ""}
                                                </span>
                                            </div>
                                        </Td>
                                        <Td>{row.processflowProgress?.status}</Td>
                                        <Td style={{ paddingTop: 0, paddingBottom: 0 }}>
                                            <div className="flex flex-1 justify-end">
                                                <OptionsDropDown
                                                    options={[{ label: "Edit user", action: () => null }]}
                                                />
                                            </div>
                                        </Td>
                                    </Tr>
                                );
                            })}
                        </TBody>
                    </Table>
                </>
            )}
        </>
    );
}
