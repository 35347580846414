import { FileUploadUtil } from "../../../utilities/file-upload";
import { useContext, useEffect, useState } from "react";
import { useQueryClient } from "@tanstack/react-query";
import Swal from "sweetalert2";
import fileUploadService from "../../../../services/file.service";
import UserContext from "../../../../services/user-context";
import ModalDialog from "../../../../layout/modal-dialog";
import { assumedUserAtom } from "../../../../atoms/userAtom";
import { useRecoilState } from "recoil";
import InputControlled from "../../../../layout/input-controlled";
import { TextAreaInput } from "../../../../layout/form/text-area-input";
import TextAreaNeoGenControlled from "../../../../layout/text-area-controlled";
import industryService, { Industry } from "../../../../services/industry.service";

export default function AddIndustry(props: AddIndustryProps) {
    const [user] = useContext(UserContext);
    const [name, setName] = useState("");
    const [notes, setNotes] = useState("");
    const queryCache = useQueryClient();

    useEffect(() => {
        setTimeout(() => {
            if (props.industry?.id) {
                setName(props.industry.name);
                setNotes(props.industry.notes);
            } else {
                setName("");
                setNotes("");
            }
        }, 1);
    }, [props]);

    async function saveIndustry() {
        if (props.industry?.id) {
            // edit
            await industryService.update(props.industry.id, { name, notes });
        } else {
            // add
            await industryService.create({ name, notes });
        }
        queryCache.invalidateQueries(["industries"]);

        props.close();
    }

    return (
        <ModalDialog
            close={props.close}
            show={props.show}
            title={props.industry ? "Edit Industry" : "Add Industry"}
            okAction={() => {
                saveIndustry();
            }}
            okText={props.industry ? "Save Industry" : "Add Industry"}
        >
            {/* </PrintPre> */}
            <InputControlled
                label="Name"
                value={name}
                onChange={(value) => {
                    setName(value);
                }}
            />
            <TextAreaNeoGenControlled
                label={"Notes"}
                value={notes}
                setValue={function (e: string): void {
                    setNotes(e);
                }}
            />
        </ModalDialog>
    );
}

type AddIndustryProps = {
    show: boolean;
    close: () => void;
    industry?: Industry | null;
};
