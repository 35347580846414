import { useCallback, useEffect, useMemo, useState } from "react";
import Loader2 from "../../sections/utilities/Loader2";
import PageDescription from "../../layout/page-description";
import { search as ssSearch } from "ss-search";
import { debounce, startCase } from "lodash";
import { getAuthTokenNoThrow } from "../../services/auth-header";
import { useUsers } from "../../jason-proof-of-concept/users/hooks/use-users";
import { useNavigate } from "react-router";
import { useRoleGroups } from "../../role-groups/hooks/use-role-groups";
import { TBody, THead, Table, Td, Th, Tr } from "../../layout/table";
import { timeAgo } from "../../sections/users/cards/calls-list";
import { useBreakpoint } from "../../hooks/appMedia";
import { usePaymentRequests } from "../hooks/use-payment-requests";
import { PaymentRequest, PaymentRequestStatus } from "../domain/payment-request";
import { Currency } from "../../billing/components";
import OptionsDropDown from "../../layout/options-dropdown";
import SwalNeogenFire from "../../layout/swal-neogen";
import { getDarkMode } from "../../sections/admin/magic-links/magic-links";
import { CreatePaymentModal } from "../../companies/components/modals/create-payment-modal";
import { useUpdatePaymentRequest } from "../hooks/use-update-payment-request";
import { Select } from "../../layout/form/select-input";
import SearchField from "../../layout/search-field";
import { Link } from "react-router-dom";
import { Payment } from "../../payments/domain/payment";
import Toggle from "../../layout/toggle";

export function PaymentRequestsPage({ darkMode }: { darkMode?: boolean }) {
    const [search, setSearch] = useState("");
    const [debouncedSearch, setDebouncedSearch] = useState("");
    const authToken = getAuthTokenNoThrow() || "no-auth";
    const [status, setStatus] = useState<PaymentRequestStatus | null>(PaymentRequestStatus.Pending);
    const navigate = useNavigate();
    const [showCapturePaymentModal, setShowCapturePaymentModal] = useState<
        (Partial<Payment> & { companyId: number }) | undefined
    >();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const updateDebouncedSearch = useCallback(
        debounce(
            (term) => {
                setDebouncedSearch(term);
            },
            700,
            { trailing: true, maxWait: 1000 },
        ),
        [],
    );
    useEffect(() => {
        updateDebouncedSearch(search);
    }, [search, updateDebouncedSearch]);

    const paymentRequestsQuery = usePaymentRequests({
        authToken,
        filters: { ...(status !== null ? { where: { status: status } } : {}) },
    });
    const paymentRequests = useMemo(() => paymentRequestsQuery.data || [], [paymentRequestsQuery.data]);
    const filteredPaymentRequests = useMemo(
        () =>
            debouncedSearch
                ? (ssSearch(
                      paymentRequests,
                      [
                          "from",
                          "fromUser.firstName",
                          "fromUser.lastName",
                          "fromUser.email",
                          "to",
                          "toUser.firstName",
                          "toUser.lastName",
                          "toUser.email",
                          "company.name",
                          "description",
                      ],
                      debouncedSearch,
                  ) as PaymentRequest[])
                : paymentRequests,
        [paymentRequests, debouncedSearch],
    );

    const filteredPaymentRequestsToClearErc = useMemo(
        () => filteredPaymentRequests.filter((pr) => pr.toClearErc === true),
        [filteredPaymentRequests],
    );
    const filteredPaymentRequestsFromClearErc = useMemo(
        () =>
            filteredPaymentRequests.filter((pr) => pr.toClearErc === false || pr.fromClearErc === true || pr.toUserId),
        [filteredPaymentRequests],
    );

    const usersQuery = useUsers({ authToken });
    const users = useMemo(() => usersQuery.data || [], [usersQuery.data]);
    const [searchOpen, setSearchOpen] = useState(false);
    const roleGroupsQuery = useRoleGroups({ authToken });
    const roleGroups = useMemo(() => roleGroupsQuery.data || [], [roleGroupsQuery.data]);
    const breakpoints = useBreakpoint();
    const isMobile = breakpoints.breakpoint === "mobile";
    const isTablet = breakpoints.breakpoint === "tablet";
    const [isToClearErc, setIsToClearErc] = useState(0);

    const updatePaymentRequestMutation = useUpdatePaymentRequest();

    return (
        <>
            {showCapturePaymentModal && (
                <CreatePaymentModal
                    companyId={showCapturePaymentModal.companyId}
                    defaultValues={showCapturePaymentModal}
                    onClose={() => {
                        setShowCapturePaymentModal(undefined);
                    }}
                    onPaymentCaptured={() => {
                        setShowCapturePaymentModal(undefined);
                        paymentRequestsQuery.refetch();
                    }}
                />
            )}
            <PageDescription title="Payments due">
                <div className="flex flex-col items-end justify-end gap-4">
                    <div className="flex flex-row gap-4 items-center">
                        <div className="w-[190px] text-left">
                            Status
                            <Select
                                isClearable
                                value={status}
                                onChange={(v) => setStatus(v === "all" ? null : (v as any))}
                                options={[
                                    { label: "Pending", value: PaymentRequestStatus.Pending },
                                    { label: "Complete", value: PaymentRequestStatus.Complete },
                                    { label: "Cancelled", value: PaymentRequestStatus.Cancelled },
                                    { label: "All", value: "all" },
                                ]}
                            />
                        </div>
                        <div className="text-left">
                            Search
                            <SearchField search={search} setSearch={setSearch} placeholder="Search payments due..." />
                        </div>
                    </div>
                    <div className="w-full">
                        <Toggle
                            options={["To ClearERC", "From ClearERC"]}
                            setSelectedIndex={setIsToClearErc}
                            selectedIndex={isToClearErc}
                        />
                    </div>
                </div>
            </PageDescription>
            {!isToClearErc ? (
                <div>
                    {paymentRequestsQuery.isLoading ? (
                        <div className={"p-5 text-center"}>
                            <Loader2 />
                        </div>
                    ) : (
                        <>
                            <Table>
                                <THead>
                                    <Tr>
                                        <Th>Description</Th>
                                        <Th>Status</Th>
                                        <Th>Requested payment date</Th>
                                        <Th>Company</Th>
                                        <Th>From</Th>
                                        <Th>To</Th>
                                        <Th>Amount</Th>
                                        <Th />
                                    </Tr>
                                </THead>
                                <TBody>
                                    {filteredPaymentRequestsToClearErc.length === 0 && (
                                        <Tr>
                                            <Td colSpan={8} style={{ textAlign: "center" }}>
                                                No payments due to ClearErc
                                            </Td>
                                        </Tr>
                                    )}
                                    {filteredPaymentRequestsToClearErc.length > 0 &&
                                        filteredPaymentRequestsToClearErc.map((pr) => {
                                            return (
                                                <Tr key={pr.id}>
                                                    <Td>{pr.description || "-"}</Td>
                                                    <Td>
                                                        <div className="flex flex-col">
                                                            {pr.status ? startCase(pr.status) : "-"}
                                                            {pr.status === PaymentRequestStatus.Complete && (
                                                                <span style={{ color: "gray" }}>
                                                                    {pr.paymentDate?.toDateString()}
                                                                </span>
                                                            )}
                                                        </div>
                                                    </Td>
                                                    <Td style={{ paddingTop: 0, paddingBottom: 0 }}>
                                                        <div className="flex flex-col">
                                                            {pr.requestedPaymentDate?.toDateString()}
                                                            <div style={{ color: "gray" }}>
                                                                {pr.requestedPaymentDate
                                                                    ? timeAgo(pr.requestedPaymentDate).toLocaleString()
                                                                    : null}
                                                            </div>
                                                        </div>
                                                    </Td>
                                                    <Td>
                                                        {pr.company && (
                                                            <Link to={`/companies/${pr.companyId}/`}>
                                                                {pr.company.name}
                                                            </Link>
                                                        )}
                                                    </Td>
                                                    <Td>
                                                        {pr.fromClearErc
                                                            ? "ClearERC"
                                                            : pr.fromUser
                                                            ? [pr.fromUser.firstName, pr.fromUser.lastName].join(" ")
                                                            : pr.from || pr.fromCompany?.name}
                                                    </Td>
                                                    <Td>
                                                        {pr.toClearErc
                                                            ? "ClearERC"
                                                            : pr.toUser
                                                            ? [pr.toUser.firstName, pr.toUser.lastName].join(" ")
                                                            : pr.to || pr.toCompany?.name}
                                                    </Td>
                                                    <Td>
                                                        <Currency amount={pr.amount} />
                                                    </Td>
                                                    <Td>
                                                        <OptionsDropDown
                                                            options={[
                                                                {
                                                                    label: "Create payment",
                                                                    disabled:
                                                                        pr.status !== PaymentRequestStatus.Pending,
                                                                    onClick: () => {
                                                                        setShowCapturePaymentModal({
                                                                            companyId: pr.companyId,
                                                                            description: `Reversal: ${pr.description}`,
                                                                            amount: pr.amount,
                                                                            from: pr.to || undefined,
                                                                            fromClearErc: pr.toClearErc || undefined,
                                                                            fromCompanyId: pr.toCompanyId || undefined,
                                                                            fromCompany: pr.toCompany || undefined,
                                                                            fromUserId: pr.toUserId || undefined,
                                                                            to: pr.from || undefined,
                                                                            toClearErc: pr.fromClearErc || undefined,
                                                                            toCompanyId: pr.fromCompanyId || undefined,
                                                                            toCompany: pr.fromCompany || undefined,
                                                                            toUserId: pr.fromUserId || undefined,
                                                                            paymentRequestId: pr.id,
                                                                        });
                                                                    },
                                                                },
                                                                {
                                                                    label: "Cancel",
                                                                    disabled:
                                                                        pr.status !== PaymentRequestStatus.Pending,
                                                                    onClick: () => {
                                                                        SwalNeogenFire({
                                                                            darkMode: getDarkMode(),
                                                                            title: "Cancel payment request?",
                                                                            text: "Are you sure you want to cancel this payment request?",
                                                                            icon: "info",
                                                                            showCancelButton: true,
                                                                            confirmButtonText: "Yes",
                                                                            cancelButtonText: "No",
                                                                            showLoaderOnConfirm: true,
                                                                        }).then(async (result) => {
                                                                            if (result.isConfirmed) {
                                                                                await updatePaymentRequestMutation.mutateAsync(
                                                                                    {
                                                                                        authToken,
                                                                                        id: pr.id,
                                                                                        data: {
                                                                                            status: PaymentRequestStatus.Cancelled,
                                                                                        },
                                                                                    },
                                                                                );
                                                                                paymentRequestsQuery.refetch();
                                                                            }
                                                                        });
                                                                    },
                                                                },
                                                            ]}
                                                        />
                                                    </Td>
                                                </Tr>
                                            );
                                        })}
                                </TBody>
                            </Table>
                        </>
                    )}
                </div>
            ) : (
                <>
                    <Table>
                        <THead>
                            <Tr>
                                <Th>Description</Th>
                                <Th>Status</Th>
                                <Th>Requested payment date</Th>
                                <Th>Company</Th>
                                <Th>From</Th>
                                <Th>To</Th>
                                <Th>Amount</Th>
                                <Th />
                            </Tr>
                        </THead>
                        <TBody>
                            {filteredPaymentRequestsFromClearErc.length === 0 && (
                                <Tr>
                                    <Td colSpan={8} style={{ textAlign: "center" }}>
                                        No payments due from ClearErc
                                    </Td>
                                </Tr>
                            )}
                            {filteredPaymentRequestsFromClearErc.length > 0 &&
                                filteredPaymentRequestsFromClearErc.map((pr) => {
                                    return (
                                        <Tr key={pr.id}>
                                            <Td>{pr.description || "-"}</Td>
                                            <Td>
                                                <div className="flex flex-col">
                                                    {pr.status ? startCase(pr.status) : "-"}
                                                    {pr.status === PaymentRequestStatus.Complete && (
                                                        <span style={{ color: "gray" }}>
                                                            {pr.paymentDate?.toDateString()}
                                                        </span>
                                                    )}
                                                </div>
                                            </Td>
                                            <Td style={{ paddingTop: 0, paddingBottom: 0 }}>
                                                <div className="flex flex-col">
                                                    {pr.requestedPaymentDate?.toDateString()}
                                                    <div style={{ color: "gray" }}>
                                                        {pr.requestedPaymentDate
                                                            ? timeAgo(pr.requestedPaymentDate).toLocaleString()
                                                            : null}
                                                    </div>
                                                </div>
                                            </Td>
                                            <Td>
                                                {pr.company && (
                                                    <Link to={`/companies/${pr.companyId}/`}>{pr.company.name}</Link>
                                                )}
                                            </Td>
                                            <Td>
                                                {pr.fromClearErc
                                                    ? "ClearERC"
                                                    : pr.fromUser
                                                    ? [pr.fromUser.firstName, pr.fromUser.lastName].join(" ")
                                                    : pr.from || pr.fromCompany?.name}
                                            </Td>
                                            <Td>
                                                {pr.toClearErc
                                                    ? "ClearERC"
                                                    : pr.toUser
                                                    ? [pr.toUser.firstName, pr.toUser.lastName].join(" ")
                                                    : pr.to || pr.toCompany?.name}
                                            </Td>
                                            <Td>
                                                <Currency amount={pr.amount} />
                                            </Td>
                                            <Td>
                                                <OptionsDropDown
                                                    options={[
                                                        {
                                                            label: "Create payment",
                                                            disabled: pr.status !== PaymentRequestStatus.Pending,
                                                            onClick: () => {
                                                                setShowCapturePaymentModal({
                                                                    companyId: pr.companyId,
                                                                    description: `Reversal: ${pr.description}`,
                                                                    amount: pr.amount,
                                                                    from: pr.to || undefined,
                                                                    fromClearErc: pr.toClearErc || undefined,
                                                                    fromCompanyId: pr.toCompanyId || undefined,
                                                                    fromCompany: pr.toCompany || undefined,
                                                                    fromUserId: pr.toUserId || undefined,
                                                                    to: pr.from || undefined,
                                                                    toClearErc: pr.fromClearErc || undefined,
                                                                    toCompanyId: pr.fromCompanyId || undefined,
                                                                    toCompany: pr.fromCompany || undefined,
                                                                    toUserId: pr.fromUserId || undefined,
                                                                    paymentRequestId: pr.id,
                                                                });
                                                            },
                                                        },
                                                        {
                                                            label: "Cancel",
                                                            disabled: pr.status !== PaymentRequestStatus.Pending,
                                                            onClick: () => {
                                                                SwalNeogenFire({
                                                                    darkMode: getDarkMode(),
                                                                    title: "Cancel payment request?",
                                                                    text: "Are you sure you want to cancel this payment request?",
                                                                    icon: "info",
                                                                    showCancelButton: true,
                                                                    confirmButtonText: "Yes",
                                                                    cancelButtonText: "No",
                                                                    showLoaderOnConfirm: true,
                                                                }).then(async (result) => {
                                                                    if (result.isConfirmed) {
                                                                        await updatePaymentRequestMutation.mutateAsync({
                                                                            authToken,
                                                                            id: pr.id,
                                                                            data: {
                                                                                status: PaymentRequestStatus.Cancelled,
                                                                            },
                                                                        });
                                                                        paymentRequestsQuery.refetch();
                                                                    }
                                                                });
                                                            },
                                                        },
                                                    ]}
                                                />
                                            </Td>
                                        </Tr>
                                    );
                                })}
                        </TBody>
                    </Table>
                </>
            )}
        </>
    );
}
