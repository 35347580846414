import { MultiTenant } from "../typings/api/multitenant";
import APIService from "./api.service";

class MultiTenantService extends APIService<MultiTenant> {
    constructor() {
        super("/multitenants");
    }
    getAll() {
        return super.getAll();
    }
}

export default new MultiTenantService();
