import { useQuery } from "@tanstack/react-query";
import { formatRelative, subDays, subMinutes } from "date-fns";
import { useNavigate, useParams } from "react-router";
import { Link } from "react-router-dom";
import { useRecoilState } from "recoil";
import userAtom from "../atoms/userAtom";
import { Currency } from "../billing/components";
import { getEstimatedPayoutFromData } from "../jason-proof-of-concept/other/actions/getEstimatedPayout";
import { getProcessflowProgresses } from "../jason-proof-of-concept/processflow-progresses/actions/get-processflow-progresses";
import { getUser } from "../jason-proof-of-concept/users/actions/get-user";
import { User } from "../jason-proof-of-concept/users/domain/user";
import { getDataFromProgressData } from "../jason-proof-of-concept/wizard/wizard-container";
import BreadCrumbs from "../layout/breadcrumbs";
import PageDescription from "../layout/page-description";
import { getAuthTokenNoThrow } from "../services/auth-header";
import { Section, Stat } from "./affiliate-billing-page";

type Stage = {
    title: string;
    status: "complete" | "inProgress" | "pending";
    completedAt?: Date;
    startedAt?: Date;
    subStages?: Stage[];
    isSubstage?: boolean;
};

const now = new Date();

const Stage = ({ stage }: { stage: Stage }) => {
    return (
        <div className="flex flex-row" key={stage.title}>
            <div style={{ width: 30, position: "relative" }}>
                {stage.status === "complete" ? (
                    <span className={"mt-3 mr-3 text-green-400 fa fa-check fa-lg"}></span>
                ) : stage.status === "inProgress" ? (
                    <span className={"mt-3 mr-3 fa-sharp fa-regular fa fa-ellipsis fa-lg"}></span>
                ) : stage.status === "pending" ? (
                    <div className={"mr-3 text-center"}>-</div>
                ) : (
                    <span>-</span>
                )}
            </div>
            <div className="flex flex-col">
                <div className={stage.isSubstage ? "text-md" : `text-lg`}>{stage.title}</div>
                {stage.completedAt && (
                    <div className="text-sm text-gray-400">Completed {formatRelative(stage.completedAt, now)}</div>
                )}
                {stage.startedAt && (
                    <div className="text-sm text-gray-400">Started {formatRelative(stage.startedAt, now)}</div>
                )}
                {stage.subStages !== undefined && (
                    <div className="flex flex-col gap-2 mt-2">
                        {(stage.subStages || []).map((subStage) => {
                            return <Stage key={subStage.title} stage={{ ...subStage, isSubstage: true }} />;
                        })}
                    </div>
                )}
            </div>
        </div>
    );
};

const Stages = ({ stages }: { stages: Stage[] }) => {
    return (
        <div className="flex flex-col gap-4">
            {stages.map((stage) => {
                return <Stage key={stage.title} stage={stage} />;
            })}
        </div>
    );
};

type Event = {
    title: string;
    date: Date;
};

const Event = ({ event: event }: { event: Event }) => {
    return (
        <div className="flex flex-row" key={event.title}>
            <div style={{ width: 30, position: "relative" }}>
                <div className={"mr-3 text-center"}>-</div>
            </div>
            <div className="flex flex-col">
                <div className={"text-md"}>{event.title}</div>
                {event.date && <div className="text-sm text-gray-400">{formatRelative(event.date, now)}</div>}
            </div>
        </div>
    );
};

const Events = ({ events: events }: { events: Event[] }) => {
    return (
        <div className="flex flex-col gap-4">
            {events.map((event) => {
                return <Event key={event.title} event={event} />;
            })}
        </div>
    );
};

const ClientDetails = ({ user }: { user: User }) => {
    const name = [user?.firstName, user?.lastName].join(" ").trim() || user?.email;

    return (
        <div>
            <div className="flex flex-row justify-between">
                <div className="flex-1">
                    <h1>{name}</h1>
                </div>
                <div className="flex-1">
                    <h1>Company: {user?.name}</h1>
                </div>
            </div>
            <div className="flex flex-row justify-between">
                <div className="flex-1">
                    <div>{user?.phone ? user.phone : <span style={{ fontStyle: "italic" }}>No phone number</span>}</div>
                    <div>
                        {user?.email ? (
                            <a href={`mailto:${user.email}`}>{user.email}</a>
                        ) : (
                            <span style={{ fontStyle: "italic" }}>No email</span>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

const FeesAndCommissions = () => {
    return (
        <div className="flex flex-col gap-5">
            <div className="flex flex-row justify-between gap-1">
                <div className="flex-1">
                    <div>
                        <div className="text-sm text-gray-600">Client fee structure</div>
                        <div className="text-xl">20% fee, 5% deposit</div>
                        <div className="text-sm text-gray-400">20% is $17,000, 5% is $3,000</div>
                    </div>
                </div>
                <div className="flex-1">
                    <div>
                        <div className="text-sm text-gray-600">Client payout finance</div>
                        <div className="text-xl">Not financed</div>
                        <div className="text-sm text-gray-400">The client&apos;s payout is not financed</div>
                    </div>
                </div>
            </div>
            <div>
                <div className="text-sm text-gray-600">Your affiliate payout structure</div>
                <div className="flex flex-col gap-2">
                    <div className="flex flex-row">
                        <div style={{ width: 30, position: "relative" }}>
                            <div className={"mr-3 text-center"}>-</div>
                        </div>
                        <div className="flex flex-col">
                            <div className={"text-lg"}>$1,500 deposit</div>
                            <div className="text-sm text-gray-400">You get 50% of the client&apos;s $3,000 deposit</div>
                        </div>
                    </div>
                    <div className="flex flex-row">
                        <div style={{ width: 30, position: "relative" }}>
                            <div className={"mr-3 text-center"}>-</div>
                        </div>
                        <div className="flex flex-col">
                            <div className={"text-lg"}>4x$4,000 quarterly payouts</div>
                            <div className="text-sm text-gray-400">
                                You get 25% of the 20% ClearERC fee, payed out on the IRS quarterly payout schedule
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div className="flex flex-row">
                        <div style={{ width: 30, position: "relative" }}>
                            <div className={"mr-3 text-center"}>-</div>
                        </div>
                        <div className="flex flex-col">
                            <div className={"text-lg"}>Total commission: $17,500</div>
                            <div className="text-sm text-gray-400">
                                Your total commission including deposit and recurring payouts
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export const CustomerDealPage = () => {
    const [user] = useRecoilState(userAtom);
    const authedUserId = user.id;
    const authToken = getAuthTokenNoThrow() || "no-auth-token";
    const navigate = useNavigate();
    const { userId: dealUserId } = useParams();

    const query = useQuery(["my-deal-data", { authToken, userId: authedUserId, dealUserId }], async () => {
        const dealUser = dealUserId ? await getUser({ authToken, id: dealUserId }) : undefined;
        const [processflowProgress] = dealUserId
            ? await getProcessflowProgresses({
                  authToken,
                  filters: { userId: dealUserId, group: 7 },
              })
            : [];
        return { dealUser, processflowProgress };
    });
    const dealUser = query.data?.dealUser;
    console.log("dealUser", dealUser);

    const name = [dealUser?.firstName, dealUser?.lastName].join(" ").trim() || dealUser?.email;
    const processflowProgress = query.data?.processflowProgress;
    const progressPercentage = Math.round((processflowProgress?.data?.percentageComplete || 0) * 100);
    const dealValue = processflowProgress?.data
        ? getEstimatedPayoutFromData({
              data: getDataFromProgressData(processflowProgress.data as any),
          })
        : 0;
    const estimatedCommission = dealValue * 0.15;

    const progressStages: Stage[] = [
        {
            title: "Signed up",
            completedAt: subDays(now, 4),
            status: "complete",
        },
        {
            title: "Application stages",
            startedAt: subMinutes(now, 10),
            status: "inProgress",
            subStages: [
                {
                    title: "Get Qualified",
                    status: "complete",
                },
                {
                    title: "Business and Payroll",
                    status: "complete",
                },
                {
                    title: "Document Upload",
                    startedAt: subMinutes(now, 5),
                    status: "inProgress",
                },
            ],
        },
        {
            title: "ClearERC Review",
            status: "pending",
        },
        {
            title: "ClearERC Options and Agreement",
            status: "pending",
        },
    ];

    const events: Event[] = [
        {
            title: "2 documents uploaded",
            date: subMinutes(now, 1),
        },
        {
            title: "Email sent: Reminder to upload docs",
            date: subMinutes(now, 17),
        },
        {
            title: "Completed 'Business and Payroll' stage",
            date: subMinutes(now, 23),
        },
        {
            title: "Completed 'Get Qualified' stage",
            date: subMinutes(now, 38),
        },
    ];

    return (
        <div>
            <div className="flex flex-row justify-between">
                <BreadCrumbs
                    pages={[
                        {
                            name: "Deals",
                            href: "/deals",
                        },
                        {
                            name: `${name}`,
                            href: `/deals/${dealUserId || ""}`,
                            current: true,
                        },
                    ]}
                />
                <Link to={`/users/${dealUserId}/application#`}>Open application</Link>
            </div>
            <Section style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                <Stat title="Deal value" value={<Currency amount={dealValue} />} />
                <Stat title="Estimated commission" value={<Currency amount={estimatedCommission} />} />
                <Stat title="Application status" value={`In progress (${progressPercentage}%)`} />
                <Stat title="Application created" value={new Date().toDateString()} />
                <Stat title="User last active" value={new Date().toDateString()} />
            </Section>
            <div className="flex flex-row gap-8 mt-4">
                <div className="flex flex-col flex-1 gap-5">
                    <div>
                        <h2 className="mb-2">Application progress</h2>
                        <Section>
                            <Stages stages={progressStages} />
                        </Section>
                    </div>
                    <div>
                        <h2 className="mb-2">Fees and Commissions</h2>
                        <Section>
                            <FeesAndCommissions />
                        </Section>
                    </div>
                </div>
                <div className="flex flex-col flex-1 gap-5">
                    <div>
                        <h2 className="mb-2">Client details</h2>
                        <Section>{dealUser && <ClientDetails user={dealUser} />}</Section>
                    </div>
                    <div>
                        <h2 className="mb-2">Recent Activity</h2>
                        <Section>
                            <Events events={events} />
                        </Section>
                    </div>
                </div>
            </div>
        </div>
    );
};
