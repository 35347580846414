import { useState } from "react";
import ModalDialog from "../../../layout/modal-dialog";
import SwitchNeoGen from "../../../layout/switch-neogen";
import { useMutation } from "@tanstack/react-query";
import { Company } from "../../domain/company";
import { UserCompany } from "../../../user-companies/domain/user-company";
import { PaymentRequest, PaymentRequestStatus } from "../../../payment-requests/domain/payment-request";
import { createPaymentRequest } from "../../../payment-requests/actions/create-payment-request";
import { useAuth } from "../../../auth/use-auth";
import { FormModalButtons } from "../../../layout/form/form";
import ButtonNeoGen from "../../../layout/button-neogen";
import { updatePaymentRequest } from "../../../payment-requests/actions/update-payment-request";
export const GeneratePayoutsModal = ({
    onClose,
    company,
    userCompanies,
    paymentRequests,
    onPayoutsGenerated,
}: {
    onClose: () => void;
    company: Company;
    userCompanies: UserCompany[];
    paymentRequests: PaymentRequest[];
    onPayoutsGenerated: () => void;
}) => {
    const [clear, setClear] = useState(false);
    const auth = useAuth();
    const authToken = auth.expectAuthToken();

    const mutation = useMutation({
        mutationFn: async () => {
            if (clear) {
                await Promise.all(
                    paymentRequests
                        .filter((pr) => pr.status === PaymentRequestStatus.Pending)
                        .map(async (pr) => {
                            await updatePaymentRequest({
                                authToken,
                                id: pr.id,
                                data: { status: PaymentRequestStatus.Cancelled },
                            });
                        }),
                );
            }

            if (typeof company.upfrontFeeAmount === "number" && company.upfrontFeeAmount > 0) {
                await createPaymentRequest({
                    authToken,
                    data: {
                        description: `Company Upfront Fee`,
                        fromCompanyId: company.id,
                        toClearErc: true,
                        amount: company.upfrontFeeAmount,
                        status: PaymentRequestStatus.Pending,
                        companyId: company.id,
                        requestedPaymentDate: company.upfrontFeeDate,
                    },
                });
            }

            if (typeof company.depositFeeAmount === "number" && company.depositFeeAmount > 0) {
                await createPaymentRequest({
                    authToken,
                    data: {
                        description: `Company Deposit Fee`,
                        fromCompanyId: company.id,
                        toClearErc: true,
                        amount: company.depositFeeAmount,
                        status: PaymentRequestStatus.Pending,
                        companyId: company.id,
                        requestedPaymentDate: company.depositFeeDate,
                    },
                });
            }

            if (typeof company.laterFeeAmount === "number" && company.laterFeeAmount > 0) {
                await createPaymentRequest({
                    authToken,
                    data: {
                        description: `Company Later Fee`,
                        fromCompanyId: company.id,
                        toClearErc: true,
                        amount: company.laterFeeAmount,
                        status: PaymentRequestStatus.Pending,
                        companyId: company.id,
                        requestedPaymentDate: company.laterFeeDate,
                    },
                });
            }

            await Promise.all(
                userCompanies.map(async (uc) => {
                    if (typeof uc.upfrontFeeAmount === "number" && uc.upfrontFeeAmount > 0) {
                        await createPaymentRequest({
                            authToken,
                            data: {
                                description: `User Upfront Commission`,
                                fromClearErc: true,
                                toUserId: uc.ercUserId,
                                amount: uc.upfrontFeeAmount,
                                status: PaymentRequestStatus.Pending,
                                companyId: company.id,
                                requestedPaymentDate: uc.upfrontFeeDate,
                            },
                        });
                    }
                    if (typeof uc.depositFeeAmount === "number" && uc.depositFeeAmount > 0) {
                        await createPaymentRequest({
                            authToken,
                            data: {
                                description: `User Deposit Commission`,
                                fromClearErc: true,
                                toUserId: uc.ercUserId,
                                amount: uc.depositFeeAmount,
                                status: PaymentRequestStatus.Pending,
                                companyId: company.id,
                                requestedPaymentDate: uc.depositFeeDate,
                            },
                        });
                    }
                    if (typeof uc.depositFeeAmount === "number" && uc.depositFeeAmount > 0) {
                        await createPaymentRequest({
                            authToken,
                            data: {
                                description: `User Deposit Commission`,
                                fromClearErc: true,
                                toUserId: uc.ercUserId,
                                amount: uc.depositFeeAmount,
                                status: PaymentRequestStatus.Pending,
                                companyId: company.id,
                                requestedPaymentDate: uc.depositFeeDate,
                            },
                        });
                    }
                    if (typeof uc.laterFeeAmount === "number" && uc.laterFeeAmount > 0) {
                        await createPaymentRequest({
                            authToken,
                            data: {
                                description: `User Later Commission`,
                                fromClearErc: true,
                                toUserId: uc.ercUserId,
                                amount: uc.laterFeeAmount,
                                status: PaymentRequestStatus.Pending,
                                companyId: company.id,
                                requestedPaymentDate: uc.laterFeeDate,
                            },
                        });
                    }
                }),
            );
        },
    });

    const handleClick = async () => {
        await mutation.mutateAsync();
        onPayoutsGenerated();
    };

    return (
        <ModalDialog isLoading show title="Generate Payouts" close={onClose} showCancel={false} showOk={false}>
            <p className="mb-5">Are you sure you want to generate payouts for all fees and commissions?</p>
            <p>Please make sure you have done the following:</p>
            <ul>
                <li> - Set the correct fee amounts & dates</li>
                <li> - Set the correct user commissions and dates</li>
            </ul>

            <div className="flex flex-col mt-5">
                <div className="flex flex-row gap-3 align-middle justify-between">
                    Reset and regenerate existing payouts?
                    <SwitchNeoGen enabled={clear} setEnabled={(e) => setClear(e)} />
                </div>
            </div>
            <div className="mb-10" />
            <div className="flex justify-end">
                <ButtonNeoGen onClick={handleClick} disabled={mutation.isLoading}>
                    Generate Payouts
                </ButtonNeoGen>
            </div>
        </ModalDialog>
    );
};
