import { useQuery } from "@tanstack/react-query";
import { getUserCompanies } from "../../user-companies/actions/get-user-companies";

type Params = {
    authToken: string;
    filters?: any;
};

const getUserCompaniesQueryKey = ({ authToken, filters }: Params) => {
    return ["user-companies", { authToken, filters }];
};

export const useUserCompanies = ({ authToken, filters }: Params) => {
    const query = useQuery({
        queryKey: getUserCompaniesQueryKey({ authToken, filters }),
        queryFn: async () => {
            const userCompanies = await getUserCompanies({ authToken, filters });
            return userCompanies;
        },
    });
    return query;
};
