import { FileUploadUtil } from "../../../utilities/file-upload";
import { useContext, useEffect, useState } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import Swal from "sweetalert2";
import fileUploadService from "../../../../services/file.service";
import UserContext from "../../../../services/user-context";
import ModalDialog from "../../../../layout/modal-dialog";
import { assumedUserAtom } from "../../../../atoms/userAtom";
import { useRecoilState } from "recoil";
import InputControlled from "../../../../layout/input-controlled";
import { TextAreaInput } from "../../../../layout/form/text-area-input";
import TextAreaNeoGenControlled from "../../../../layout/text-area-controlled";
import industryService from "../../../../services/industry.service";
import industryGroupsService, { IndustryGroup } from "../../../../services/industry-groups.service";
import { Select } from "../../../../layout/form/select-input";
import industryGroupMembersService from "../../../../services/industry-group-members.service";
import users from "../../users/users";
import _ from "lodash";

export default function AddIndustryGroup(props: AddIndustryGroupProps) {
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [members, setMembers] = useState<number[]>([]);
    const queryCache = useQueryClient();

    const industryGroupMembersQuery = useQuery(["industryGroupMembers"], async () => {
        const result = await industryGroupMembersService.getAll();
        return result?.data ?? [];
    });
    useEffect(() => {
        setTimeout(() => {
            if (props.industryGroup?.id) {
                setName(props.industryGroup.name);
                setDescription(props.industryGroup.description);
                setMembers(
                    industryGroupMembersQuery.data
                        ?.filter((i) => i.industryGroup === props.industryGroup?.id)
                        .map((i) => i.industry) ?? [],
                );
            } else {
                setName("");
                setDescription("");
            }
        }, 1);
    }, [industryGroupMembersQuery.data, props]);

    const industriesQuery = useQuery(["industries"], async () => {
        const result = await industryService.getAll();
        return result?.data ?? [];
    });

    async function saveIndustryGroup() {
        // The entry has rule groups because they are included in the query
        // They actually come from the ProcessflowRuleGroups table
        const dbRules =
            industryGroupMembersQuery.data?.filter((i) => i.industryGroup === props.industryGroup?.id) ?? [];
        // const same = _.isEqual(dbRules[0], enabled);

        // entriets.
        const removals = _.differenceWith(dbRules, members, _.isEqual);
        const additions = _.differenceWith(members, dbRules, _.isEqual);
        console.log({ removals, additions });
        // Find the industryGroupMembers that are in the db but not in the enabled list
        // and delete them
        const entriesToDelete =
            industryGroupMembersQuery.data?.filter((i) => removals.find((r) => r.id === i.id)) ?? [];
        // Find the industryGroupMembers that are in the enabled list but not in the db
        // and add them
        const entriesToAdd = members.filter((i) => additions.find((a) => a === i)) ?? [];
        for (const entry of entriesToAdd) {
            await industryGroupMembersService.create({ industryGroup: props.industryGroup?.id, industry: entry });
        }
        for (const entry of entriesToDelete) {
            await industryGroupMembersService.deleteByID(entry.id ?? -1);
        }
        if (props.industryGroup?.id) {
            // edit
            await industryGroupsService.update(props.industryGroup.id, { name, description });
        } else {
            // add
            await industryGroupsService.create({ name, description });
        }
        queryCache.invalidateQueries(["industryGroups"]);
        queryCache.invalidateQueries(["industryGroupMembers"]);

        props.close();
    }

    return (
        <ModalDialog
            close={props.close}
            show={props.show}
            title={props.industryGroup ? "Edit Industry Group" : "Add Industry Group"}
            okAction={() => {
                saveIndustryGroup();
            }}
            okText={props.industryGroup ? "Save Industry Group" : "Add Industry Group"}
        >
            {/* </PrintPre> */}
            <InputControlled
                label="Name"
                value={name}
                onChange={(value) => {
                    setName(value);
                }}
            />
            <TextAreaNeoGenControlled
                label={"Description"}
                value={description}
                setValue={function (e: string): void {
                    setDescription(e);
                }}
            />
            <div className="mb-5">
                <Select
                    value={members}
                    options={
                        industriesQuery.data?.map((i) => ({
                            value: i.id ?? -1,
                            label: i.name ?? "",
                        })) ?? []
                    }
                    isMultiple={true}
                    label="Group Members"
                    onChange={(value) => {
                        if (Array.isArray(value)) {
                            setMembers(value.map((i) => Number(i)) ?? []);
                        } else {
                            setMembers([Number(value)] ?? []);
                        }
                        // setSelectedUser(value as string);
                        // refetch();
                    }}
                />
            </div>
        </ModalDialog>
    );
}

type AddIndustryGroupProps = {
    show: boolean;
    close: () => void;
    industryGroup?: IndustryGroup | null;
};
