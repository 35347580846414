import { useQuery } from "@tanstack/react-query";
import { getMondaySyncData } from "../../jason-proof-of-concept/monday-sync-data/actions/get-monday-sync-data";

type Params = {
    authToken: string;
    filters?: any;
};

const getMondaySyncDataQueryKey = ({ authToken, filters }: Params) => {
    return ["monday-data-sync", { authToken, filters }];
};

export const useMondaySyncData = ({ authToken, filters }: Params) => {
    const query = useQuery({
        queryKey: getMondaySyncDataQueryKey({ authToken, filters }),
        queryFn: async () => {
            const mondaySyncData = await getMondaySyncData({ authToken, filters });
            return mondaySyncData;
        },
    });
    return query;
};
