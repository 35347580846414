import { useQuery } from "@tanstack/react-query";
import React from "react";
import processflowProgressService from "../services/processflow-progress.service";
import Loader3 from "../sections/utilities/Loader3";
import PrintPre from "../layout/print-pre";
import PageDescription from "../layout/page-description";
import mondaySyncDataService from "../services/monday-sync-data.service";
import { Sunburst } from "../layout/sunburst";
import ColorHash from "color-hash";
import TableNeogen from "../layout/table-neogen";

export default function DealsByStage({ darkMode }: { darkMode?: boolean }) {
    const [groupedData, setGroupedData] = React.useState<{ [key: string]: any[] } | undefined>({});

    const mondaySyncDataQuery = useQuery(["mondaySyncData"], async () => {
        const response = await mondaySyncDataService.getAll();
        if (response) {
            return response.data;
        }
    });

    const processFlowProgresssQuery = useQuery(
        ["processsflowProgressesWithMondy"],
        async () => {
            const response = await processflowProgressService.getAll();
            if (response) {
                return response.data
                    .map((deal: any) => {
                        // console.log("Compare: ", deal.id, " and ", JSON.stringify(mondaySyncDataQuery.data));
                        deal.ercPayout = mondaySyncDataQuery.data?.find(
                            (item) => item.clearErcId == deal.id && item.fieldId === 8,
                        )?.ercData;
                        deal.clientPayout = mondaySyncDataQuery.data?.find(
                            (item) => item.clearErcId == deal.id && item.fieldId === 7,
                        )?.ercData;
                        return deal;
                    })
                    .filter((deal: any) => deal.ercPayout);
            }
        },
        { enabled: mondaySyncDataQuery.isSuccess },
    );

    // Group the processflows by status field and save them to state
    React.useEffect(() => {
        if (processFlowProgresssQuery.isSuccess) {
            const grouped = processFlowProgresssQuery.data?.reduce((r: any, a: any) => {
                r[a.status] = [...(r[a.status] || []), a];
                return r;
            }, {});
            // Sort grouped by status name
            const sortedGrouped: { [key: string]: any[] } | undefined = {};
            Object.keys(grouped ?? {})
                // Sort alphabetically
                .sort((a, b) => {
                    if (a.toLowerCase() < b.toLowerCase()) {
                        return -1;
                    }
                    if (a.toLowerCase() > b.toLowerCase()) {
                        return 1;
                    }
                    return 0;
                })

                .forEach(function (key) {
                    const value = grouped[key];
                    sortedGrouped[key] = value;
                    // console.log(value);
                });

            setGroupedData(sortedGrouped);
        }
    }, [
        processFlowProgresssQuery.data,
        processFlowProgresssQuery.isSuccess,
        // mondaySyncDataQuery.data,
        // mondaySyncDataQuery.isSuccess,
    ]);

    function componentToHex(c: { toString: (arg0: number) => any }) {
        const hex = c.toString(16);
        return hex.length == 1 ? "0" + hex : hex;
    }

    function rgbToHex(
        r: { toString: (arg0: number) => any },
        g: { toString: (arg0: number) => any },
        b: { toString: (arg0: number) => any },
    ) {
        return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
    }

    /*
    return {
                            id: key,
                            // data: [
                            // {
                            // id: "Hits",
                            label: (key + " (" + utmCalcs[key].hits + ")").substring(0, 38),
                            value: utmCalcs[key].hits,
                            color: rgbToHex(color[0], color[1], color[2]),
                            darkColor: rgbToHex(darkColor[0], darkColor[1], darkColor[2]),
                            // },
                            // {
                            // x: "Registrations",
                            // y: utmCalcs[key].registrations,
                            // },
                            // ],
                        };
    */

    // Create a summed total for each group
    const totals = Object.keys(groupedData ?? {}).map((key: string) => {
        const colorHash = new ColorHash({
            hue: [
                { min: 30, max: 285 },
                { min: 8, max: 10 },
                { min: 270, max: 285 },
            ],
            lightness: [0.7, 0.7, 0.7],
        });
        const color = colorHash.rgb(key);
        const colorHashDark = new ColorHash({
            hue: [
                { min: 30, max: 205 },
                { min: 8, max: 10 },
                { min: 270, max: 285 },
            ],
            lightness: [0.5, 0.5, 0.5],
        });
        const darkColor = colorHashDark.rgb(key);

        const total = groupedData?.[key]?.reduce((a: any, b: any) => {
            return a + Number(b.ercPayout);
        }, 0);
        return {
            id: key,
            key: key,
            label:
                key +
                " (" +
                Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "USD",
                }).format(total ?? 0) +
                ")",
            value: total,

            color: rgbToHex(color[0], color[1], color[2]),
            darkColor: rgbToHex(darkColor[0], darkColor[1], darkColor[2]),
        };
    });

    return (
        <>
            <PageDescription title="Summed Deals By Stage" description="Deals and their values grouped by stages" />
            {processFlowProgresssQuery.isLoading || mondaySyncDataQuery.isLoading ? (
                <Loader3 />
            ) : (
                <div>
                    <div className="h-96 mb-5  bg-gradient-to-br to-white from-slate-100 dark:from-slate-900 dark:to-gray-800 border-2 border-white rounded-xl p-5 dark:bg-gray-800 dark:border-gray-700 dark:shadow-inner shadow-sm">
                        <Sunburst data={totals} darkMode={darkMode} />
                        {/*  */}
                    </div>
                    <TableNeogen
                        entries={totals.sort((a, b) => {
                            if (a.value < b.value) {
                                return 1;
                            }
                            if (a.value > b.value) {
                                return -1;
                            }
                            return 0;
                        })}
                        headers={["Stage", "Amount", "Color", "Dark Color"]}
                        ignoreFields={["label"]}
                        formatters={[
                            {
                                field: "value",
                                type: "Currency",
                            },
                            {
                                field: "color",
                                type: "Color",
                            },
                            {
                                field: "darkColor",
                                type: "Color",
                            },
                        ]}
                    />
                </div>
            )}
        </>
    );
}
