import { makeRequest } from "../../jason-proof-of-concept/shared/utils";
import { UserCompany } from "../domain/user-company";
import { getUserCompany } from "./get-user-company";

export type UpdateUserCompanyData = Partial<UserCompany>;

export const updateUserCompany = async ({
    id,
    data,
    authToken,
}: {
    id: number;
    data: UpdateUserCompanyData;
    authToken: string;
}) => {
    const response = await makeRequest({
        method: "patch",
        path: `/user-companies/${id}`,
        data,
        authToken,
    });
    const userCompany = await getUserCompany({ authToken, id });
    return userCompany;
};
