/* eslint-disable react/display-name */
import { HTMLAttributes, ReactNode, forwardRef } from "react";

export const Table = ({ children, className, style }: { children?: ReactNode; className?: string; style?: any }) => {
    return (
        <table
            style={style}
            className={`w-full divide-y divide-gray-300 dark:divide-gray-900 rounded-lg table-fixed ${className || ""}`}
        >
            {children}
        </table>
    );
};

export const THead = ({ children, sticky }: { children?: ReactNode; sticky?: boolean }) => {
    return (
        <thead
            className="bg-gray-50 dark:bg-gray-800 rounded-t-xl shadow-md"
            style={
                sticky
                    ? {
                          position: "sticky",
                          top: 0,
                          background: "white",
                          zIndex: 10,
                      }
                    : {}
            }
        >
            {children}
        </thead>
    );
};

export const TBody = ({ children, className }: { children?: ReactNode; className?: string }) => {
    return (
        <tbody
            className={`rounded-xl divide-y divide-gray-200 bg-white dark:bg-gray-800 dark:divide-gray-900 ${
                className || ""
            }`}
        >
            {children}
        </tbody>
    );
};

export const Tr = forwardRef(
    ({ children, ...other }: { children?: ReactNode } & HTMLAttributes<HTMLTableRowElement>, ref: any) => {
        return (
            <tr ref={ref} {...other}>
                {children}
            </tr>
        );
    },
);

export const Th = ({ children, ...other }: { children?: ReactNode } & HTMLAttributes<HTMLTableHeaderCellElement>) => {
    return (
        <th
            scope="col"
            className="dark:text-gray-500 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
            {...other}
        >
            {children}
        </th>
    );
};

export const Td = ({
    children,
    ...other
}: { children?: ReactNode } & HTMLAttributes<HTMLTableDataCellElement> & { colSpan?: number }) => {
    return (
        <td className="py-4 pl-4 pr-3 text-sm sm:pl-6" {...other}>
            {children}
        </td>
    );
};
