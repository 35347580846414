import { z } from "zod";
import { User } from "../../../../jason-proof-of-concept/users/domain/user";
import { getAuthTokenNoThrow } from "../../../../services/auth-header";
import { useForm } from "../../../../hooks/useForm";
import { useMutation, useQuery } from "@tanstack/react-query";
import { updateUser } from "../../../../jason-proof-of-concept/users/actions/update-user";
import ModalDialog from "../../../../layout/modal-dialog";
import { TextField } from "../../../../layout/form/text-field";
import { Form } from "../../../../layout/form/form";
import { EmailField } from "../../../../layout/form/email-field";
import ButtonNeoGen from "../../../../layout/button-neogen";
import { getUsers } from "../../../../jason-proof-of-concept/users/actions/get-users";
import { useMemo } from "react";
import roleGroupService, { roleGroups } from "../../../../services/role-group.service";
import authService from "../../../../services/auth.service";
import { roleAssignments } from "../../../../services/role-group.service";
import { sortUsers } from "../../../utilities/sortUsers";
import { ClearERCUser } from "../../../../typings/api/clear-erc-user";

const schema = z.object({
    firstName: z.string().min(1),
    lastName: z.string().min(1),
    email: z.string().email(),
    phone: z.string().nullish(),
    affiliateUserId: z.string().nullish(),
    accountManagerUserId: z.string().nullish(),
    cpaUserId: z.string().nullish(),
    taxAttorneyUserId: z.string().nullish(),
});
type Data = z.infer<typeof schema>;

export const EditUserModal = ({
    onClose,
    user,
    onUserUpdated,
}: {
    onClose: () => any;
    user: User;
    onUserUpdated: (updatedUser: User) => any;
}) => {
    const authToken = getAuthTokenNoThrow() || "no-auth-token";
    if (!user.id) {
        throw new Error("User must have an id");
    }
    const userId = user.id as string;

    const affiliatesQuery = useQuery(["users", "affiliates"], async () => {
        const response = await getUsers({ authToken, role: "Affiliate" });
        return response || [];
    });
    const affiliates = useMemo(() => sortUsers((affiliatesQuery.data as ClearERCUser[]) || []), [affiliatesQuery.data]);

    const accountantsQuery = useQuery(["users", "accountant"], async () => {
        const response = await getUsers({ authToken, role: "Accountant" });
        return response || [];
    });
    const accountants = useMemo(
        () => sortUsers((accountantsQuery.data as ClearERCUser[]) || []),
        [accountantsQuery.data],
    );

    const docCollectorsQuery = useQuery(["users", "docCollector"], async () => {
        const response = await getUsers({ authToken });
        return response || [];
    });
    const docCollectors = useMemo(
        () => sortUsers((docCollectorsQuery.data as ClearERCUser[]) || []),
        [docCollectorsQuery.data],
    );

    const taxAttorneyQuery = useQuery(["users", "taxAttorney"], async () => {
        const response = await getUsers({ authToken });
        return response || [];
    });
    const taxAttorneys = useMemo(
        () => sortUsers((taxAttorneyQuery.data as ClearERCUser[]) || []),
        [taxAttorneyQuery.data],
    );

    const roleGroupsQuery = useQuery(["roleGroups"], async () => {
        const response = await roleGroupService.getAll();
        return response?.data || [];
    });

    const allRoleGroups = roleGroupsQuery.data || [];
    const usersRoleGroups = (authService.getCurrentUser()?.user?.roleGroups || []) as any[];
    const roleGroupsIds = usersRoleGroups.reduce<number[]>((acc, roleGroup: any) => {
        return [...acc, ...(roleAssignments?.[roleGroup.id] || [])];
    }, []);
    const finalRoleGroups = allRoleGroups.filter((rg) => roleGroupsIds.includes(rg.id || 9999));

    const isSuperUser = !!usersRoleGroups.find((rg) => rg.id === roleGroups.SuperUser);

    const form = useForm({
        schema,
        defaultValues: {
            firstName: user.firstName || undefined,
            lastName: user.lastName || undefined,
            email: user.email,
            phone: user.phone,
            affiliateUserId: user.affiliateUserId,
            accountManagerUserId: user.accountManagerUserId,
            cpaUserId: user.cpaUserId,
            taxAttorneyUserId: user.taxAttorneyUserId,
        },
    });

    const mutation = useMutation({
        mutationFn: async (data: Data) => {
            const { ...filteredData } = data;
            const updatedUser = await updateUser({
                authToken,
                id: userId,
                data: filteredData,
            });

            return updatedUser;
        },
    });

    const handleSubmit = async (data: Data) => {
        const updatedUser = await mutation.mutateAsync(data);
        onUserUpdated(updatedUser);
    };

    return (
        <ModalDialog size="xs" show={true} title={"Edit user"} close={onClose} showOk={false} showCancel={false}>
            <Form onSubmit={form.handleSubmit(handleSubmit)} error={mutation.error as any}>
                <div className="grid grid-cols-1 gap-3">
                    <TextField label="First name" {...form.getFieldProps("firstName")} isRequired />
                    <TextField label="Last name" {...form.getFieldProps("lastName")} isRequired />
                    <EmailField
                        autoComplete="username"
                        label="Email Address"
                        {...form.getFieldProps("email")}
                        isRequired
                    />
                    <TextField label="Phone" {...form.getFieldProps("phone")} />
                </div>
                <ButtonNeoGen block type="submit" disabled={mutation.isLoading}>
                    Update user
                </ButtonNeoGen>
            </Form>
        </ModalDialog>
    );
};
