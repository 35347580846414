import React from "react";
import PageDescription from "../../../layout/page-description";
import { Table } from "../../../layout/table";
import TableNeogen from "../../../layout/table-neogen";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import industryService from "../../../services/industry.service";
import industryGroupsService, { IndustryGroup } from "../../../services/industry-groups.service";
import industryGroupMembersService from "../../../services/industry-group-members.service";
import Loader3 from "../../utilities/Loader3";
import AddIndustryGroup from "./modals/add-industry-group";
import SwalNeogenFire from "../../../layout/swal-neogen";

export default function IndustryGroups() {
    const [showAddIndustryGroup, setShowAddIndustryGroup] = React.useState(false);
    const [industryGroup, setIndustryGroup] = React.useState<IndustryGroup | null>(null);
    const queryCache = useQueryClient();
    const industriesQuery = useQuery(["industries"], async () => {
        const result = await industryService.getAll();
        return result?.data ?? [];
    });
    const industryGroupsQuery = useQuery(["industryGroups"], async () => {
        const result = await industryGroupsService.getAll();
        return result?.data ?? [];
    });
    const industryGroupMembersQuery = useQuery(["industryGroupMembers"], async () => {
        const result = await industryGroupMembersService.getAll();
        return result?.data ?? [];
    });

    return (
        <>
            <PageDescription
                title="Industry Groups"
                description="Manage Industry Groups"
                buttons={[
                    {
                        label: "Add Industry Group",
                        onClick: () => {
                            setIndustryGroup(null);
                            setShowAddIndustryGroup(true);
                        },
                        icon: "fas fa-plus",
                    },
                    {
                        label: "Manage Industries",
                        onClick: () => {
                            window.location.href = "/admin/industries";
                        },
                        icon: "fas fa-building-circle-arrow-right",
                        type: "info",
                    },
                    {
                        label: "Manage Mandates",
                        onClick: () => {
                            window.location.href = "/admin/mandates";
                        },
                        icon: "fas fa-file-lines",
                        type: "secondary",
                    },
                ]}
            />
            {industriesQuery.isLoading || industryGroupsQuery.isLoading || industryGroupMembersQuery.isLoading ? (
                <Loader3 />
            ) : (
                <TableNeogen
                    entries={
                        industryGroupsQuery.data?.map((i) => {
                            return {
                                id: i.id,
                                name: i.name,
                                description: i.description,
                                industries:
                                    industryGroupMembersQuery.data
                                        ?.filter((m) => m.industryGroup === i.id)
                                        .map((m) => {
                                            return (
                                                industriesQuery.data?.find((in1) => in1.id === m.industry)?.name ?? ""
                                            );
                                        })
                                        ?.join(", ") ?? "",
                            };
                        }) ?? []
                    }
                    actions={[
                        {
                            label: "Edit",
                            onClick: (entry) => {
                                // console.log({ entry });
                                setIndustryGroup(industryGroupsQuery.data?.find((i) => i.id === entry) ?? null);
                                setShowAddIndustryGroup(true);
                            },

                            icon: "fas fa-edit",
                        },
                        {
                            label: "Delete",
                            type: "danger",
                            onClick: (entry) => {
                                SwalNeogenFire({
                                    title: "Are you sure?",
                                    text: "You won't be able to revert this!",
                                    icon: "warning",
                                    showCancelButton: true,
                                    confirmButtonText: "Yes, delete it!",
                                    cancelButtonText: "No, keep it",
                                    confirmButtonColor: "#d33",
                                    reverseButtons: true,
                                }).then(async (result) => {
                                    if (result.isConfirmed) {
                                        // Get a list of all the industryGroupMembers that are in this industryGroup
                                        // and delete them
                                        const entriesToDelete =
                                            industryGroupMembersQuery.data?.filter((i) => i.industryGroup === entry) ??
                                            [];
                                        for (const entry of entriesToDelete) {
                                            await industryGroupMembersService.deleteByID(entry.id ?? -1);
                                        }
                                        await industryService.deleteByID(entry);

                                        queryCache.invalidateQueries(["industryGroups"]);
                                        queryCache.invalidateQueries(["industryGroupMembers"]);
                                        industriesQuery.refetch();
                                        SwalNeogenFire({
                                            title: "Deleted!",
                                            text: "Your file has been deleted.",
                                            icon: "success",
                                            showConfirmButton: false,
                                            timer: 1500,
                                        });
                                    }
                                });
                            },
                            icon: "fas fa-trash",
                        },
                    ]}
                />
            )}
            {showAddIndustryGroup && (
                <AddIndustryGroup
                    industryGroup={industryGroup}
                    close={() => {
                        setShowAddIndustryGroup(false);
                    }}
                    show={showAddIndustryGroup}
                />
            )}
        </>
    );
}
