import { ResponsiveTreeMap } from "@nivo/treemap";
import stagesService from "../../../services/stages.service";
import { useQuery } from "@tanstack/react-query";
import Loader3 from "../../utilities/Loader3";
import { useEffect, useState } from "react";
import AddEditStageModal from "./modals/add-edit-stage";
import PageDescription from "../../../layout/page-description";

export default function Stages({ darkMode }: { darkMode: boolean }) {
    const [show, setShow] = useState(false);
    const [data, setData] = useState<any>({});
    const [currentStage, setCurrentStage] = useState<any>({});
    const stagesQuery = useQuery(["stages"], async () => {
        const response = await stagesService.getAll();
        return response?.data ?? [];
    });

    useEffect(() => {
        const getChildrenFromArray = (array: any[], parent: number) => {
            const children = array.filter((item) => item.parent === parent);
            for (const child of children) {
                child.children = getChildrenFromArray(array, child.id);
            }
            return children.length > 0 ? children : undefined;
        };

        const sorted = stagesQuery.data?.sort((a, b) => {
            return (a.parent ?? -1) - (b.parent ?? -1);
        });
        const cleaned =
            sorted?.map((stage) => {
                return { name: stage.name, id: stage.id, parent: stage.parent, loc: 1, color: "hsl(124, 70%, 50%)" };
            }) ?? [];
        const finalResult: any[] = [];
        for (const stage of cleaned ?? []) {
            const entry: any = { id: stage.id, name: stage.name, loc: 1, color: "hsl(124, 70%, 50%)" };
            if (stage.parent === 0) {
                entry.children = getChildrenFromArray(cleaned, stage.id ?? -1);
                finalResult.push(entry);
            }
        }
        setData({ name: "Stages", color: "hsl(124, 70%, 50%)", children: finalResult });
    }, [stagesQuery.data]);

    if (stagesQuery.isLoading) {
        return <Loader3 />;
    }
    const pattern = /([^\s].{0,14}(?=[\s\W]|$))/gm;
    return (
        <>
            <PageDescription
                title="Stages"
                description="Stages are the steps in the process that a customer goes through. This is a visual representation of the stages."
                buttons={[
                    {
                        label: "Add Stage",
                        onClick: () => {
                            setCurrentStage(undefined);
                            setShow(true);
                        },
                    },
                ]}
            />
            <div className="h-[80%]">
                <ResponsiveTreeMap
                    data={data}
                    identity="name"
                    value="loc"
                    valueFormat=" >-.2c"
                    margin={{ top: 10, right: 10, bottom: 10, left: 10 }}
                    label="id"
                    tile="sliceDice"
                    motionConfig="molasses"
                    labelTextColor={{
                        from: "color",
                        modifiers: [darkMode ? ["brighter", 200.2] : ["darker", 1.2]],
                    }}
                    parentLabelPosition="top"
                    parentLabelTextColor={{
                        from: "color",
                        modifiers: [["darker", 2]],
                    }}
                    borderColor={{
                        from: "color",
                        modifiers: [["darker", 0.1]],
                    }}
                    onClick={(node) => {
                        setCurrentStage(stagesQuery.data?.find((stage) => stage.id === node.data.id));
                        setShow(true);
                    }}
                />
            </div>
            <AddEditStageModal
                close={() => {
                    setShow(false);
                }}
                show={show}
                stage={currentStage}
                setStage={setCurrentStage}
                stages={stagesQuery.data ?? []}
            />
        </>
    );
}
