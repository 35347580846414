import { useQuery } from "@tanstack/react-query";
import { getStages } from "../../jason-proof-of-concept/stages/actions/get-stages";

type Params = {
    authToken: string;
    filters?: any;
};

const getStagesQueryKey = ({ authToken, filters }: Params) => {
    return ["processflow-progresses", { authToken, filters }];
};

export const useStages = ({ authToken, filters }: Params) => {
    const query = useQuery({
        queryKey: getStagesQueryKey({ authToken, filters }),
        queryFn: async () => {
            const stages = await getStages({ authToken });
            return stages;
        },
    });
    return query;
};
