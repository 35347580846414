import APIService from "./api.service";

class IndustryGroupMemberService extends APIService<IndustryGroupMember> {
    constructor() {
        super("/industry-group-members");
    }
}

export type IndustryGroupMember = {
    id?: number;
    industryGroup: number;
    industry: number;
};

export default new IndustryGroupMemberService();
