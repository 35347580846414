import APIService from "./api.service";

class MandateIndustryService extends APIService<MandateIndustry> {
    constructor() {
        super("/mandate-industries");
    }
}

export type MandateIndustry = {
    id?: number;
    industry: number;
    mandate: number;
};

export default new MandateIndustryService();
