import { z } from "zod";
import { userSchema } from "../../jason-proof-of-concept/users/domain/user";
import { documentTypeSchema } from "../../document-types/domain/document-type";

export const fileSchema = z.object({
    id: z.number(),
    dateUploaded: z.coerce.date(),
    originalFilename: z.string(),
    filename: z.string(),
    encoding: z.string(),
    mimetype: z.string(),
    size: z.number(),
    uploaded_by: z.string().uuid(),
    uploadedByUser: userSchema.nullish(),
    notes: z.string(),
    lastUpdated: z.coerce.date(),
    documentTypeId: z.number().nullish(),
    documentType: documentTypeSchema.nullish(),
    key: z
        .object({
            group: z.number().nullish(),
            userId: z.string().uuid().nullish(),
            entryField: z.string().nullish(),
        })
        .nullish(),
    hasMultiple: z.boolean().nullish(),
    isArchived: z.boolean().nullish(),
    companyId: z.number().nullish(),
    processflowProgressId: z.number().nullish(),
});

export type File = z.infer<typeof fileSchema>;
