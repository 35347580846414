// NOTE: The function 'validateEntry' in this file is to be kept in sync with the same function in ClearERCAPI/src/utils/validate-entry.ts
import { RuleTestResult } from "../../../sections/business-rules/hooks/use-rule-passes";
import authService from "../../../services/auth.service";
import { BusinessRuleGroup } from "../../../typings/api/business-rule-group";
import { ProcessFlowProgressData } from "../../../typings/api/processflow-progress-data";
import { BusinessRuleType } from "../../business-rule-types/domain/business-rule-type";
import { Entry, ProcessFlow } from "../domain/processflow";

export const checkIfRuleGroupPasses = ({
    businessRuleGroup,
    businessRuleTypes,
    data,
}: {
    businessRuleGroup: BusinessRuleGroup;
    businessRuleTypes: BusinessRuleType[];
    data: any;
}): RuleTestResult => {
    let passedEntries = 0;
    // Check each rule in the group, one at a time
    for (const rule of businessRuleGroup.businessRules ?? []) {
        const ruleType = businessRuleTypes?.find((type) => type.id === rule.ruleType);
        if (!ruleType) {
            return {
                passed: false,
                isError: true,
                ruleField: rule.fieldName,
                ruleFailed: rule,
                ruleGroupFailed: businessRuleGroup,
                message: "Rule type not found",
            };
        }

        // TODO: Sometimes it has field value other times it has value
        let val;
        if (Object.hasOwn && Object.hasOwn(data?.entriesByField?.[rule.fieldName] ?? {}, "fieldValue")) {
            val = data?.entriesByField?.[rule.fieldName].fieldValue;
        } else if (Object.hasOwn && Object.hasOwn(data, "entriesByField")) {
            val = data?.entriesByField?.[rule.fieldName];
        } else if (ruleType.dataType === "roleGroup") {
            val = authService.getCurrentUser().user?.roleGroups;
        } else {
            val = data?.[rule.fieldName];
        }

        if ((ruleType.id !== 10 && val === "") || val === undefined || val === null) {
            return {
                passed: false,
                isError: true,
                ruleField: rule.fieldName,
                ruleFailed: rule,
                ruleGroupFailed: businessRuleGroup,
                message:
                    "Value not found for " +
                    ruleType.name +
                    " rule in field:" +
                    rule.fieldName +
                    " (looking in: " +
                    JSON.stringify(data) +
                    ")",
            };
        }

        switch (ruleType.dataType) {
            case "checkbox":
                if (ruleType.id === 6) {
                    // Checkbox
                    if (rule.valueTriggerStart === "1") {
                        if (!val) {
                            if (businessRuleGroup.isAnd) {
                                return {
                                    passed: false,
                                    ruleGroupFailed: businessRuleGroup,
                                    ruleFailed: rule,
                                    ruleField: rule.fieldName,
                                    message: "Checkbox rule failed",
                                };
                            }
                        } else if (!businessRuleGroup.isAnd) {
                            return {
                                passed: true,
                                message: "Checkbox rule passed",
                            };
                        } else {
                            passedEntries++;
                        }
                    } else {
                        if (val) {
                            if (businessRuleGroup.isAnd) {
                                return {
                                    passed: false,
                                    ruleField: rule.fieldName,
                                    ruleFailed: rule,
                                    ruleGroupFailed: businessRuleGroup,
                                    message: "Checkbox rule failed",
                                };
                            }
                        } else if (!businessRuleGroup.isAnd) {
                            return {
                                passed: true,
                                message: "Checkbox rule passed",
                            };
                        } else {
                            passedEntries++;
                        }
                    }
                }
                break;
            case "yesNo":
                if (ruleType.id === 7) {
                    // Yes/No
                    if (rule.valueTriggerStart.toString() === "1") {
                        if (val.toString() !== "1") {
                            if (businessRuleGroup.isAnd) {
                                return {
                                    passed: false,
                                    ruleField: rule.fieldName,
                                    ruleFailed: rule,
                                    ruleGroupFailed: businessRuleGroup,
                                    message: "Yes/No rule failed",
                                    expected: "1",
                                    actual: val,
                                };
                            }
                        } else if (!businessRuleGroup.isAnd) {
                            return {
                                passed: true,
                                message: "Yes/No rule passed with " + val,
                                ruleField: rule.fieldName,
                            };
                        } else {
                            passedEntries++;
                        }
                    } else {
                        if (val.toString() !== "0") {
                            if (businessRuleGroup.isAnd) {
                                return {
                                    passed: false,
                                    ruleField: rule.fieldName,
                                    ruleFailed: rule,
                                    ruleGroupFailed: businessRuleGroup,
                                    message: "Yes/No rule failed",
                                };
                            }
                        } else if (!businessRuleGroup.isAnd) {
                            return {
                                passed: true,
                                message: "Yes/No rule passed with " + val,
                                ruleField: rule.fieldName,
                            };
                        } else {
                            passedEntries++;
                        }
                    }
                }
                break;
            case "selectBox":
                if (ruleType.id === 8) {
                    if (val !== rule.valueTriggerStart) {
                        if (businessRuleGroup.isAnd) {
                            return {
                                passed: false,
                                ruleField: rule.fieldName,
                                ruleFailed: rule,
                                ruleGroupFailed: businessRuleGroup,
                                message: "Select box rule failed",
                            };
                        }
                    } else if (!businessRuleGroup.isAnd) {
                        return {
                            passed: true,
                            ruleField: rule.fieldName,
                            rulePassed: rule,
                            ruleGroupFailed: businessRuleGroup,
                            message: "Select box rule passed and rule is or",
                        };
                    } else {
                        passedEntries++;
                    }
                }
                break;
            case "roleGroup":
                if (!val.find((e: any) => e.id === Number(rule.valueTriggerStart))) {
                    if (businessRuleGroup.isAnd) {
                        return {
                            passed: false,
                            ruleField: rule.fieldName,
                            ruleFailed: rule,
                            ruleGroupFailed: businessRuleGroup,
                            message: "Rolegroup rule failed",
                        };
                    }
                } else if (!businessRuleGroup.isAnd) {
                    return {
                        passed: true,
                        ruleField: rule.fieldName,
                        rulePassed: rule,
                        ruleGroupFailed: businessRuleGroup,
                        message: "Rolegroup rule passed and rule is or",
                    };
                } else {
                    passedEntries++;
                }
                break;
            case "textfield":
                if (ruleType.id === 4) {
                    if (Number(val) <= Number(rule.valueTriggerStart)) {
                        if (businessRuleGroup.isAnd) {
                            return {
                                passed: false,
                                ruleField: rule.fieldName,
                                ruleFailed: rule,
                                ruleGroupFailed: businessRuleGroup,
                                message: "Number higher than rule failed",
                            };
                        }
                    } else if (!businessRuleGroup.isAnd) {
                        return {
                            passed: true,
                            message: "Number higher than rule passed",
                            ruleField: rule.fieldName,
                        };
                    } else {
                        passedEntries++;
                    }
                } else if (ruleType.id === 5) {
                    if (Number(val) >= Number(rule.valueTriggerStart)) {
                        if (businessRuleGroup.isAnd) {
                            return {
                                passed: false,
                                ruleField: rule.fieldName,
                                ruleFailed: rule,
                                ruleGroupFailed: businessRuleGroup,
                                message: "Number lower than rule failed",
                            };
                        }
                    } else if (!businessRuleGroup.isAnd) {
                        return {
                            passed: true,
                            message: "Number lower than rule passed",
                            ruleField: rule.fieldName,
                        };
                    } else {
                        passedEntries++;
                    }
                } else if (ruleType.id === 9) {
                    if (val !== rule.valueTriggerStart) {
                        if (businessRuleGroup.isAnd) {
                            return {
                                passed: false,
                                ruleField: rule.fieldName,
                                ruleFailed: rule,
                                ruleGroupFailed: businessRuleGroup,
                                message: "Exact match rule failed",
                            };
                        }
                    } else if (!businessRuleGroup.isAnd) {
                        return {
                            passed: true,
                            message: "Exact match rule passed",
                            ruleField: rule.fieldName,
                        };
                    } else {
                        passedEntries++;
                    }
                } else if (ruleType.id === 10) {
                    if (businessRuleGroup.isAnd) {
                        if (isNaN(val as any) || val.length === 0) {
                            return {
                                passed: false,
                                ruleField: rule.fieldName,
                                ruleFailed: rule,
                                ruleGroupFailed: businessRuleGroup,
                                message: "Is a number rule failed",
                            };
                        } else {
                            passedEntries++;
                        }
                    } else {
                        return {
                            passed: true,
                            message: "Is a number rule passed",
                            ruleField: rule.fieldName,
                        };
                    }
                }
                break;
            case "datePicker":
                if (ruleType.id === 1) {
                    if (val < rule.valueTriggerStart || val > rule.valueTriggerEnd) {
                        if (businessRuleGroup.isAnd) {
                            return {
                                passed: false,
                                ruleField: rule.fieldName,
                                ruleFailed: rule,
                                ruleGroupFailed: businessRuleGroup,
                                message: "Date between rule failed",
                            };
                        }
                    } else if (!businessRuleGroup.isAnd) {
                        return {
                            passed: true,
                            message: "Date between rule passed",
                            ruleField: rule.fieldName,
                        };
                    } else {
                        passedEntries++;
                    }
                } else if (ruleType.id === 2) {
                    if (val < rule.valueTriggerStart) {
                        if (businessRuleGroup.isAnd) {
                            return {
                                passed: false,
                                ruleField: rule.fieldName,
                                ruleFailed: rule,
                                ruleGroupFailed: businessRuleGroup,
                                message: "Date after rule failed",
                            };
                        }
                    } else if (!businessRuleGroup.isAnd) {
                        return {
                            passed: true,
                            message: "Date after rule passed",
                            ruleField: rule.fieldName,
                        };
                    } else {
                        passedEntries++;
                    }
                } else if (ruleType.id === 3) {
                    if (val > rule.valueTriggerStart) {
                        if (businessRuleGroup.isAnd) {
                            return {
                                passed: false,
                                ruleField: rule.fieldName,
                                ruleFailed: rule,
                                ruleGroupFailed: businessRuleGroup,
                                message: "Date before rule failed",
                            };
                        }
                    } else if (!businessRuleGroup.isAnd) {
                        return {
                            passed: true,
                            message: "Date before rule passed",
                            ruleField: rule.fieldName,
                        };
                    } else {
                        passedEntries++;
                    }
                }
                break;
            case "fileUpload":
                if (ruleType.id === 11) {
                    if (businessRuleGroup.isAnd) {
                        if (!val) {
                            return {
                                passed: false,
                                ruleField: rule.fieldName,
                                ruleFailed: rule,
                                ruleGroupFailed: businessRuleGroup,
                                message: "File upload rule failed",
                            };
                        } else {
                            passedEntries++;
                        }
                    } else {
                        return {
                            passed: true,
                            message: "File Upload rule passed",
                            ruleField: rule.fieldName,
                        };
                    }
                }
                break;
            case "textarea":
                console.log("textarea");
                // alert("1")
                if (ruleType.id === 12) {
                    console.log("textarea contents Exists", { val, rule });
                    if (businessRuleGroup.isAnd) {
                        if (!val) {
                            return {
                                passed: false,
                                ruleField: rule.fieldName,
                                ruleFailed: rule,
                                ruleGroupFailed: businessRuleGroup,
                                message: "textarea rule failed",
                            };
                        } else {
                            passedEntries++;
                        }
                    } else {
                        return {
                            passed: true,
                            message: "textarea rule passed",
                            ruleField: rule.fieldName,
                        };
                    }
                }
                break;
            case "state":
                console.log("state");
                // alert("1")
                if (ruleType.id === 13) {
                    console.log("state Exists", { val, rule });
                    if (businessRuleGroup.isAnd) {
                        if (!val) {
                            return {
                                passed: false,
                                ruleField: rule.fieldName,
                                ruleFailed: rule,
                                ruleGroupFailed: businessRuleGroup,
                                message: "state rule failed",
                            };
                        } else {
                            passedEntries++;
                        }
                    } else {
                        return {
                            passed: true,
                            message: "state rule passed",
                            ruleField: rule.fieldName,
                        };
                    }
                }
                break;
        }
    }

    const finalResult = {
        passed:
            (!(businessRuleGroup.isAnd === 1) && passedEntries > 0) ||
            (businessRuleGroup.isAnd === 1 && passedEntries >= (businessRuleGroup.businessRules ?? []).length),
        ruleField: "None",
        ruleFailed: undefined,
        ruleGroupFailed: businessRuleGroup,
        message:
            businessRuleGroup.name +
            " " +
            (businessRuleGroup.isQualify ? "Qualifier" : "Disqualifier") +
            " Nothing returned (" +
            passedEntries +
            " passes)",
    };
    return finalResult;
};

/**
 * Checks if the given customer data pass the given business rule groups.
 * @param {BusinessRuleGroup[]} businessRuleGroups - The business rule groups to check against.
 * @param {BusinessRuleType[]} businessRuleTypes - The business rule types to check against.
 * @param {any} data - The responses to the questions
 * @returns {RuleTestResult} - The result of the test.
 */
export const checkIfRuleGroupListPasses = ({
    businessRuleGroups,
    businessRuleTypes,
    data,
    ruleGroupsAreOr,
}: {
    businessRuleGroups: BusinessRuleGroup[];
    businessRuleTypes: BusinessRuleType[];
    data: any;
    ruleGroupsAreOr?: boolean;
}): RuleTestResult => {
    const results = [];

    if (businessRuleGroups.length === 0) {
        return {
            passed: true,
            message: "No rules to check",
            ruleField: "None",
        };
    }

    /**
     * Loop through the business rule groups and check if they pass.
     * If we're doing an "or" test, we can return as soon as we find a passing rule group.
     * If we're doing an "and" test, we can return as soon as we find a failing rule group.
     */
    for (const ruleGroup of businessRuleGroups ?? []) {
        // alert("hi")
        const result = checkIfRuleGroupPasses({
            businessRuleGroup: ruleGroup,
            data,
            businessRuleTypes: businessRuleTypes,
        });

        // If the rule group is a disqualify rule, then the result is inverted.
        if (ruleGroup.isQualify === 0) {
            result.passed = !result.passed;
        }

        // If one didn't amd we're doing an "and" test, return the result.
        if (!result.passed && !ruleGroupsAreOr) {
            // alert("hi")
            return result;
        }

        // If one did and we're doing an "or" test, return the result.
        if (ruleGroupsAreOr && result.passed) {
            return result;
        }

        // Otherwise, add the result to the list of results.
        results.push(result);
    }

    if (ruleGroupsAreOr) {
        if (results.filter((e) => e.passed)?.length === 0) {
            return {
                passed: false,
                message: "All rules failed",
                results,
            };
        }
    } else {
        return {
            passed: true,
            message: "One or more rules passed with and",
            results,
        };
    }
    return {
        passed: true,
        message: "All rules passed",
        results,
    };
};

/**
 * Validates the entry (business rule) object.
 * @param {Entry} entry - the business rule we want to check
 * @param {BusinessRuleType[]} businessRuleTypes - the list of business rule types
 * @param {any} data - the data the customer has filled in for this field
 * @returns {RuleTestResult} - the result of the validation
 */
export const validateEntry = ({
    entry,
    businessRuleTypes,
    data,
}: {
    entry: Entry;
    businessRuleTypes: BusinessRuleType[];
    data: any;
}): RuleTestResult => {
    /**
     * Checks if the entry has any rule groups.
     * @param {Entry} entry - the field to check
     * @returns {RuleTestResult} with passed true if the entry has no rule groups, continues otherwise
     */
    if (entry?.ruleGroups?.length === 0) {
        return {
            passed: true,
            message: "No rule groups",
        };
    }

    /**
     * Checks if the rule group list passes the business rule checks.
     * @param {BusinessRuleGroup[]} businessRuleGroups - The list of business rule groups.
     * @param {BusinessRuleType[]} businessRuleTypes - The list of business rule types.
     * @param {any} data - the data the customer has filled in
     * @param {ruleGroupsAreOr} boolean - whether we need to match all rule groups or just one
     * @returns {RuleTestResult} - the result of the validation
     */
    const response = checkIfRuleGroupListPasses({
        businessRuleGroups: entry.ruleGroups || [],
        businessRuleTypes: businessRuleTypes,
        data,
        ruleGroupsAreOr: entry.ruleGroupsAreOr,
    });
    return response;
};

export const checkIfProcessflowPasses = ({
    processflow,
    progressData,
    businessRuleGroups,
    businessRuleTypes,
}: {
    processflow: ProcessFlow;
    progressData: Record<string, string | number | Date | boolean>;
    businessRuleGroups?: BusinessRuleGroup[];
    businessRuleTypes: BusinessRuleType[];
}) => {
    if (!businessRuleGroups || businessRuleGroups.length === 0) {
        return {
            passed: true,
            message: "No rule groups",
        };
    }

    const result = checkIfRuleGroupListPasses({
        businessRuleGroups,
        businessRuleTypes,
        data: progressData,
        ruleGroupsAreOr: !!processflow.ruleGroupsAreOr,
    });
    return result;
};
