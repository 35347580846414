import "./wdyr";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import axios from "axios";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import "./i18n";
import { onlineManager } from "@tanstack/react-query";

export const API_URL = process.env.REACT_APP_API_URL ?? "https://api.clearerc.com";

const MILLI_SECOND = 1000;

const isRefreshTokenExpired = (refreshToken: string) => {
    const tokenData = JSON.parse(atob(refreshToken.split(".")[1]));

    const expirationTime = tokenData.exp * MILLI_SECOND;

    const currentTime = Date.now();

    if (currentTime >= expirationTime) {
        return true;
    }

    return false;
};

const refreshToken = async () => {
    const user = JSON.parse(localStorage.getItem("user") ?? "");
    if (user) {
        const refreshTokenExpired = isRefreshTokenExpired(user.refreshToken);

        if (refreshTokenExpired) {
            return "";
        }

        const response = await axios.post(API_URL + "refresh", {
            refreshToken: user.refreshToken,
        });

        if (response.data && response.data.refreshToken) {
            user.token = response.data.accessToken;
            localStorage.setItem("user", JSON.stringify(user));
            localStorage.setItem("token", user.token);
            localStorage.setItem("refreshToken", response.data.refreshToken);
            return user.token;
        }
    }
    return "";
};

const handleUnauthorized = async (error: any) => {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry && !window.location.href.startsWith("/login")) {
        originalRequest._retry = true;
        try {
            const refreshedToken = await refreshToken();
            if (refreshedToken) {
                originalRequest.headers.Authorization = `Bearer ${refreshedToken}`;
                return axios(originalRequest);
            }
            throw new Error("Could not refresh token");
        } catch (refreshError) {
            // could not refresh token
            localStorage.removeItem("user");
            localStorage.removeItem("token");
            localStorage.removeItem("refreshToken");
            window.location.href = `/login?redirectUri=${encodeURIComponent(window.location.pathname)}`;
            return Promise.reject(refreshError);
        }
    } else {
        throw error;
    }
};

axios.interceptors.response.use(
    (response) => response,
    (error) => handleUnauthorized(error),
);

const commitId = process.env.REACT_APP_COMMIT_ID;

if (process.env.NODE_ENV !== "development") {
    Sentry.init({
        dsn: "https://9bf5ba06dc5042b99b9a7590544bfcec@o4504022380118016.ingest.sentry.io/4504022381690880",
        environment: process.env.REACT_APP_ENVIRONMENT,
        // This sets the sample rate to be 10%. You may want this to be 100% while
        // in development and sample at a lower rate in production
        replaysSessionSampleRate: 0.1,
        // If the entire session is not sampled, use the below sample rate to sample
        // sessions when an error occurs.
        replaysOnErrorSampleRate: 1.0,
        integrations: [
            new Sentry.Replay({
                // Additional SDK configuration goes in here, for example:
                maskAllText: true,
                blockAllMedia: true,
            }),
            new BrowserTracing(),
        ],
        release: commitId,
        tracesSampleRate: 1.0,
    });
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore This is allowed
global.appVersion = commitId;

import { createRoot } from "react-dom/client";
const container = document.getElementById("root");
onlineManager.setOnline(true);
if (container) {
    const root = createRoot(container);
    root.render(<App />);
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
