import { useRecoilState } from "recoil";
import userAtom from "../atoms/userAtom";
import { useCallback } from "react";
import { User } from "../jason-proof-of-concept/users/domain/user";
import { getAuthTokenNoThrow } from "../services/auth-header";

export const useAuth = () => {
    const [user] = useRecoilState(userAtom);

    const expectAuthToken = useCallback(() => {
        const authToken = getAuthTokenNoThrow();
        if (!authToken) {
            throw new Error("No auth token");
        }
        return authToken;
    }, []);

    const expectUser = useCallback(() => {
        if (!user) {
            throw new Error("User not logged in");
        }
        return user as User;
    }, [user]);

    return { expectUser, expectAuthToken };
};
