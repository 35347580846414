import APIService from "./api.service";

class MandateBindsService extends APIService<MandateBind> {
    constructor() {
        super("/mandate-binds");
    }
}

export type MandateBind = {
    id?: number;
    boundBy: number;
    binds: number;
};

export default new MandateBindsService();
