// install (please make sure versions match peerDependencies)
// yarn add @nivo/core @nivo/pie
import { ResponsivePie } from "@nivo/pie";

// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.
// export const Sunburst = () => (
export const Sunburst = ({ data, darkMode /* see data tab */ }: { data: any; darkMode: any }) => (
    <ResponsivePie
        data={data}
        margin={{ top: 40, right: 220, bottom: 40, left: 0 }}
        innerRadius={0.5}
        padAngle={0.7}
        cornerRadius={3}
        activeOuterRadiusOffset={8}
        sortByValue={true}
        // colors={{ scheme: "spectral" }}
        colors={{ datum: darkMode ? "data.darkColor" : "data.color" }}
        borderWidth={1}
        borderColor={{
            from: "color",
            modifiers: [["darker", 0.2]],
        }}
        enableArcLinkLabels={false}
        arcLinkLabelsSkipAngle={10}
        arcLinkLabelsTextColor="#f33333"
        arcLinkLabelsThickness={2}
        arcLinkLabelsColor={{ from: "color" }}
        enableArcLabels={false}
        arcLabelsSkipAngle={10}
        arcLabelsTextColor={{
            from: "color",
            modifiers: [["darker", 2]],
        }}
        legends={[
            {
                anchor: "right",
                direction: "column",
                justify: false,
                translateX: 20,
                translateY: -2,
                // itemsSpacing: 12,
                itemWidth: 33,
                itemHeight: 18,
                itemTextColor: darkMode ? "#ccc" : "#444",
                itemDirection: "left-to-right",
                itemOpacity: 1,
                symbolSize: 12,
                symbolShape: "circle",
                effects: [
                    {
                        on: "hover",
                        style: {
                            itemTextColor: darkMode ? "#f00" : "#000",
                        },
                    },
                ],
            },
        ]}
    />
);
