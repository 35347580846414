import { useController } from "react-hook-form";
import { GetFieldPropsReturn } from "../../hooks/useForm";
import { Field, FormFieldProps } from "./field";
import { NumberInput } from "./number-input";
import { TextInputProps } from "./text-input";
import { formatCurrency } from "../../billing/utils";
import { CurrencyInput } from "./currency-input";

export const CurrencyField = ({
    name,
    form,
    isRequired,
    label,
    noMargin,
    ...other
}: FormFieldProps<number> & GetFieldPropsReturn<any> & TextInputProps & { noMargin?: boolean }) => {
    const { field, fieldState } = useController({
        name,
        control: form.control,
        rules: { required: isRequired ? "This field is required" : undefined },
        defaultValue: other.defaultValue || 0,
    });
    const error = fieldState.error?.message;

    return (
        <Field label={label} isRequired={isRequired} helpText={other.helperText} error={error} noMargin={noMargin}>
            <CurrencyInput
                {...other}
                {...field}
                onChange={(e) => {
                    field.onChange(Number.parseFloat(e.target.value));
                    if (other.onChange) {
                        other.onChange(e);
                    }
                }}
                hasError={!!error}
            />
        </Field>
    );
};
