import { TBody, THead, Table, Td, Th, Tr } from "../../layout/table";
import { useFiles } from "../../files/hooks/use-files";
import { useMemo } from "react";
import { getAuthToken } from "../../services/auth-header";
import ButtonNeoGen from "../../layout/button-neogen";
import fileService from "../../services/file.service";

export const Files = ({ companyId }: { companyId: number }) => {
    const authToken = getAuthToken();
    const filesQuery = useFiles({
        authToken,
        filters: { where: { companyId }, include: ["uploadedByUser", "documentType"] },
    });
    const files = useMemo(() => filesQuery.data?.pageItems || [], [filesQuery.data]);

    return (
        <Table>
            <THead>
                <Tr>
                    <Th style={{ width: "30%" }}>Filename</Th>
                    <Th>Date uploaded</Th>
                    <Th>Uploaded by</Th>
                    <Th>
                        <ButtonNeoGen
                            onClick={async () => {
                                const zippedFiles = await fileService.createZip(files.map((f) => f.filename));
                                if (zippedFiles?.data) {
                                    fileService.downloadFile(
                                        zippedFiles?.data?.filename,
                                        zippedFiles?.data?.originalFilename,
                                    );
                                }
                            }}
                        >
                            Download all files
                        </ButtonNeoGen>
                    </Th>
                </Tr>
            </THead>
            <TBody>
                {files.length === 0 && (
                    <Tr>
                        <Td colSpan={4} style={{ textAlign: "center" }}>
                            {filesQuery.isLoading ? "Loading..." : "No files uploaded yet"}
                        </Td>
                    </Tr>
                )}
                {files.length > 0 && (
                    <>
                        {files.map((file) => (
                            <Tr key={file.id}>
                                <Td>
                                    <div style={{ display: "flex", flexDirection: "column" }}>
                                        <div>{file.originalFilename}</div>
                                        <div style={{ color: "gray" }}>
                                            {file.documentType?.name || file.key?.entryField}
                                        </div>
                                    </div>
                                </Td>
                                <Td>{file.dateUploaded.toUTCString()}</Td>
                                <Td>
                                    <div style={{ display: "flex", flexDirection: "column" }}>
                                        <div>
                                            {[file.uploadedByUser?.firstName, file.uploadedByUser?.lastName].join(" ")}
                                        </div>
                                        <div style={{ color: "gray" }}>{file.uploadedByUser?.email}</div>
                                    </div>
                                </Td>
                                <Td>
                                    <ButtonNeoGen
                                        onClick={() => {
                                            fileService.downloadFile(file.filename, file.originalFilename);
                                        }}
                                    >
                                        Download
                                    </ButtonNeoGen>
                                </Td>
                            </Tr>
                        ))}
                    </>
                )}
            </TBody>
        </Table>
    );
};
