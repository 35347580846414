/* This example requires Tailwind CSS v2.0+ */
import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon, EllipsisVerticalIcon } from "@heroicons/react/24/solid";
import { TableAction } from "./table-neogen";

function classNames(...classes: any) {
    return classes.filter(Boolean).join(" ");
}

export default function OptionsDropDown(props: OptionsDropDownProps) {
    return (
        <div className="static" onClick={(e) => e.stopPropagation()}>
            {props.options.length > 0 && (
                <Menu as="div" className="static block text-left left-0 w-[120px]">
                    <div>
                        {props.asEllipses ? (
                            <Menu.Button className="inline-flex justify-center w-full ">
                                <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
                            </Menu.Button>
                        ) : (
                            <Menu.Button className="z-10 inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 dark:focus:ring-offset-gray-900 focus:ring-indigo-500">
                                Options
                                <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
                            </Menu.Button>
                        )}
                    </div>
                    <div className="relative z-20">
                        <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                        >
                            <Menu.Items className="z-20 absolute right-0 w-56 rounded-md shadow-lg bg-white dark:bg-gray-800 ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none dark:border-gray-600 dark:border">
                                <div className="">
                                    {props.options.map((option, idx: number) => {
                                        return (
                                            <Menu.Item key={idx} disabled={option?.disabled}>
                                                {({ active }) => (
                                                    <a
                                                        href="#"
                                                        onClick={
                                                            option?.disabled
                                                                ? null
                                                                : (option?.action && option.action) ||
                                                                  (option?.onClick && option.onClick)
                                                        }
                                                        style={option?.disabled ? { color: "gray" } : {}}
                                                        className={classNames(
                                                            active
                                                                ? " bg-gray-100 dark:bg-gray-800 text-gray-900 dark:text-green-400 "
                                                                : " text-gray-700 dark:text-gray-400 ",
                                                            option?.disabled
                                                                ? " dark:text-gray-700 pointer-events-none cursor-not-allowed text-gray-100 "
                                                                : " cursor-pointer ",
                                                            " group flex items-center px-4 py-2 text-sm ",
                                                        )}
                                                    >
                                                        <span
                                                            className={
                                                                "fa-fw mr-3 text-gray-400 dark:text-green-500 " +
                                                                option?.icon
                                                            }
                                                        />
                                                        {option?.label}
                                                    </a>
                                                )}
                                            </Menu.Item>
                                        );
                                    })}
                                </div>
                            </Menu.Items>
                        </Transition>
                    </div>
                </Menu>
            )}
        </div>
    );
}

type Option = {
    label: string;
    action?: any;
    onClick?: (id?: any) => void;
    icon?: string;
    disabled?: boolean;
};

type OptionsDropDownProps = {
    options: (Option | null)[];
    asEllipses?: boolean;
};
