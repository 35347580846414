import { useQuery } from "@tanstack/react-query";
import { CompanyFilters } from "../components/modals/company-filters-modal";
import { getHedgeFunds } from "../actions/get-hedge-funds";

type Params = {
    authToken: string;
    filters?: any;
    adHocFilters?: CompanyFilters;
};

const getHedgeFundsQueryKey = ({ authToken, filters, adHocFilters }: Params) => {
    return ["hedge-funds", { authToken, filters, adHocFilters }];
};

export const useHedgeFunds = ({ authToken, filters, adHocFilters }: Params) => {
    const query = useQuery({
        queryKey: getHedgeFundsQueryKey({ authToken, filters, adHocFilters }),
        queryFn: async () => {
            const companies = await getHedgeFunds({ authToken, filters, adHocFilters });
            return companies;
        },
    });
    return query;
};
