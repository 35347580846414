import { useState } from "react";
import { AddUserToCompanyModal } from "./modals/add-user-to-company-modal";
import { User } from "../../jason-proof-of-concept/users/domain/user";
import { RoleGroup } from "../../role-groups/domain/role-group";
import { EditUserModal } from "./modals/edit-user-modal";
import { UserCompany } from "../../user-companies/domain/user-company";
import { TBody, THead, Table, Td, Th, Tr } from "../../layout/table";
import ButtonNeoGen from "../../layout/button-neogen";
import OptionsDropDown from "../../layout/options-dropdown";
import { formatPhonenumber } from "../../sections/utilities/formatters";
import { InviteExistingUserModal } from "./modals/invite-existing-user-modal";
import SwalNeogenFire from "../../layout/swal-neogen";
import { getDarkMode } from "../../sections/admin/magic-links/magic-links";
import Avatar from "react-avatar";
import { Company } from "../domain/company";
import { ReInviteUserModal } from "./modals/re-invite-user-modal";
import { timeAgo } from "../../sections/users/cards/calls-list";
import { CommissionModal } from "./modals/commission-modal";
import { useRecoilState } from "recoil";
import userAtom from "../../atoms/userAtom";
import { useBreakpoint } from "../../hooks/appMedia";
import { EnvelopeIcon, InformationCircleIcon, UserIcon } from "@heroicons/react/24/outline";
import ResetPassword from "../../sections/admin/users/modals/reset-password";
import { ClearERCUser } from "../../typings/api/clear-erc-user";
import { omit } from "lodash";
import { AdminResetPasswordModal } from "./modals/admin-reset-password-modal";

export const Members = ({
    company,
    onUserAdded,
    onUserUpdated,
    onRemoveUser,
    onUserInvited,
    onCommissionUpdated,
    companyRoles,
    userCompanies,
    users,
    darkMode,
}: {
    company: Company;
    onUserAdded: (user: User) => void;
    onUserUpdated: (user: User) => void;
    onRemoveUser: (userId: string) => void;
    onUserInvited: () => void;
    onCommissionUpdated: (userCompanyId: number, commission: number) => void;
    companyRoles: RoleGroup[];
    userCompanies: UserCompany[];
    users: User[];
    darkMode?: boolean;
}) => {
    const [showAddUserModal, setShowAddUserModal] = useState(false);
    const [showInviteUserModal, setShowInviteUserModal] = useState(false);
    const [showCommissionModal, setShowCommissionModal] = useState(false);
    const [userToCommission, setUserToCommission] = useState<User | undefined>();
    const [userToEdit, setUserToEdit] = useState<User | undefined>();
    const [userToResetPasswordFor, setUserToResetPasswordFor] = useState<User | undefined>();
    const [userToReSend, setUserToResend] = useState<User | undefined>();
    const companyId = company.id;
    const [loggedInUser] = useRecoilState(userAtom);

    const isSuperUser = loggedInUser?.roleGroups?.find((r: any) => r.id === 4);

    const totalCommissionPercentage = userCompanies
        .filter((uc) => uc.ercUserId !== userToCommission?.id)
        .reduce((sum, uc) => {
            const commissionPercentage = uc.commissionPercentage || 0;
            if (!isNaN(commissionPercentage)) {
                return sum + commissionPercentage;
            }
            return sum;
        }, 0);

    const breakpoints = useBreakpoint();
    const isMobile = breakpoints.breakpoint === "mobile";
    const isTablet = breakpoints.breakpoint === "tablet";

    return (
        <div>
            {showAddUserModal && (
                <AddUserToCompanyModal
                    onClose={() => setShowAddUserModal(false)}
                    companyId={companyId}
                    onUserAdded={(user) => {
                        setShowAddUserModal(false);
                        onUserAdded(user);
                    }}
                    companyRoles={companyRoles as any[]}
                />
            )}
            {showInviteUserModal && (
                <InviteExistingUserModal
                    onClose={() => setShowInviteUserModal(false)}
                    companyId={companyId}
                    onUserAdded={(user) => {
                        setShowInviteUserModal(false);
                        onUserAdded(user);
                    }}
                    companyRoles={companyRoles as any[]}
                />
            )}
            {userToEdit && (
                <EditUserModal
                    onClose={() => setUserToEdit(undefined)}
                    user={userToEdit}
                    companyId={companyId}
                    onUserUpdated={(user) => {
                        setUserToEdit(undefined);
                        onUserUpdated(user);
                    }}
                    currentRole={
                        companyRoles.filter((r) =>
                            userCompanies
                                .filter((uc) => uc.ercUserId === userToEdit.id && uc.companyId === companyId)
                                .map((uc) => uc.roleGroupId)
                                .includes(r.id || -1),
                        )[0]
                    }
                    companyRoles={companyRoles as any[]}
                />
            )}
            {userToReSend && (
                <ReInviteUserModal
                    user={userToReSend}
                    onClose={() => setUserToResend(undefined)}
                    companyId={company.id}
                    onUserInvited={() => {
                        setUserToResend(undefined);
                        onUserInvited();
                    }}
                />
            )}
            {userToCommission && showCommissionModal && (
                <CommissionModal
                    onClose={() => setShowCommissionModal(false)}
                    onSubmit={(commission) => {
                        const userCompany = userCompanies.find(
                            (uc) => uc.ercUserId === userToCommission?.id && uc.companyId === company.id,
                        );

                        if (!userCompany) {
                            throw new Error("User company not found");
                        }

                        onCommissionUpdated(userCompany.id, commission);
                    }}
                    totalCommissionPercentage={totalCommissionPercentage}
                />
            )}
            {userToResetPasswordFor && (
                <AdminResetPasswordModal
                    user={userToResetPasswordFor}
                    onClose={() => {
                        setUserToResetPasswordFor(undefined);
                    }}
                    onUserUpdated={(updatedUser) => {
                        setUserToResetPasswordFor(undefined);
                        onUserUpdated(updatedUser);
                    }}
                />
            )}
            <div className="flex justify-end mb-6">
                <div className="flex gap-2 mx-4">
                    <ButtonNeoGen onClick={() => setShowInviteUserModal(true)}>Add User</ButtonNeoGen>
                </div>
            </div>
            <div className="mx-4">
                {!isMobile && !isTablet ? (
                    <Table>
                        <THead>
                            <Tr>
                                <Th style={{ width: "28%" }}>Name</Th>
                                <Th style={{ width: "23%" }}>Dates</Th>
                                <Th>Role</Th>
                                <Th>Status</Th>
                                {isSuperUser && <Th>Commission %</Th>}
                                <Th></Th>
                            </Tr>
                        </THead>
                        <TBody>
                            {users
                                .filter((user) => {
                                    const currentRole = companyRoles.filter((r) =>
                                        userCompanies
                                            .filter((uc) => uc.ercUserId === user.id && uc.companyId === companyId)
                                            .map((uc) => uc.roleGroupId)
                                            .includes(r.id || -1),
                                    )[0] as RoleGroup | undefined;
                                    return !!currentRole;
                                })
                                .map((user) => {
                                    const currentRole = companyRoles.filter((r) =>
                                        userCompanies
                                            .filter((uc) => uc.ercUserId === user.id && uc.companyId === companyId)
                                            .map((uc) => uc.roleGroupId)
                                            .includes(r.id || -1),
                                    )[0] as RoleGroup | undefined;

                                    const statusDate =
                                        user.userStatus === "invited"
                                            ? user.invitedAt
                                            : user.userStatus === "active"
                                            ? user.accountSetupAt
                                            : undefined;

                                    return (
                                        <Tr key={user.id}>
                                            <Td className="break-all" style={{ paddingTop: 0, paddingBottom: 0 }}>
                                                <div className="flex flex-col">
                                                    <div className="flex flex-row items-center">
                                                        <div className="pr-3">
                                                            <Avatar
                                                                name={[user.firstName, user.lastName].join(" ")}
                                                                size="44"
                                                                email={user.email}
                                                                round={true}
                                                                className="flex h-10 w-10 items-center justify-center rounded-full bg-gray-400  "
                                                            />
                                                        </div>
                                                        <div className="flex flex-col">
                                                            <div>{[user.firstName, user.lastName].join(" ")}</div>
                                                            <div style={{ color: "gray", fontSize: 14 }}>
                                                                <a
                                                                    style={{ textDecoration: "underline" }}
                                                                    href={`mailto:${user.email}`}
                                                                >
                                                                    {user.email}
                                                                </a>
                                                            </div>
                                                            <div style={{ color: "gray", fontSize: 14 }}>
                                                                <a
                                                                    style={{ textDecoration: "underline" }}
                                                                    href={`tel:${user.phone}`}
                                                                >
                                                                    {user.phone && formatPhonenumber(user.phone)}
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Td>
                                            <Td style={{ paddingTop: 0, paddingBottom: 0 }}>
                                                <div>
                                                    <div className="flex flex-row gap-2">
                                                        <div className="text-gray-400">Created:</div>
                                                        <div>
                                                            {user.createdAt ? timeAgo(user.createdAt).toString() : "-"}
                                                        </div>
                                                    </div>
                                                    <div className="flex flex-row gap-2">
                                                        <div className="text-gray-400">Last login:</div>
                                                        <div>
                                                            {user.lastLoggedIn
                                                                ? timeAgo(user.lastLoggedIn).toString()
                                                                : "-"}
                                                        </div>
                                                    </div>
                                                </div>
                                            </Td>
                                            <Td>{currentRole?.name || "-"}</Td>
                                            <Td>
                                                <div className="flex flex-col">
                                                    <div>
                                                        {user.emailVerified || user.userStatus === "active"
                                                            ? "Active"
                                                            : user.userStatus === "invited"
                                                            ? "Invited"
                                                            : "Inactive"}
                                                    </div>
                                                    {statusDate && (
                                                        <div className="text-gray-400">
                                                            {timeAgo(statusDate).toString()}
                                                        </div>
                                                    )}
                                                </div>
                                            </Td>

                                            {isSuperUser && (
                                                <Td>
                                                    <div className="flex flex-col">
                                                        <div>
                                                            {userCompanies.find((uc) => uc.ercUserId === user.id)
                                                                ?.totalFeePercent || 0}
                                                        </div>
                                                    </div>
                                                </Td>
                                            )}

                                            <Td style={{ paddingTop: 0, paddingBottom: 0 }}>
                                                <div className="flex flex-1 justify-end">
                                                    <div style={{ width: 120 }}>
                                                        <OptionsDropDown
                                                            options={[
                                                                {
                                                                    label: "Edit user",
                                                                    action: () => setUserToEdit(user),
                                                                },
                                                                ...(isSuperUser
                                                                    ? [
                                                                          {
                                                                              label: "Reset password",
                                                                              action: () =>
                                                                                  setUserToResetPasswordFor(user),
                                                                          },
                                                                      ]
                                                                    : []),
                                                                ...(!user.emailVerified
                                                                    ? [
                                                                          {
                                                                              label: "Resend invitation email",
                                                                              action: () => setUserToResend(user),
                                                                          },
                                                                      ]
                                                                    : []),
                                                                {
                                                                    label: "Remove from company",
                                                                    action: () => {
                                                                        SwalNeogenFire({
                                                                            darkMode: getDarkMode(),
                                                                            title: "Remove user from company?",
                                                                            text: "Are you sure you want to remove this user?",
                                                                            icon: "info",
                                                                            showCancelButton: true,
                                                                            confirmButtonText: "Yes, remove!",
                                                                            cancelButtonText: "No, cancel!",
                                                                            showLoaderOnConfirm: true,
                                                                        }).then(async (result) => {
                                                                            if (result.isConfirmed) {
                                                                                onRemoveUser(user.id);
                                                                            }
                                                                        });
                                                                    },
                                                                },
                                                                {
                                                                    label: "Set commission %",
                                                                    disabled: !isSuperUser,
                                                                    action: () => {
                                                                        setShowCommissionModal(true);
                                                                        setUserToCommission(user);
                                                                    },
                                                                },
                                                            ]}
                                                        />
                                                    </div>
                                                </div>
                                            </Td>
                                        </Tr>
                                    );
                                })}
                        </TBody>
                    </Table>
                ) : (
                    <ul className="m-4">
                        {users
                            .filter((user) => {
                                const currentRole = companyRoles.filter((r) =>
                                    userCompanies
                                        .filter((uc) => uc.ercUserId === user.id && uc.companyId === companyId)
                                        .map((uc) => uc.roleGroupId)
                                        .includes(r.id || -1),
                                )[0] as RoleGroup | undefined;
                                return !!currentRole;
                            })
                            .map((user) => {
                                const currentRole = companyRoles.filter((r) =>
                                    userCompanies
                                        .filter((uc) => uc.ercUserId === user.id && uc.companyId === companyId)
                                        .map((uc) => uc.roleGroupId)
                                        .includes(r.id || -1),
                                )[0] as RoleGroup | undefined;

                                const statusDate =
                                    user.userStatus === "invited"
                                        ? user.invitedAt
                                        : user.userStatus === "active"
                                        ? user.accountSetupAt
                                        : undefined;

                                return (
                                    <li
                                        key={user.id}
                                        className="bg-gray-50 dark:bg-gray-700 dark:border-gray-900 p-4 rounded-lg shadow-md mb-4"
                                    >
                                        <div className="flex items-start justify-between">
                                            <div>
                                                <div className="flex gap-2 pb-1">
                                                    <UserIcon
                                                        color={darkMode ? "#4ade80" : "#4338ca"}
                                                        className="w-5 h-5"
                                                    />
                                                    <span>
                                                        {user.firstName
                                                            ? [user.firstName, user.lastName].join(" ")
                                                            : "Unknown"}
                                                    </span>
                                                </div>
                                                <div className="flex gap-2 pb-1">
                                                    <EnvelopeIcon
                                                        color={darkMode ? "#4ade80" : "#4338ca"}
                                                        className="w-5 h-5"
                                                    />
                                                    <span>
                                                        <div>
                                                            <a
                                                                style={{ textDecoration: "underline" }}
                                                                href={`mailto:${user.email}`}
                                                            >
                                                                {user.email}
                                                            </a>
                                                        </div>
                                                    </span>
                                                </div>
                                                <div className="flex gap-2 pb-1">
                                                    <InformationCircleIcon
                                                        color={darkMode ? "#4ade80" : "#4338ca"}
                                                        className="w-5 h-5"
                                                    />
                                                    <span>{currentRole?.name || "Unknown"}</span>
                                                </div>
                                            </div>
                                            <OptionsDropDown
                                                asEllipses
                                                options={[
                                                    { label: "Edit user", action: () => setUserToEdit(user) },
                                                    ...(!user.emailVerified
                                                        ? [
                                                              {
                                                                  label: "Resend invitation email",
                                                                  action: () => setUserToResend(user),
                                                              },
                                                          ]
                                                        : []),
                                                    {
                                                        label: "Remove from company",
                                                        action: () => {
                                                            SwalNeogenFire({
                                                                darkMode: getDarkMode(),
                                                                title: "Remove user from company?",
                                                                text: "Are you sure you want to remove this user?",
                                                                icon: "info",
                                                                showCancelButton: true,
                                                                confirmButtonText: "Yes, remove!",
                                                                cancelButtonText: "No, cancel!",
                                                                showLoaderOnConfirm: true,
                                                            }).then(async (result) => {
                                                                if (result.isConfirmed) {
                                                                    onRemoveUser(user.id);
                                                                }
                                                            });
                                                        },
                                                    },
                                                    {
                                                        label: "Set commission %",
                                                        disabled: !isSuperUser,
                                                        action: () => {
                                                            setShowCommissionModal(true);
                                                            setUserToCommission(user);
                                                        },
                                                    },
                                                ]}
                                            />
                                        </div>
                                        <div className="flex justify-start w-full mt-2">
                                            <div className="text-xs font-light italic">
                                                <div>
                                                    <div className="flex gap-2">
                                                        <div className="text-gray-400 text-xs">Created:</div>
                                                        <div>
                                                            {user.createdAt ? timeAgo(user.createdAt).toString() : "-"}
                                                        </div>
                                                    </div>
                                                    <div className="flex gap-2">
                                                        <div className="text-gray-400 text-xs">Last login:</div>
                                                        <div>
                                                            {user.lastLoggedIn
                                                                ? timeAgo(user.lastLoggedIn).toString()
                                                                : "-"}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                );
                            })}
                    </ul>
                )}
            </div>
        </div>
    );
};
