/* eslint-disable indent */
import { z } from "zod";
import { makeRequest, parseSchema } from "../../shared/utils";

export const stageResponseSchema = z.object({
    id: z.number(),
    name: z.string(),
    description: z.string().optional().nullish(),
    requiredRoleGroup: z.number().optional().nullish(),
    parent: z.number().optional(),
});

export type StagesData = z.infer<typeof stageResponseSchema>;

const getStageResponseSchema = z.array(stageResponseSchema);

export const getStages = async ({ authToken }: { authToken?: string }) => {
    try {
        const response = await makeRequest({
            method: "get",
            path: "/stages",
            authToken,
        });

        const stages = parseSchema(getStageResponseSchema, response.data);

        return stages;
    } catch (error) {
        console.error(error);
        throw error;
    }
};
