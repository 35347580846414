import { useState } from "react";
import { z } from "zod";
import { Link } from "react-router-dom";
import LogoBox from "../../layout/LogoBox";
import userAtom from "../../atoms/userAtom";
import { useRecoilState } from "recoil";
import { useMutation } from "@tanstack/react-query";
import { Form } from "../../layout/form/form";
import { useForm } from "../../hooks/useForm";
import { TextField } from "../../layout/form/text-field";
import { setPassword } from "../../jason-proof-of-concept/users/actions/set-password";

const initialFormSchema = z
    .object({
        password: z.string().min(8),
        confirmPassword: z.string().min(8),
    })
    .refine(({ password, confirmPassword }) => password === confirmPassword, {
        message: "Passwords must match",
        path: ["confirmPassword"],
    });

type InitialFormData = z.infer<typeof initialFormSchema>;

export default function SetPassword() {
    const [user] = useRecoilState(userAtom);
    const form = useForm({ schema: initialFormSchema });
    const initialMutation = useMutation({
        mutationFn: async (data: InitialFormData) => {
            if (!user.id) throw Error("No user id is present");
            await setPassword({
                userId: user.id,
                password: data.password,
                verificationToken: "isTempPassword",
            });
            return { user };
        },
    });

    const handleSubmit = async (data: InitialFormData) => {
        const result = await initialMutation.mutateAsync(data);
        if (result) {
            window.location.href = window.location.origin + "/dashboard";
        }
    };
    return (
        <>
            <div className="bg-slate-300 dark:bg-slate-600 h-screen m-0 p-0 min-h-screen">
                <>
                    <div className="min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-12 ">
                        <Form onSubmit={form.handleSubmit(handleSubmit as any)} error={initialMutation.error as any}>
                            <div className="max-w-lg md:w-[600px] space-y-10 rounded-xl  shadow-2xl bg-white shadow-slate-700/90 border-white/80 dark:border-slate-500 border    ">
                                <div className="bg-indigo-800 rounded-tl-xl rounded-tr-xl p-4">
                                    <LogoBox />
                                </div>

                                <div className="m-14 pb-14">
                                    <div>
                                        <h1 style={{ textAlign: "center", marginBottom: 12 }}>
                                            Set a password for your account
                                        </h1>

                                        <div className="rounded-md shadow-sm -space-y-px">
                                            <div>
                                                <TextField
                                                    type="password"
                                                    label="Password"
                                                    {...form.getFieldProps("password")}
                                                />
                                                <TextField
                                                    type="password"
                                                    label="Confirm password"
                                                    {...form.getFieldProps("confirmPassword")}
                                                />
                                            </div>
                                        </div>

                                        <div>
                                            <button
                                                type="submit"
                                                className={
                                                    "group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md " +
                                                    "text-white bg-indigo-600 hover:bg-indigo-500 hover:text-gray-200 focus:outline-none focus:ring-2 " +
                                                    "focus:ring-offset-2 focus:ring-indigo-500 transition-all duration-500"
                                                }
                                            >
                                                Create password
                                            </button>
                                        </div>
                                    </div>

                                    <div className="flex items-center justify-between">
                                        <div className="text-sm">
                                            <Link
                                                to="/logout"
                                                className="font-medium text-indigo-400 hover:text-indigo-500"
                                            >
                                                Sign out
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Form>
                    </div>
                </>
            </div>
        </>
    );
}
