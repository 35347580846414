import APIService from "./api.service";

class IndustryGroupService extends APIService<IndustryGroup> {
    constructor() {
        super("/industry-groups");
    }
}

export type IndustryGroup = {
    id?: number;
    name: string;
    description: string;
};

export default new IndustryGroupService();
