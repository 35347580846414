import { FileUploadUtil } from "../../../utilities/file-upload";
import { useContext, useEffect, useState } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import Swal from "sweetalert2";
import fileUploadService from "../../../../services/file.service";
import UserContext from "../../../../services/user-context";
import ModalDialog from "../../../../layout/modal-dialog";
import { assumedUserAtom } from "../../../../atoms/userAtom";
import { useRecoilState } from "recoil";
import InputControlled from "../../../../layout/input-controlled";
import { TextAreaInput } from "../../../../layout/form/text-area-input";
import TextAreaNeoGenControlled from "../../../../layout/text-area-controlled";
import industryService from "../../../../services/industry.service";
import industryGroupsService, { IndustryGroup } from "../../../../services/industry-groups.service";
import { Select } from "../../../../layout/form/select-input";
import industryGroupMembersService from "../../../../services/industry-group-members.service";
import users from "../../users/users";
import _, { set } from "lodash";
import mandateTypeService, { MandateType } from "../../../../services/mandate-type.service";
import Toggle from "../../../../layout/toggle";

export default function AddMandateType(props: AddMandateTypeProps) {
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [legallyBinding, setLegallyBinding] = useState(false);

    const queryCache = useQueryClient();

    useEffect(() => {
        setTimeout(() => {
            if (props.mandateType?.id) {
                setName(props.mandateType.name ?? "");
                setDescription(props.mandateType.description ?? "");
            } else {
                setName("");
                setDescription("");
            }
        }, 1);
    }, [props]);

    async function saveMandateType() {
        // The entry has rule groups because they are included in the query
        // They actually come from the ProcessflowRuleGroups table

        if (props.mandateType?.id) {
            // edit
            await mandateTypeService.update(props.mandateType.id, {
                name,
                description,
                legallyBinding: legallyBinding ? 1 : 0,
            });
        } else {
            // add
            await mandateTypeService.create({ name, description, legallyBinding: legallyBinding ? 1 : 0 });
        }
        queryCache.invalidateQueries(["mandateTypes"]);

        props.close();
    }

    return (
        <ModalDialog
            close={props.close}
            show={props.show}
            title={props.mandateType ? "Edit Mandate Type" : "Add Mandate Type"}
            okAction={() => {
                saveMandateType();
            }}
            okText={props.mandateType ? "Save Mandate Type" : "Add Mandate Type"}
        >
            {/* </PrintPre> */}
            <InputControlled
                label="Name"
                value={name}
                onChange={(value) => {
                    setName(value);
                }}
            />
            <TextAreaNeoGenControlled
                label={"Description"}
                value={description}
                setValue={function (e: string): void {
                    setDescription(e);
                }}
            />
            <div className="mb-5">
                <Toggle
                    options={["Not Legally Binding", "Legally Binding"]}
                    selectedIndex={legallyBinding ? 1 : 0}
                    setSelectedIndex={function (index: number): void {
                        setLegallyBinding(index === 1);
                    }}
                />
            </div>
            {/* <div className="mb-5">
                <Select
                    value={binds}
                    options={
                        industriesQuery.data?.map((i) => ({
                            value: i.id ?? -1,
                            label: i.name ?? "",
                        })) ?? []
                    }
                    isMultiple={true}
                    label="Group Members"
                    onChange={(value) => {
                        if (Array.isArray(value)) {
                            setBinds(value.map((i) => Number(i)) ?? []);
                        } else {
                            setBinds([Number(value)] ?? []);
                        }
                        // setSelectedUser(value as string);
                        // refetch();
                    }}
                />
            </div> */}
        </ModalDialog>
    );
}

type AddMandateTypeProps = {
    show: boolean;
    close: () => void;
    mandateType?: MandateType | null;
};
