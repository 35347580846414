import APIService from "./api.service";

class MandateIndustryGroupService extends APIService<MandateIndustryGroup> {
    constructor() {
        super("/mandate-industry-groups");
    }
}

export type MandateIndustryGroup = {
    id?: number;
    industryGroup: number;
    mandate: number;
};

export default new MandateIndustryGroupService();
