import { z } from "zod";
import ModalDialog from "../../../layout/modal-dialog";
import { useForm } from "../../../hooks/useForm";
import { Form } from "../../../layout/form/form";
import { getAuthTokenNoThrow } from "../../../services/auth-header";
import ButtonNeoGen from "../../../layout/button-neogen";
import { Company, companySchema } from "../../domain/company";
import { NumberField } from "../../../layout/form/number-field";
import { TextAreaField } from "../../../layout/form/text-area-field";
import { User, userSchema } from "../../../jason-proof-of-concept/users/domain/user";
import { Field } from "../../../layout/form/field";
import { useCreatePaymentMutation } from "../../../payments/hooks/use-create-payment";
import { DateTimeField } from "../../../layout/form/date-field";
import { useAuth } from "../../../auth/use-auth";
import { Payment } from "../../../payments/domain/payment";
import { TextField } from "../../../layout/form/text-field";

const schema = z.object({
    amount: z.number(),
    date: z.date(),
    description: z.string().nullish(),
    reference: z.string().nullish(),
    notes: z.string().nullish(),
    fromClearErc: z.boolean().nullish(),
    from: z.string().nullish(),
    fromCompanyId: z.number().nullish(),
    fromUserId: z.string().uuid().nullish(),
    toClearErc: z.boolean().nullish(),
    to: z.string().nullish(),
    toCompanyId: z.number().nullish(),
    toUserId: z.string().uuid().nullish(),
    paymentRequestId: z.string().uuid().nullish(),
});

type Data = z.infer<typeof schema>;

export const CreatePaymentModal = ({
    onClose,
    companyId,
    defaultValues,
    onPaymentCaptured,
    fromUser,
    fromCompany,
    toUser,
    toCompany,
}: {
    onClose: () => any;
    companyId: number;
    defaultValues?: Partial<Payment>;
    onPaymentCaptured: (payment: Payment) => void;
    fromUser?: User;
    fromCompany?: Company;
    toUser?: User;
    toCompany?: Company;
}) => {
    const auth = useAuth();
    const authedUser = auth.expectUser();
    const authToken = getAuthTokenNoThrow() || "no-auth-token";

    const form = useForm({
        schema,
        defaultValues: {
            ...defaultValues,
            toUserId: toUser?.id,
            date: new Date(),
        },
    });

    const formData = form.getValues();

    const createPaymentMutation = useCreatePaymentMutation();

    const handleSubmit = async (data: Data) => {
        const payment = await createPaymentMutation.mutateAsync({
            authToken,
            data: {
                createdById: authedUser.id,
                companyId,
                ...data,
                notes: data.notes || "",
            },
        });
        onPaymentCaptured(payment);
    };

    return (
        <ModalDialog show title={"Create payment"} close={onClose} showOk={false} showCancel={false} size="sm">
            <p className="text-center mb-6 text-gray-400">Create a payment which has been made.</p>
            <Form onSubmit={form.handleSubmit(handleSubmit as any)} error={createPaymentMutation.error as any}>
                <div className="flex flex-row">
                    <div className="flex-1">
                        <Field label="From">
                            {formData.fromClearErc
                                ? "ClearERC"
                                : formData.from || fromUser?.firstName || fromCompany?.name}
                        </Field>
                    </div>
                    <div className="flex-1">
                        <Field label="To">
                            {formData.toClearErc ? "ClearERC" : formData.to || toUser?.firstName || toCompany?.name}
                        </Field>
                    </div>
                </div>
                <TextField label="Description" {...form.getFieldProps("description")} />
                <DateTimeField label="Date" dateFormat="yyyy/MM/dd hh:mm" {...form.getFieldProps("date")} />
                <NumberField label="Amount" {...form.getFieldProps("amount")} />
                <TextField label="Payment reference" {...form.getFieldProps("reference")} />
                <TextAreaField label="Notes" {...form.getFieldProps("notes")} />
                <div className="flex justify-end gap-4">
                    <ButtonNeoGen type="outline" disabled={createPaymentMutation.isLoading} onClick={() => onClose()}>
                        Cancel
                    </ButtonNeoGen>
                    <ButtonNeoGen type="submit" disabled={createPaymentMutation.isLoading}>
                        <i className="fa fa-check text-green-500 mr-2" />
                        Create payment
                    </ButtonNeoGen>
                </div>
            </Form>
        </ModalDialog>
    );
};
