import APIService from "./api.service";
import stageHistoryService from "./stage-history.service";
import stagesService from "./stages.service";

export type MondaySyncData = {
    id: number;
    clearErcId: string;
    ercData: string;
    ercLastUpdated: Date;
    fieldId: number;
    mondayData: string;
    mondayId: string;
    mondayLastUpdated: Date;
    processed: number;
};

class MondaySyncDataService extends APIService<MondaySyncData> {
    constructor() {
        super("monday-sync-data");
    }
    async pushStatusToMonday(processFlowID: number, status: string, changedBy?: string) {
        // First find the mondaySyncData record for this processFlowID
        const response = await this.getFilteredWhere({ clearErcId: processFlowID, fieldId: 3 });
        // Create a stage history record
        if (response) {
            const stageFrom = await stagesService.getStageByName(response?.data?.[0]?.ercData);
            const stageTo = await stagesService.getStageByName(status);
            stageHistoryService.create({
                processflow: processFlowID,
                stageFrom: stageFrom?.id,
                stageTo: stageTo?.id,
                changeWhen: new Date(),
                syncChange: 0,
                changeBy: changedBy ?? "system",
                notes: "",
            });
            // Then update the mondaySyncData record with the status
            response.data[0].ercData = status;
            response.data[0].mondayData = status;
            await this.update(response?.data?.[0]?.id, response.data[0]);
            // Then call the Monday API to update the status
            const mondayResponse = await this.postURL("/monday-sync-data/send-to-monday", {
                mondayId: response?.data?.[0]?.mondayId,
                fieldId: "status",
                mondayData: status,
            });
        }
    }
}

export default new MondaySyncDataService();
