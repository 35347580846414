import { z } from "zod";
import { makeRequest, parseSchema } from "../../jason-proof-of-concept/shared/utils";
import { userCompanySchema } from "../domain/user-company";

const getUserCompaniesResponseSchema = z.array(userCompanySchema);

export const getUserCompanies = async ({ authToken, filters }: { authToken?: string; filters?: any }) => {
    const response = await makeRequest({
        method: "get",
        path: "/user-companies",
        authToken,
        queryParams: { filter: JSON.stringify(filters) },
    });

    const userCompanyDtos = parseSchema(getUserCompaniesResponseSchema, response.data);

    const userCompanies = parseSchema(
        z.array(userCompanySchema),
        userCompanyDtos.map((dto) => ({ ...dto })),
    );

    return userCompanies;
};
