import React from "react";
import PageDescription from "../../../layout/page-description";
import { Table } from "../../../layout/table";
import TableNeogen from "../../../layout/table-neogen";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import industryService from "../../../services/industry.service";
import industryGroupsService, { IndustryGroup } from "../../../services/industry-groups.service";
import industryGroupMembersService from "../../../services/industry-group-members.service";
import Loader3 from "../../utilities/Loader3";
import AddMandateType from "./modals/add-mandate-type";
import SwalNeogenFire from "../../../layout/swal-neogen";
import mandateTypeService, { MandateType } from "../../../services/mandate-type.service";
import { Mandate } from "../../../services/mandate.service";

export default function MandateTypes() {
    const [showAddMandateType, setShowAddMandateType] = React.useState(false);
    const [mandate, setMandate] = React.useState<Mandate | null>(null);
    const [mandateType, setMandateType] = React.useState<MandateType | null>(null);

    const queryCache = useQueryClient();
    const mandateTypesQuery = useQuery(["mandateTypes"], async () => {
        const result = await mandateTypeService.getAll();
        return result?.data ?? [];
    });

    return (
        <>
            <PageDescription
                title="Mandate Types"
                description="Manage Mandate Types"
                buttons={[
                    {
                        label: "Add Mandate Type",
                        onClick: () => {
                            setMandateType(null);
                            setShowAddMandateType(true);
                        },
                        icon: "fas fa-plus",
                    },
                    {
                        label: "Manage Industries",
                        onClick: () => {
                            window.location.href = "/admin/industries";
                        },
                        icon: "fas fa-building-circle-arrow-right",
                        type: "info",
                    },
                    {
                        label: "Manage Mandates",
                        onClick: () => {
                            window.location.href = "/admin/mandates";
                        },
                        icon: "fas fa-file-lines",
                        type: "secondary",
                    },
                ]}
            />
            {mandateTypesQuery.isLoading ? (
                <Loader3 />
            ) : (
                <TableNeogen
                    entries={
                        mandateTypesQuery.data?.map((i) => {
                            return {
                                id: i.id,
                                name: i.name,
                                description: i.description,
                                // industries:
                                //     industryGroupMembersQuery.data
                                //         ?.filter((m) => m.industryGroup === i.id)
                                //         .map((m) => {
                                //             return (
                                //                 industriesQuery.data?.find((in1) => in1.id === m.industry)?.name ?? ""
                                //             );
                                //         })
                                //         ?.join(", ") ?? "",
                            };
                        }) ?? []
                    }
                    actions={[
                        {
                            label: "Edit",
                            onClick: (entry) => {
                                // console.log({ entry });
                                setMandateType(mandateTypesQuery.data?.find((i) => i.id === entry) ?? null);
                                setShowAddMandateType(true);
                            },

                            icon: "fas fa-edit",
                        },
                        {
                            label: "Delete",
                            type: "danger",
                            onClick: (entry) => {
                                SwalNeogenFire({
                                    title: "Are you sure?",
                                    text: "You won't be able to revert this!",
                                    icon: "warning",
                                    showCancelButton: true,
                                    confirmButtonText: "Yes, delete it!",
                                    cancelButtonText: "No, keep it",
                                    confirmButtonColor: "#d33",
                                    reverseButtons: true,
                                }).then(async (result) => {
                                    if (result.isConfirmed) {
                                        // Get a list of all the industryGroupMembers that are in this industryGroup
                                        // and delete them
                                        // const entriesToDelete =
                                        //     industryGroupMembersQuery.data?.filter((i) => i.industryGroup === entry) ??
                                        //     [];
                                        // for (const entry of entriesToDelete) {
                                        //     await industryGroupMembersService.deleteByID(entry.id ?? -1);
                                        // }
                                        // await industryService.deleteByID(entry);
                                        // queryCache.invalidateQueries(["industryGroups"]);
                                        // queryCache.invalidateQueries(["industryGroupMembers"]);
                                        // industriesQuery.refetch();
                                        // SwalNeogenFire({
                                        //     title: "Deleted!",
                                        //     text: "Your file has been deleted.",
                                        //     icon: "success",
                                        //     showConfirmButton: false,
                                        //     timer: 1500,
                                        // });
                                    }
                                });
                            },
                            icon: "fas fa-trash",
                        },
                    ]}
                />
            )}
            {showAddMandateType && (
                <AddMandateType
                    show={showAddMandateType}
                    close={() => setShowAddMandateType(false)}
                    mandateType={mandateType}
                />
            )}
        </>
    );
}
