import APIService from "./api.service";

export type Stages = {
    id?: number;
    name?: string;
    description?: string;
    isPublic?: number;
    isAccountant?: number;
    parent?: number;
    requiredRoleGroup?: number;
};

class StagesService extends APIService<Stages> {
    constructor() {
        super("stages");
    }
    async getStageByName(name: string) {
        const stageResult = await this.getFilteredWhere({ name });
        if (stageResult && stageResult.data && stageResult.data.length > 0) {
            return stageResult.data[0];
        } else {
            console.error("Stage not found: " + name);
            const response = await this.create({
                name,
                description: "",
                isPublic: 0,
                isAccountant: 0,
                parent: 0,
                requiredRoleGroup: 0,
            });
            console.error(response);
            return response?.data;
        }
    }
}

export default new StagesService();
