/* eslint-disable indent */
import { z } from "zod";
import { makeRequest, parseSchema } from "../../shared/utils";

export const mondaySyncDataResponseSchema = z.object({
    id: z.number(),
    clearErcId: z.string().nullish(),
    ercData: z.string().nullish(),
    ercLastUpdated: z.string().nullish(),
    fieldId: z.number().nullish(),
    mondayData: z.string().nullish(),
    mondayId: z.string().nullish(),
    mondayLastUpdated: z.string().nullish(),
    processed: z.number().nullish(),
});

export type MondaySyncDataData = z.infer<typeof mondaySyncDataResponseSchema>;

const getMondaySyncDataResponseSchema = z.array(mondaySyncDataResponseSchema);

export const getMondaySyncData = async ({ authToken, filters }: { authToken?: string; filters?: any }) => {
    try {
        const response = await makeRequest({
            method: "get",
            path: "/monday-sync-data",
            authToken,
            queryParams: { filter: JSON.stringify(filters) },
        });

        const mondaySyncData = parseSchema(getMondaySyncDataResponseSchema, response.data);

        return mondaySyncData;
    } catch (error) {
        console.error(error);
        throw error;
    }
};
