import { z } from "zod";
import { makeRequest, parseSchema } from "../../jason-proof-of-concept/shared/utils";
import { companySchema } from "../domain/company";
import { CompanyFilters } from "../components/modals/company-filters-modal";

// TODO: Should ideally be a filter on /companies - but too complicated to build a filter for now.
export const getHedgeFunds = async ({
    authToken,
    filters,
    adHocFilters,
}: {
    authToken: string;
    filters?: any;
    adHocFilters?: CompanyFilters;
}) => {
    const response = await makeRequest({
        method: "get",
        path: "/companies/hedge-funds",
        authToken,
        queryParams: { filter: JSON.stringify(filters), adHocFilters: JSON.stringify(adHocFilters) },
    });

    const companies = parseSchema(z.array(companySchema), response.data);

    return companies;
};
